<script>
import axiosInstance from '../../../axiosInstance';

export default {
  data() {
    return {
      selectedBrand: null,
      productDetail: [],
      product: {
        total_commission: '',
        affiliate_commission: '',
        sub_affiliate_commission: '',
        sale_price: '',
      },
      brands: [],

      products: [],
      childcategory: [],
      file: null
    };
  },

  created() {
    this.fetchAllData(); // Load data when component is created
  },

  methods: {
    fetchAllData() {
      return axiosInstance
          .get('api/get-product-detail-commission')
          .then(response => {
            this.productDetail = response.data;
          })
          .catch(error => {
            console.error('Error fetching main data:', error);
          });
    },

    updateDescription(product) {
      console.log('Updating description for product:', product); // Add this for debugging

      // Check if product.id exists
      if (!product.id) {
        console.error("Product ID is missing!");
        return;
      }

      // Prepare the data to be sent
      const payload = {
        total_commission: product.total_commissions,
        affiliate_commission: product.affiliate_commission,
        sub_affiliate_commission: product.sub_affiliate_commission,
        new_price: product.new_price, // Include new_price in the payload
      };

      axiosInstance
          .put(`api/update-product-detail-commission/${product.id}`, payload)
          .then(response => {
            console.log('Commissions updated:', response.data);
            this.fetchAllData(); // Refresh product data after successful update
          })
          .catch(error => {
            console.error('Error updating commissions:', error);
          });
    },

    updateCheckedItems() {
      // Filter products that are checked
      const checkedProducts = this.productDetail.filter(product => product.checked);
      if (checkedProducts.length === 0) {
        alert("Please select at least one item to update.");
        return;
      }

      // Loop through checked products and update them
      checkedProducts.forEach(product => {
        this.updateDescription(product);
      });
    }
  }
};
</script>

<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Product Detail</h3>

          <button @click="updateCheckedItems" class="btn btn-primary mb-3">
            Update Checked Items
          </button>

          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Image</th>
              <th>Sku</th>
              <th>Sale Price</th>
              <th>New Sale Price</th>
              <th>Cost</th>
              <th>Price After Discount</th>
              <th>Latest Commission</th>
              <th>Total Commission</th>
              <th>Affiliate Commission</th>
              <th>Sub Affiliate Commission</th>
              <th>Select</th> <!-- Added -->
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in productDetail" :key="product.id">
              <td>{{ product.id }}</td>
              <td>{{ product.name }}</td>
              <td><img :src="'https://api.gocami.com/product_detail/' + product.image" width="100"/></td>
              <td>{{ product.sku }}</td>
              <td v-if="product.sale_price">{{ product.sale_price.toFixed(2) }}</td>
              <td v-else>-</td>
              <td v-if="product.new_price">
                <input type="number" class="form-control" v-model="product.new_price" style="width:100%"/>
              </td>
              <td v-else>-</td>
              <td v-if="product.main_price">{{ product.main_price.toFixed(2) }}</td>
              <td v-else>-</td>
              <td v-if="product.new_price"
                  :class="{
        'text-danger fw-bold': (product.new_price - (product.new_price * product.total_commissions / 100)).toFixed(2) == product.main_price,
        'text-warning fw-bold': (product.new_price - (product.new_price * product.total_commissions / 100)).toFixed(2) > product.main_price && (product.new_price - (product.new_price * product.total_commissions / 100)).toFixed(2) <= product.main_price + 5,
        'text-success fw-bold': (product.new_price - (product.new_price * product.total_commissions / 100)).toFixed(2) > product.main_price + 5
    }">
                {{ (product.new_price - (product.new_price * product.total_commissions / 100)).toFixed(2) }}
              </td>
              <td v-else>-</td>

              <td>{{ product.latest_status_0_commission ? product.latest_status_0_commission : '-' }}</td>
              <td><input type="text" class="form-control" v-model="product.total_commissions" style="width:100%;"/></td>
              <td><input type="text" class="form-control" v-model="product.affiliate_commission" style="width:100%;"/>
              </td>
              <td><input type="text" class="form-control" v-model="product.sub_affiliate_commission"
                         style="width:100%;"/></td>
              <td>
                <input type="checkbox" class="form-check-input" v-model="product.checked"/>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>
