<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h2>Invoices</h2>
          <div>
            <label>From Date: <input type="datetime-local" v-model="fromDate"/></label>
            <label>To Date: <input type="datetime-local" v-model="toDate"/></label>
            <button @click="fetchInvoices">Load Data</button>
          </div>
          <table border="1">
            <thead>
            <tr>
              <th>Invoice ID</th>
              <th>Created At</th>
              <th>First Name</th>
              <th>Middle Name</th>
              <th>Last Name</th>
              <th>Invoice Total Before Discount</th>
              <th>Invoice Total After Discount</th>
              <th>Refund</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="invoice in invoices" :key="invoice.invoice_id">
              <td>{{ invoice.invoice_id }}</td>
              <td>{{ invoice.created_at }}</td>
              <td>{{ invoice.first_name }}</td>
              <td>{{ invoice.invoice_middle_name }}</td>
              <td>{{ invoice.invoice_last_name }}</td>
              <td>{{ invoice.invoice_total_price_before_discount }}</td>
              <td>{{ invoice.invoice_total_price_after_discount }}</td>
              <td v-if="invoice.status == 10">{{invoice.invoice_invoice_id}} فاتورة المرتجع</td>
              <td v-else>-</td>
            </tr>
            </tbody>
          </table>
          <button @click="exportCSV">Export as CSV</button>
          <button @click="printPage">Print</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
  data() {
    return {
      invoices: [],
      fromDate: '',
      toDate: '',
    };
  },
  methods: {
    async fetchInvoices() {
      try {
        const response = await axiosInstance.get('api/get-invoices-data-daily', {
          params: {
            from_date: this.fromDate,
            to_date: this.toDate,
          },
        });
        this.invoices = response.data;
      } catch (error) {
        console.error("Error fetching invoices:", error);
      }
    },
    
    exportCSV() {
      // CSV header row
      let csv = 'Invoice ID,Created At,First Name,Middle Name,Last Name,Invoice Total Before Discount,Invoice Total After Discount,Refund\n';

      // Append rows for each invoice
      this.invoices.forEach((invoice) => {
        const refund = invoice.status === 10 ? `${invoice.invoice_invoice_id} فاتورة المرتجع` : '-';
        csv += `${invoice.invoice_id},${invoice.created_at},${invoice.first_name},${invoice.invoice_middle_name},${invoice.invoice_last_name},${invoice.invoice_total_price_before_discount},${invoice.invoice_total_price_after_discount},${refund}\n`;
      });

      // Add UTF-8 BOM to support Arabic text
      const bom = '\uFEFF';
      const blob = new Blob([bom + csv], { type: 'text/csv;charset=utf-8;' });

      // Create a downloadable link
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'invoices.csv';
      link.click();
    },

    printPage() {
      window.print();
    },
  },
};
</script>

<style scoped>
/* Add any styles you need for the table here */
table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid black;
  padding: 8px;
}

thead {
  background-color: #f2f2f2;
}

button {
  margin: 10px 5px;
  padding: 10px;
}
</style>
