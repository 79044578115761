<template>
  <div class="pc">


    <div class="temp justify-content-center text-center mx-auto">


      <!-- <div> -->
      <!-- <div id="main-top" style="margin: 0 205px"> -->

      <!-- <div class="hide"
  v-if="this.user?.id && dataSpinner.user_id === this.user?.id || (this.user?.roles == 4 || this.user?.roles == 5)">
  <button class="btn-spin" @click="openPopup2">
      <i class="fa fa-arrow-left"></i><span>Hover me!</span>
  </button>
</div>

<div class="btn-container" v-else>
  <button class="btn-spin" @click="openPopup2">
      <i class="fa fa-arrow-left"></i><span>Hover me!</span>
  </button>
</div> -->

      <!--
<div v-if="isLoading" class="loading-indicator">
  <div class="loading-spinner"></div>
  Loading...
</div> -->


      <!-- <div v-if="this.user?.id && dataSpinner.user_id === this.user?.id || (this.user?.roles == 4 || this.user?.roles == 5)"
  v-cloak>
  <div class="popup-overlay hide" style="display:none !important"></div>
</div>

<div v-else-if="this.user?.id && dataSpinner.user_id != this.user?.id && getSpinerBazar.length < 1">


  <div class="popup-overlay" v-if="showPopup2">
      <div class="popup-content">
          <button class="close-button" @click="closePopup2">Close</button>
          <div class="spinner-container">
              <div class="chair-base"></div>
              <div class="chair-base2 shadow"></div>

              <div class="wheel-wrapper" style="margin-top: 120px;">
                  <div class="circles">
                      <div class="wheel-pointer" @click="onClickRotateProduct"></div>
                  </div>
                  <div class="wheel-container shadow">
                      <div class="white-border">
                          <div class="triangle top"></div>
                          <div class="triangle left"></div>
                          <div class="triangle right"></div>
                          <div class="triangle bottom"></div>
                          <div class="triangle left-top"></div>
                          <div class="triangle right-top"></div>
                          <div class="triangle left-bottom"></div>
                          <div class="triangle right-bottom"></div>

                          <div class="shape shape-top shadow"></div>
                          <div class="shape shape-left-top shadow"></div>
                          <div class="shape shape-left-bottom shadow"></div>
                          <div class="shape shape-bottom shadow"></div>
                          <div class="shape shape-bottom-right shadow"></div>
                          <div class="shape shape-right-top shadow"></div>
                          <div class="shape shape-right shadow"></div>
                          <div class="shape shape-right-bottom shadow"></div>
                      </div>
                      <div class="wheel-bg" :class="{ freeze: freeze }" :style="`transform: rotate(${wheelDeg}deg)`">
                          <div class="prize-list">
                              <div class="prize-item-wrapper" v-for="(product, index) in productspinner" :key="index">
                                  <div class="prize-item"
                                      :style="`transform: rotate(${(360 / numSegments) * index}deg); background-color: ${segmentColors[index]}`">
                                      <div class="prize-name text-white text-center mx-auto"
                                          style="max-width: 120px;">
                                          {{ product.title }}
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
          </div>

          <div style="position: absolute; top:65%;">
              Rules:
              <ul>
                  <li>You can spin the wheel only once.</li>
              </ul>
          </div>

      </div>
  </div>

</div> -->

      <!-- <div class="popup-overlay" v-if="showPopup3">
  <div class="popup-content">
      <button class="close-button" @click="closePopup3">Close</button>


      <div v-if="isLoading" class="loading-indicator">
          <div class="loading-spinner"></div>
          Loading...
      </div>
      <div v-else>
          <div class="pyro">
              <div class="before"></div>
              <div class="after"></div>
          </div>

          <div class="message-container">
              <div class="message mt-5">
                  <span>Congratulations!</span> &nbsp;
                  <span>You won!</span><br />
                  {{ getProducts.productdetail?.title }} <br />
                  <img :src="'https://api.gocami.com/product_detail/' + getProducts.productdetail?.image" />
              </div>
          </div>

      </div>
  </div>
</div>

<div class="popup-overlay" v-if="showPopup2">
  <div class="popup-content">
      <button class="close-button" @click="closePopup2">Close</button>
      <div class="lucky-wheel">
          <div class="wheel-container">
              <div class="wheel">
                  <div class="wheel-center"></div>
                  <div class="wheel-sections" :style="wheelStyle">
                      <div v-for="(section, index) in wheelSections" :key="index" class="wheel-section"
                          :style="sectionStyle(index)">
                          <div class="section-content">{{ section.value }}</div>
                      </div>
                  </div>
                  <div class="pin"></div>
              </div>
          </div>
          <button @click="spin" :disabled="spinning">Spin</button>
          <p v-if="result !== -1">You won: {{ selectedValue }}</p>
      </div>
  </div>
</div> -->


      <div class="toast" :class="{ 'show': showSuccessMessage, 'error': isError }">
        {{ message }}
      </div>

      <!-- <div v-if="loading" class="loading-indicator">
          <img src="https://api.gocami.com/photos/cami.gif" width="200" height="200" alt="Loading..." />
      </div> -->

      <!-- <div class="row">
          <div class="col-4" v-for="item in items" :key="item.id">
              <div v-if="item.avqty > 0">
                  <span class="text-danger">(id: {{ item.id }} )</span>
                  <span class="text-success">(description: {{ item.description }} )</span>
                  <span class="text-primary">(sku: {{ item.sku_code }} )</span>
                  <span class="text-warning">(avQty: {{ item.avqty }} )</span>
                  <button @click="createQuotationForItem(item)">Create Quotation</button>
              </div>
          </div>
      </div> -->


      <!-- ========================  Best seller ======================== -->

      <div class="text-white text-center py-3 top-side print mx-3 mb-3" style="background-color:#EDEFF3;">
        <span class="fw-bolder fs-2" style="color:#690001">Free delivery </span>
        <span class="fs-4" style="color:#690001">in Tripoli. Outside <span class="fw-bolder fs-4">free</span>
                    for orders over $25</span>
      </div>


      <section class="header-content">
        <div class="clearifx">
          <splide :options="splideOptions">
            <splide-slide v-for="slider in sliders" :key="slider.id">
              <div class="slide-container">
                <router-link :to="slider.slider_url" class="center-image">
                  <img v-if="isPC" :src="'https://api.gocami.com/slider_image/' + slider.slider_image"
                       style="border-radius: 10px; width: 100%; height: 350px !important;" alt=""
                       class="my-5 mx-3"/>
                  <img v-else class="mb-5"
                       :src="'https://api.gocami.com/mobile_slider_image/' + slider.mobile_slider_image"
                       style="border-radius: 10px; width: 100%; height: 200px" alt=""/>
                </router-link>
              </div>
            </splide-slide>
          </splide>
        </div>
      </section>


      <div class="row row-clean" v-if="bundles.length > 0">
        <div class="bundle-container mx-2" ref="bundleContainer">
          <div class="bundle-items">
            <!-- Loop through bundles -->
            <div v-for="bundle in bundles" :key="bundle.id" class="bundle-item">
              <router-link :to="{ name: 'HomeBundleView', params: { id: bundle.id } }" class="mfp-open">
                <img :src="'https://api.gocami.com/bundle_image/' + bundle.bundle_image" alt=""
                     class="product-image" style="width: 300px !important;"/>
                <div class="text">
                  <h2 class="title h5 pt-3">{{ bundle.bundle_name }}</h2>
                  <sup>$ {{ bundle.bundle_price.toFixed(2) }}</sup>
                </div>
              </router-link>
            </div>
            <!-- End loop -->
          </div>
        </div>
        <div class="d-flex text-center justify-content-center mt-4">
          <button @click="scrollBundleLeft" class="arrow-btn bundle-left">
            <span class="bg-white p-3 rounded shadow circle"><i class="pi pi-chevron-left"></i></span>
          </button>
          <button @click="scrollBundleRight" class="arrow-btn bundle-right">
            <span class="bg-white p-3 rounded shadow circle"><i class="pi pi-chevron-right"></i></span>
          </button>
        </div>
      </div>

<!--            <router-link to="/bazar-view">-->
<!--              <img src="https://api.gocami.com/sale/grand_pc.webp" class="mt-4">-->
<!--            </router-link>-->


      <section class="blog blog-block blog-intro my-5" style="background-color: #EDEFF3;">
        <div class="row mx-5">
          <button v-if="isPC" @click="scrollLeft" class="btn bg-white shadow btn-arrow btn-circle-left"><i
              class="pi pi-arrow-left"></i></button>
          <button v-else @click="scrollLeft" class="btn bg-white shadow btn-arrow btn-circle-left"
                  style="margin-top:50px !important"><i class="pi pi-arrow-left"></i></button>
          <div class="scrolling-container" ref="scrollingContainer">

            <div class="flex" v-for="allBrands in allBrands" :key="allBrands.id">
              <router-link :to="{ name: 'HomeBrandView', params: { id: allBrands.id } }">
                <img :src="'https://api.gocami.com/brand_icon/' + allBrands.brand_icon"
                     class="my-2 sub-image rounded-circle"
                     style="width: 120px !important; height: auto !important; margin-right: 10px;"/>
              </router-link>
            </div>

          </div>
          <button v-if="isPC" @click="scrollRight" class="btn bg-white shadow btn-arrow btn-circle-right"><i
              class="pi pi-arrow-right"></i></button>
          <button v-else @click="scrollRight" class="btn bg-white shadow btn-arrow btn-circle-right"
                  style="margin-top:50px !important"><i class="pi pi-arrow-right"></i></button>
        </div>
      </section>

      <div v-if="showCountdown" class="mb-5 countdown-container">
        <!-- Image -->
        <img v-if="isPC" :src="'https://api.gocami.com/sale/pc.webp'"/>
        <img v-else :src="'https://api.gocami.com/sale/mobile.webp'"/>

        <span v-if="isPC">
                    <!-- Countdown items -->
                    <div class="countdown-item" style="right: calc(9% - -10px);">
                        <div class="countdown-number-container">
                            <span class="countdown-number">{{ counter.hours }}</span>
                        </div>
                        <span class="countdown-label text-white">Hours</span>
                    </div>
                    <div class="countdown-item" style="right: calc(12% - 100px);">
                        <div class="countdown-number-container">
                            <span class="countdown-number">{{ counter.minutes }}</span>
                        </div>
                        <span class="countdown-label text-white">Minutes</span>
                    </div>
                    <div class="countdown-item" style="right: calc(10% + -135px);">
                        <div class="countdown-number-container">
                            <span class="countdown-number">{{ counter.seconds }}</span>
                        </div>
                        <span class="countdown-label text-white">Seconds</span>
                    </div>

          <!-- Orders Left -->
                    <div class="countdown-item" style="right: 8%; top: 63%; margin-top:35px">
                        <div class="countdown-number-container">
                            <span class="countdown-label">Orders Left: {{ countDownNumber.number }}</span>
                        </div>
                    </div>

                </span>

        <span v-else>
                    <!-- Countdown items -->
                    <div class="countdown-item" style="right: calc(20% + 30px);">
                        <div class="countdown-number-container">
                            <span class="countdown-number">{{ counter.hours }}</span>
                        </div>
                        <span class="countdown-label">Hours</span>
                    </div>
                    <div class="countdown-item" style="right: calc(15% - 0px);">
                        <div class="countdown-number-container">
                            <span class="countdown-number">{{ counter.minutes }}</span>
                        </div>
                        <span class="countdown-label">Minutes</span>
                    </div>
                    <div class="countdown-item" style="right: calc(13% - 50px);">
                        <div class="countdown-number-container">
                            <span class="countdown-number">{{ counter.seconds }}</span>
                        </div>
                        <span class="countdown-label">Seconds</span>
                    </div>

          <!-- Orders Left -->
                    <div class="countdown-item" style="right: 55%; top: 63%;">
                        <div class="countdown-number-container">
                            <span class="countdown-label">Orders Left: {{ countDownNumber.number }}</span>
                        </div>
                    </div>

                </span>

      </div>

      <div class="row mx-lg-5 d-flex mt-5">
        <div v-if="isPC" class="col-7">
          <img src="https://api.gocami.com/photos/photo2.webp" usemap="#workmap" alt="Product Image"
               style="height: auto !important"/>
          <map name="workmap">
            <area shape="rect" coords="0,0,340,190" href="/brand-view/12" alt="Lapitak"/>
            <area shape="rect" coords="350,200,600, 230" href="/brand-view/6" alt="Dermokil"/>
          </map>
        </div>

        <div v-else class="col-7">
          <img src="https://api.gocami.com/photos/photo2.webp" usemap="#workmap" alt="Product Image"
               style="height: auto !important"/>
          <map name="workmap">
            <area shape="rect" coords="0,0,170,150" href="/brand-view/12" alt="Lapitak"/>
            <area shape="rect" coords="0,0,300,190" href="/brand-view/6" alt="Dermokil"/>
          </map>
        </div>

        <div class="col-5">
          <router-link to="/productdetail-view/1334">
            <img src="https://api.gocami.com/photos/photo1.webp" style="height: auto !important;"/>
          </router-link>
        </div>
      </div>


      <div class="row justify-content-center text-center mx-1">
        <div class="col-12">
          <div class="d-flex pt-3 text-start mx-4" style="min-width:100% !important">
            <h2 class="fs-5 fw-bolder" style="color: #154068; font-size:24pt !important" v-if="isPC">New
              Arrival</h2>
            <h2 class="fs-5 fw-bold" style="color: #154068; font-size:14pt !important" v-else>New Arrival
            </h2>
            <hr class="line" style="align-self: center; margin: 0 10px;">
            <!-- <h2 style="font-size:18pt !important" v-if="isPC"><span class="p-2"
                    style="background-color:#154068; color:white; border-radius: 20px !important;">view more
                </span></h2>
            <h2 style="font-size:10pt !important" v-else>
                <span class="p-2"
                    style="background-color:#154068; color:white; border-radius: 20px !important;">
                    view more </span>
            </h2> -->
          </div>
        </div>
      </div>

      <div v-if="loading" class="loading-indicator">
        <i class="fa fa-circle-o-notch fa-spin" style="font-size:24px !important"></i>
      </div>

      <div v-else>
        <div class="row row-clean products-row mt-3 mb-5">
          <div class="product-navigation">

            <button @click="previousPageArrival" :disabled="currentPageArrival === 1"
                    class="arrow-button arrow-left">
                            <span class="bg-white p-3 rounded shadow circle"><i class="pi pi-chevron-left"
                                                                                style="padding-top: 10px !important; margin-top: 10px !important"></i></span>
            </button>

            <div class="product-item-product mx-1"
                 style="width:180px !important ;border: 2px solid #ddd !important; border-radius: 20px !important;padding: 10px !important"
                 v-for="newProd in paginatedProductsArrival" :key="newProd.id">
              <article style="overflow: hidden">
                <div class="figure-grid">
                  <div class="image">
                    <router-link :to="{
                name: 'ProductDetail',
                params: { id: newProd.id },
            }" class="mfp-open">
                      <img :src="'https://api.gocami.com/product_main/' +
                newProd.image
                " alt="" class="product-image"/>
                    </router-link>
                  </div>

                  <div class="text text-start ms-3">
                    <h2 class="title h5 pb-0 mb-0 py-2" style="color: black">{{
                        newProd.product_name
                      }}
                    </h2>
                    <span class="d-flex justify-content-between" v-if="newProd.discount > 0">
                                            <p class="title pt-0 mt-0 pb-0 mb-0 text-dark fw-bolder"> <del
                                                class="text-danger">${{
                                                newProd.price.toFixed(2)
                                              }}</del> ${{
                                                Math.floor(newProd.price -
                                                    (newProd.price * newProd.discount)
                                                    /
                                                    100)
                                              }}.<span style="vertical-align: super; font-size:90% !important">
                                                    {{
                                                  (
                                                      newProd.price -
                                                      (newProd.price *
                                                          newProd.discount) /
                                                      100
                                                  ).toFixed(2).slice(-2)
                                                }}
                                                </span>
                                            </p>

                                            <p class="title pt-0 mt-0 pb-0 mb-0 me-3 text-dark">
                                                <router-link :to="{
                name: 'ProductDetail',
                params: { id: newProd.id },
            }" class="mfp-open"> <i class="fa fa-eye" style="color:#154068; font-size:12pt !important"></i>
                                                        </router-link>

                                            </p>
                                        </span>
                    <span class="d-flex justify-content-between" v-else>
                                            <p class="title pt-0 mt-0 pb-0 mb-0"> ${{
                                                newProd.price.toFixed(2)
                                              }}
                                            </p>

                                            <p class="title pt-0 mt-0 pb-0 mb-0 me-3 text-dark">
                                                <router-link :to="{
                    name: 'ProductDetail',
                    params: { id: newProd.id },
                }" class="mfp-open">
                                                    <i class="fa fa-eye"
                                                       style="color:#154068; font-size:12pt !important"></i>
                                                </router-link>
                                            </p>
                                        </span>
                    <p class="title pt-0 mt-0 text-danger fw-bolder" v-if="newProd.discount > 0">
                      %{{
                        parseFloat(newProd.discount)
                      }} OFF</p>
                    <p class="title pt-0 mt-0" v-else></p>
                  </div>

                </div>
              </article>
            </div>

            <button @click="nextPageArrival" :disabled="currentPageArrival === totalPagesArrival"
                    class="arrow-button arrow-right">
                            <span class="bg-white p-3 rounded shadow circle"><i class="pi pi-chevron-right"
                                                                                style="padding-top: 10px !important; margin-top: 10px !important"></i></span>
            </button>

          </div>
        </div>

      </div>


      <div class="row mx-3 d-flex mt-5">
        <!-- <div class="col-lg-6 col-sm-12">
                <router-link to="/brand-view/37">
                    <img src="https://api.gocami.com/photos/img6.webp" />

                </router-link>

            </div> -->
        <div class="col-lg-12 col-sm-12 margin-top">
          <router-link to="/categories/Home">

            <img src="https://api.gocami.com/photos/img1.webp"/>
          </router-link>
        </div>
      </div>
      <!-- <section class="blog blog-block blog-intro my-5">
          <div class="row">
              <div class="col-12">
                  <router-link to="/">
                      <div v-if="isPC">
                          <img src="https://api.gocami.com/sale/discount.png" />
                      </div>

                      <div v-else>
                          <img src="https://api.gocami.com/sale/discount-mobile.png"
                              style="height: 150px !important;" />
                      </div>

                  </router-link>
              </div>
          </div>
      </section> -->



      <div class="row">
        <div class="mx-4" v-for="categoryProduct in categoryProduct" :key="categoryProduct.id">

          <div class="d-flex pt-3 text-start mx-4" style="min-width:100% !important">
            <h2 class="fs-5 fw-bolder" style="color: #154068; font-size:24pt !important" v-if="isPC">{{
                categoryProduct.category.category_name
              }}</h2>
            <h2 class="fs-5 fw-bold" style="color: #154068; font-size:14pt !important" v-else>{{
                categoryProduct.category.category_name
              }}</h2>
            <hr class="line" style="align-self: center; margin: 0 10px;">
            <h2 style="font-size:18pt !important" v-if="isPC">
              <router-link
                  :to="{ name: 'Categories', params: { id: categoryProduct.category.category_name } }"
                  class="p-2"
                  style="background-color:#154068; color:white; border-radius: 20px !important;">view more
              </router-link>
            </h2>
            <h2 style="font-size:10pt !important" v-else>
              <router-link
                  :to="{ name: 'Categories', params: { id: categoryProduct.category.category_name } }"
                  class="p-2"
                  style="background-color:#154068; color:white; border-radius: 20px !important;">
                view more
              </router-link>
            </h2>
          </div>

          <div class="row">
            <Carousel :items-to-show="5.1" :items-to-scroll="5" :wrap-around="true" class="mt-4"
                      style="font-size:48pt; height:45vh !important; transition-duration: 2s;" v-if="isPC">

              <Slide v-for="product in categoryProduct.products" :key="product.id">
                <router-link
                    :to="{ name: 'ProductDetail', params: { id: product.id } }"
                    @click="handleClick"
                    class="text-white"
                >
                  <div class="carousel__item" style="width:0% !important; height:38vh !important;">
                    <div class="product-item-product shadow"
                         style="height: 95% !important; border: 2px solid #ddd !important; border-radius: 20px !important;padding: 10px !important">
                      <article style="overflow: hidden">
                        <div class="figure-grid">

                          <div class="image">

                            <img :src="'https://api.gocami.com/product_main/' + product.image"
                                 alt="" class="product-image"/>

                          </div>

                          <div class="text text-start ms-3">
                            <h2 class="title h5 pb-0 mb-0 py-3" style="color: black">{{
                                product.product_name
                              }}
                            </h2>
                            <span class="d-flex justify-content-between"
                                  v-if="product.discount > 0">
                                                            <p class="title pt-0 mt-0 pb-0 mb-0 text-dark fw-bolder"
                                                               v-if="product.price"> <del class="text-danger">${{
                                                                product.price.toFixed(2)
                                                              }}</del> ${{
                                                                Math.floor(product.price -
                                                                    (product.price * product.price)
                                                                    /
                                                                    100)
                                                              }}.<span
                                                                  style="vertical-align: super; font-size:90% !important">
                                                                    {{
                                                                  (
                                                                      product.price -
                                                                      (product.price *
                                                                          product.discount) /
                                                                      100
                                                                  ).toFixed(2).slice(-2)
                                                                }}
                                                                </span>
                                                            </p>

                                                            <p class="title pt-0 mt-0 pb-0 mb-0 me-3 text-dark">
                                                                <i class="fa fa-eye"
                                                                   style="color:#154068; font-size:12pt !important"></i>
                                                            </p>
                                                        </span>
                            <span class="d-flex justify-content-between" v-else>
                                                            <p class="title pt-0 mt-0 pb-0 mb-0 text-dark"
                                                               v-if="product.old_price && product.old_price > product.price">
                                                                ${{ product.price.toFixed(2) }}
                                                              <del class="text-danger">${{
                                                                  product.old_price.toFixed(2)
                                                                }}</del>
                                                            </p>

                                                          <p class="title pt-0 mt-0 pb-0 mb-0 text-dark" v-else>
                                                              ${{ product.price.toFixed(2) }}
                                                            </p>

                                                            <p class="title pt-0 mt-0 pb-0 mb-0 me-3 text-dark">
                                                                <i class="fa fa-eye"
                                                                   style="color:#154068; font-size:12pt !important"></i>
                                                            </p>
                                                        </span>
                            <p class="title pt-0 mt-0 text-danger fw-bolder"
                               v-if="product.discount > 0">
                              %{{
                                parseFloat(product.discount)
                              }} OFF</p>
                            <p class="title pt-0 mt-0" v-else></p>
                          </div>

                        </div>
                      </article>
                    </div>

                  </div>
                </router-link>
              </Slide>
              <template #addons>
                <Navigation/>
              </template>

            </Carousel>

            <Carousel :items-to-show="1.8" :items-to-scroll="2" :wrap-around="true" class="mt-1"
                      style="font-size:48pt; height:35vh !important;transition-duration: 2s;" v-else>

              <Slide v-for="product in categoryProduct.products" :key="product.id">
                <router-link :to="{
                name: 'ProductDetail',
                params: { id: product.id }
            }" class="text-white">
                  <div class="carousel__item" style="width:0% !important;">
                    <div class="product-item"
                         style="height: 65% !important; border: 2px solid #ddd !important; border-radius: 20px !important;padding: 10px !important">
                      <article style="overflow: hidden">
                        <div class="figure-grid">

                          <div class="image">
                            <router-link :to="{
                name: 'ProductDetail',
                params: { id: product.id }
            }" class="mfp-open">
                              <img :src="'https://api.gocami.com/product_main/' + product.image"
                                   alt="" class="product-image"/>
                            </router-link>
                          </div>

                          <div class="text text-start ms-3">
                            <h2 class="title h5 pb-0 mb-0 py-3" style="color: black">{{
                                product.product_name
                              }}
                            </h2>
                            <span class="d-flex justify-content-between"
                                  v-if="product.discount > 0">
                                                            <p class="title pt-0 mt-0 pb-0 mb-0 text-dark fw-bolder"
                                                               v-if="product.price"> <del class="text-danger">${{
                                                                product.price.toFixed(2)
                                                              }}</del> ${{
                                                                Math.floor(product.price -
                                                                    (product.price * product.price)
                                                                    /
                                                                    100)
                                                              }}.<span
                                                                  style="vertical-align: super; font-size:90% !important">
                                                                    {{
                                                                  (
                                                                      product.price -
                                                                      (product.price *
                                                                          product.discount) /
                                                                      100
                                                                  ).toFixed(2).slice(-2)
                                                                }}
                                                                </span>
                                                            </p>

                                                            <p class="title pt-0 mt-0 pb-0 mb-0 me-3 text-dark">
                                                                <i class="fa fa-eye"
                                                                   style="color:#154068; font-size:12pt !important"></i>
                                                            </p>
                                                        </span>

                            <span class="d-flex justify-content-between" v-else>
                                                            <p class="title pt-0 mt-0 pb-0 mb-0 text-dark"
                                                               v-if="product.old_price && product.old_price > product.price">
                                                              ${{ product.price.toFixed(2) }}
                                                              <del class="text-danger">${{
                                                                  product.old_price.toFixed(2)
                                                                }}</del>
                                                            </p>

                                                          <p class="title pt-0 mt-0 pb-0 mb-0 text-dark" v-else>
                                                                ${{ product.price.toFixed(2) }}
                                                            </p>

                                                            <p class="title pt-0 mt-0 pb-0 mb-0 me-3 text-dark">
                                                                <i class="fa fa-eye"
                                                                   style="color:#154068; font-size:12pt !important"></i>
                                                            </p>
                                                        </span>

                            <p class="title pt-0 mt-0 text-danger fw-bolder"
                               v-if="product.discount > 0">
                              %{{
                                parseFloat(product.discount)
                              }} OFF</p>
                            <p class="title pt-0 mt-0" v-else></p>
                          </div>

                        </div>
                      </article>
                    </div>

                  </div>
                </router-link>
              </Slide>
              <template #addons>
                <Navigation/>
              </template>

            </Carousel>


          </div>
        </div>
      </div>


      <!-- <div class="row justify-content-center text-center mx-1">
              <div class="col-12">
                  <img v-if="isPC" src="https://api.gocami.com/photos/gocami.gif" style="border-radius: 10px;" />
                  <img v-else src="https://api.gocami.com/photos/gocami.gif" class="mt-4"
                      style="border-radius: 10px; height:120px !important" />
              </div>

          </div> -->

<!--      <div class="row mx-lg-5 d-flex mt-5">-->
<!--        <div v-if="isPC" class="col-7">-->
<!--          <img src="https://api.gocami.com/photos/photo2.webp" usemap="#workmap" alt="Product Image"-->
<!--               style="height: auto !important"/>-->
<!--          <map name="workmap">-->
<!--            <area shape="rect" coords="0,0,340,190" href="/brand-view/12" alt="Lapitak"/>-->
<!--            <area shape="rect" coords="350,200,600, 230" href="/brand-view/6" alt="Dermokil"/>-->
<!--          </map>-->
<!--        </div>-->

<!--        <div v-else class="col-7">-->
<!--          <img src="https://api.gocami.com/photos/photo2.webp" usemap="#workmap" alt="Product Image"-->
<!--               style="height: auto !important"/>-->
<!--          <map name="workmap">-->
<!--            <area shape="rect" coords="0,0,170,150" href="/brand-view/12" alt="Lapitak"/>-->
<!--            <area shape="rect" coords="0,0,300,190" href="/brand-view/6" alt="Dermokil"/>-->
<!--          </map>-->
<!--        </div>-->

<!--        <div class="col-5">-->
<!--          <router-link to="/productdetail-view/1334">-->
<!--            <img src="https://api.gocami.com/photos/photo1.webp" style="height: auto !important;"/>-->
<!--          </router-link>-->
<!--        </div>-->
<!--      </div>-->


      <!-- <div class="text-center justify-content-center mt-5 px-5 mar-t pad-x">
          <hr />
          <h1 class="shop-category">Shop by category</h1>
          <hr />
      </div>

      <section class="blog blog-block blog-intro my-5 mx-lg-5">
          <div class="row">
              <div class="category-scroll-container">
                  <div class="category-wrapper">
                      <div class="col-lg-3 col-sm-12 category-item" v-for="getCategory in getCategory"
                          :key="getCategory.id">
                          <router-link :to="{ name: 'Categories', params: { id: getCategory.category_name } }">
                              <img v-if="isPC"
                                  :src="'https://api.gocami.com/category_image/' + getCategory.category_image"
                                  class="my-2 category-image" :alt="getCategory.category_name"
                                  :title="getCategory.category_name" />
                              <img v-else :src="'https://api.gocami.com/photos/' + getCategory.banner_category"
                                  class="my-2 categories" :alt="getCategory.category_name"
                                  :title="getCategory.category_name"
                                  style="width: 100%; height: 120px !important;" />
                          </router-link>
                      </div>
                  </div>
              </div>
          </div>
      </section> -->


      <section class="blog blog-block blog-intro my-5">
        <div class="row">
          <div class="col-12" v-for="flash in flash" :key="flash.id">
            <router-link :to="flash.flash_url">
              <img :src="'https://api.gocami.com/sale/' + flash.flash_image" class="my-2"/>
            </router-link>
          </div>
        </div>
      </section>


      <div class="row justify-content-center text-center mx-3 mb-3" v-if="bundles.length > 0">
        <!-- <div class="col-12">
            <img v-if="isPC" src="https://api.gocami.com/photos/winter.gif"
                style="border-radius: 10px;margin-bottom: 25px;" alt="winter offer" />
            <img v-else src="https://api.gocami.com/photos/winter.gif" class="mt-4"
                style="border-radius: 10px; margin-bottom: 25px; height:120px !important" alt="winter offer" />
        </div> -->

      </div>


<!--      <div class="row row-clean" v-if="bundles.length > 0">-->
<!--        <div class="bundle-container mx-2" ref="bundleContainer">-->
<!--          <div class="bundle-items">-->
<!--            &lt;!&ndash; Loop through bundles &ndash;&gt;-->
<!--            <div v-for="bundle in bundles" :key="bundle.id" class="bundle-item">-->
<!--              <router-link :to="{ name: 'HomeBundleView', params: { id: bundle.id } }" class="mfp-open">-->
<!--                <img :src="'https://api.gocami.com/bundle_image/' + bundle.bundle_image" alt=""-->
<!--                     class="product-image" style="width: 300px !important;"/>-->
<!--                <div class="text">-->
<!--                  <h2 class="title h5">{{ bundle.bundle_name }}</h2>-->
<!--                  <sup>$ {{ bundle.bundle_price.toFixed(2) }}</sup>-->
<!--                </div>-->
<!--              </router-link>-->
<!--            </div>-->
<!--            &lt;!&ndash; End loop &ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="d-flex text-center justify-content-center mt-4">-->
<!--          <button @click="scrollBundleLeft" class="arrow-btn bundle-left">-->
<!--            <span class="bg-white p-3 rounded shadow circle"><i class="pi pi-chevron-left"></i></span>-->
<!--          </button>-->
<!--          <button @click="scrollBundleRight" class="arrow-btn bundle-right">-->
<!--            <span class="bg-white p-3 rounded shadow circle"><i class="pi pi-chevron-right"></i></span>-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->

      <!--
          <img src="https://api.gocami.com/photos/seller.webp" />


          <div class="row row-clean products-row mt-3">

              <div class="product-navigation">

                  <button @click="previousPage" :disabled="currentPage === 1" class="arrow-button arrow-left">
                      <span class="bg-white p-3 rounded shadow circle"><i class="pi pi-chevron-left"
                              style="padding-top: 10px !important; margin-top: 10px !important"></i></span>
                  </button>
                  <div class="product-item mx-1 d-flex" v-for="topProductDetails in paginatedProducts"
                      :key="topProductDetails.id">
                      <article style="overflow: hidden">
                          <div class="figure-grid">
                              <span class="label label-danger" v-if="topProductDetails.productdetail.discount > 50">
                                  -{{ parseFloat(topProductDetails.productdetail.discount) }}%
                              </span>
                              <span class="label label-info" v-else-if="topProductDetails.productdetail.discount < 50 &&
                                  topProductDetails.productdetail.discount > 0
                                  ">
                                  -{{ parseFloat(topProductDetails.productdetail.discount) }}%
                              </span>

                              <div class="image">
                                  <router-link :to="{
                                      name: 'ProductDetail',
                                      params: { id: topProductDetails.productdetail.product_id },
                                  }" class="mfp-open">
                                      <img :src="'https://api.gocami.com/product_detail/' +
                                          topProductDetails.productdetail.image
                                          " alt="" class="product-image" />
                                  </router-link>
                              </div>
                              <div class="text" v-if="topProductDetails.productdetail.discount > 0">
                                  <h2 class="title h5" style="color:black">{{ topProductDetails.productdetail.title }}
                                  </h2>
                                  <sub style="color:black">$ {{ Math.floor(topProductDetails.productdetail.sale_price)
                                  }}.<span style="vertical-align: super; font-size: 90% !important;">{{
(topProductDetails.productdetail.sale_price % 1).toFixed(2).slice(-2) }}
                                      </span></sub>

                                  <sup style="color:black">
                                      - $productsOnSale
                                  </sup>
                              </div>

                              <div class="text" v-else>
                                  <h2 class="title h5" style="color:black">{{ topProductDetails.productdetail.title }}
                                  </h2>
                                  <sup style="color:black">$ {{ Math.floor(topProductDetails.productdetail.sale_price)
                                  }}.<span style="vertical-align: super; font-size: 90% !important;">{{
(topProductDetails.productdetail.sale_price % 1).toFixed(2).slice(-2) }}
                                      </span></sup>
                              </div>
                          </div>
                      </article>
                  </div>

                  <button @click="nextPage" :disabled="currentPage === totalPages" class="arrow-button arrow-right">
                      <span class="bg-white p-3 rounded shadow circle"><i class="pi pi-chevron-right"
                              style="padding-top: 10px !important; margin-top: 10px !important"></i></span>
                  </button>
              </div>
          </div>

-->

<!--      <img src="https://api.gocami.com/photos/onsale.webp"/>-->

<!--      <div v-if="loading" class="loading-indicator">-->
<!--        <i class="fa fa-circle-o-notch fa-spin" style="font-size:24px !important"></i>-->
<!--      </div>-->

<!--      <div v-else>-->
<!--        <div class="row row-clean products-row mt-3 mb-5">-->

<!--          <div class="product-navigation">-->

<!--            <button @click="previousPageSale" :disabled="currentPageSale === 1"-->
<!--                    class="arrow-button arrow-left">-->
<!--                            <span class="bg-white p-3 rounded shadow circle"><i class="pi pi-chevron-left"-->
<!--                                                                                style="padding-top: 10px !important; margin-top: 10px !important"></i></span>-->
<!--            </button>-->
<!--            <div class="product-item-product mx-1"-->
<!--                 style="width:180px !important ;border: 2px solid #ddd !important; border-radius: 20px !important;padding: 10px !important"-->
<!--                 v-for="productsOnSale in paginatedProductsOnSale"-->
<!--                 :key="productsOnSale.id">-->
<!--              <article style="overflow: hidden">-->
<!--                <div class="figure-grid">-->

<!--                  <div class="image">-->
<!--                    <router-link :to="{-->
<!--                name: 'ProductDetail',-->
<!--                params: { id: productsOnSale.product_id }-->
<!--            }" class="mfp-open">-->
<!--                      <img :src="'https://api.gocami.com/product_detail/' + productsOnSale.image"-->
<!--                           alt="" class="product-image"/>-->
<!--                    </router-link>-->
<!--                  </div>-->

<!--                  <div class="text text-start ms-3">-->
<!--                    <h2 class="title h5 pb-0 mb-0 py-2" style="color: black">{{ productsOnSale.title }}-->
<!--                    </h2>-->
<!--                    <span class="d-flex justify-content-between" v-if="productsOnSale.discount > 0">-->
<!--                                            <p class="title pt-0 mt-0 pb-0 mb-0 text-dark fw-bolder"> <del-->
<!--                                                class="text-danger">${{-->
<!--                                                productsOnSale.sale_price.toFixed(2)-->
<!--                                              }}</del> ${{-->
<!--                                                Math.floor(productsOnSale.sale_price - -->
<!--                                                    (productsOnSale.sale_price * productsOnSale.discount)-->
<!--                                                    /-->
<!--                                                    100)-->
<!--                                              }}.<span style="vertical-align: super; font-size:90% !important">-->
<!--                                                    {{-->
<!--                                                  (-->
<!--                                                      productsOnSale.sale_price - -->
<!--                                                      (productsOnSale.sale_price *-->
<!--                                                          productsOnSale.discount) /-->
<!--                                                      100-->
<!--                                                  ).toFixed(2).slice(-2)-->
<!--                                                }}-->
<!--                                                </span>-->
<!--                                            </p>-->

<!--                                            <p class="title pt-0 mt-0 pb-0 mb-0 me-3 text-dark">-->
<!--                                                                <i class="fa fa-eye"-->
<!--                                                                   style="color:#154068; font-size:12pt !important"></i>-->
<!--                                                            </p>-->

<!--                                        </span>-->
<!--                    <span class="d-flex justify-content-between" v-else>-->
<!--                                            <p class="title pt-0 mt-0 pb-0 mb-0"> ${{-->
<!--                                                productsOnSale.sale_price.toFixed(2)-->
<!--                                              }}-->
<!--                                            </p>-->

<!--                                            <p class="title pt-0 mt-0 pb-0 mb-0 me-3 text-dark">-->
<!--                                                                <i class="fa fa-eye"-->
<!--                                                                   style="color:#154068; font-size:12pt !important"></i>-->
<!--                                                            </p>-->
<!--                                        </span>-->

<!--                    <p class="title pt-0 mt-0 text-danger fw-bolder"-->
<!--                       v-if="productsOnSale.discount > 0">-->
<!--                      %{{-->
<!--                        parseFloat(productsOnSale.discount)-->
<!--                      }} OFF</p>-->
<!--                    <p class="title pt-0 mt-0" v-else></p>-->
<!--                  </div>-->

<!--                </div>-->
<!--              </article>-->
<!--            </div>-->

<!--            <button @click="nextPageSale" :disabled="currentPageSale === totalPagesOnSale"-->
<!--                    class="arrow-button arrow-right">-->
<!--                            <span class="bg-white p-3 rounded shadow circle"><i class="pi pi-chevron-right"-->
<!--                                                                                style="padding-top: 10px !important; margin-top: 10px !important"></i></span>-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->


    </div>

  </div>

  <FooterLayout/>

  <!-- </div> -->
</template>

<script>

import FooterLayout from "./FooterLayout.vue";
import axiosInstance from '../../../../axiosInstance';

import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import {useUserStore} from "../../../../store";
import 'primeicons/primeicons.css'

import 'vue3-carousel/dist/carousel.css'
import {Carousel, Navigation, Slide} from 'vue3-carousel'
import {nextTick} from "vue";

export default {
  components: {
    FooterLayout, Splide, SplideSlide, Carousel,
    Slide,
    Navigation,
  },
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },

  beforeRouteEnter(to, from, next) {
    if (to.query.addToCart) {
      next(vm => {
        vm.showSuccessMessage = true;
        setTimeout(() => {
          vm.showSuccessMessage = false;
        }, 5000);
      });
    } else {
      next();
    }
  },

  computed: {

    totalPages() {
      return Math.ceil(this.topProductDetails.length / this.itemsPerPage);
    },

    totalPagesOnSale() {
      return Math.ceil(this.productsOnSale.length / this.itemsPerPage);
    },

    totalPagesArrival() {
      return Math.ceil(this.newProd.length / this.itemsPerPage);
    },

    paginatedProducts() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.topProductDetails.slice(startIndex, endIndex);
    },

    paginatedProductsOnSale() {
      const startIndex = (this.currentPageSale - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.productsOnSale.slice(startIndex, endIndex);
    },

    paginatedProductsArrival() {
      const startIndex = (this.currentPageArrival - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.newProd.slice(startIndex, endIndex);
    },

    segmentColors() {
      if (!this.productspinner || this.productspinner.length === 0) {
        return [];
      }

      const numSegments = this.productspinner.length;
      const colors = ['#39A1E8', '#CA3D77', '#FBA629', '#2FAF74', '#F3F3E9'];
      const colorCount = colors.length;

      const segmentedColors = [];

      for (let i = 0; i < numSegments; i++) {
        const colorIndex = i % colorCount;
        segmentedColors.push(colors[colorIndex]);
      }

      return segmentedColors;
    },

    shuffledProductspinner() {
      // Create a copy of the original array and shuffle it
      const shuffledArray = [...this.productspinner];
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }
      return shuffledArray;
    },

    randomIndex() {
      return () => Math.floor(Math.random() * this.spinners.length);
    },

    randomProductIndex() {
      return () => Math.floor(Math.random() * this.productspinner.length);
    },
    // wheelStyle() {
    //     return {
    //         transform: `rotate(${this.rotationAngle}deg)`,
    //     };
    // },
    // pinStyle() {
    //     return {
    //         transform: `translateX(-50%) rotate(-${this.rotationAngle}deg)`, // Position the pin at the top center
    //     };
    // },


  },

  data() {
    return {
      items: [],
      videoSource: 'https://api.gocami.com/sale/discount.mp4', // Replace with your video file path
      videoSourceMobile: 'https://api.gocami.com/sale/discount-mobile.mp4', // Replace with your video file path
      showControls: false,
      newProd: [],
      getSpinerBazar: [],
      copyMessage: false,
      explosions: [],
      numExplosions: 5, // Number of explosions to create
      freeze: false,
      rolling: false,
      productRolling: false,
      wheelDeg: 0,
      selectedPrize: null,
      spinners: [],
      showPopup2: false,
      showPopup3: false,
      showPopup: false,
      selectedProduct: null,
      selectedProductDetails: [], // Initialize as an empty array
      splideOptions: {
        type: 'carousel',
        autoplay: true,
        interval: 3000, // Adjust the interval (in milliseconds) between each slide
        rewind: true, // Go back to the first slide when reaching the last slide
      },
      cart: {
        quantity: 1,
      },
      scrollPosition: 0, // Local reactive state for scroll position
      clickPosition: { x: 0, y: 0 }, // Local reactive state for click position
      allCateg: [],
      subCategoriesByCategory: [],
      dataSpinner: [],
      selectedAttribute: null,
      displayedAttributes: [],
      showAllAttributes: false,
      showAllColors: false,
      showAllSizes: false,
      searchQuery: '',
      allProducts: [],
      colors: new Set(),
      sizes: new Set(),
      brands: [],
      related: [],
      selectedColor: null,
      selectedSize: null,
      previousColorData: null,
      attributes: new Set(),
      isPC: false,
      products: [],
      perPage: 18,
      // totalPages: 0,
      productsContainer: null,
      scrollStepPC: 1100,
      scrollStepMobile: 300,
      scrollOffset: 0,
      sliders: [],
      categories: [],
      countdowns: [],
      productsales: [],
      bundles: [],
      sales: [],
      brand: [],
      proddet: [],
      productbrands: [],
      subcategories: [],
      childcategories: [],
      productdiscounts: [],
      loading: true, // Add this flag
      countdownIntervals: {},
      randomSubcategoriess: [],
      perPages: 5, // Initial number of items to load
      loadingMore: false, // Indicator for loading more content
      isLoading: false, // Initial loading indicator
      currentPage: 1, // Current page for fetching data
      publicKey: this.user?.first_name.concat(" ", this.user?.last_name),
      message: '',
      isError: false,
      showSuccessMessage: false,
      productspinner: [],
      getProducts: [],
      // newData: [],
      numSegments: 0, // Calculate the number of segments
      getCategory: [],
      flash: [],
      subCategory: [],
      bundle: [],
      topProductDetails: [],
      itemsPerPage: 8,
      productsOnSale: [],
      currentPageSale: 1,
      currentPageArrival: 1,
      allBrands: [],
      // scrollStep: 120,
      countDown: [],
      counter: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      showCountdown: false, // Initialize to false
      countDownNumber: [],
      categoryProduct: []
    };
  },

  created() {

    this.allBrand();
    this.fetchSlider();
    this.getSpinner();
    this.fetchSpinner();
    this.createExplosions();
    this.getSpinnerBazar();
    this.fetchProductSpinner();
    this.GetProductSpinner();
    // this.getNewData();
    this.calculateNumSegments();
    this.mainHome();
    this.getCountDown();
    this.getCountDownNumber();
    this.getHomeContent();
    // this.fetchApiData();
  },

  methods: {
    handleClick() {
      // Store the current scroll position before navigating
      this.scrollPosition = window.scrollY; // Get the current scroll position
      localStorage.setItem('scrollPosition', this.scrollPosition); // Store in localStorage
    },
    // fetchApiData() {
    //     axiosInstance.get('http://localhost:8000/api/fetch-data')
    //         .then(response => {
    //             // console.log(response.data);
    //             this.items = response.data;
    //         })
    //         .catch(error => {
    //             this.errorMessage = error.response.data.error;
    //         });
    // },

    // createQuotationForItem(item) {
    //     const quotationData = {
    //         client_id: this.user?.id || '', // Use empty string as default if user ID is not available
    //         client_name: `${this.user?.first_name || ''} ${this.user?.last_name || ''}`.trim(),
    //         invoice_id: '1',
    //         items: [item]
    //     };

    //     axiosInstance.post('http://localhost:8000/api/create-quotation', quotationData)
    //         .then(response => {
    //             console.log(response.data);
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         });
    // },

    restartVideo() {
      // Restart the video by setting currentTime to 0 and playing it
      this.$refs.videoPlayer.currentTime = 0;
      this.$refs.videoPlayer.play();
    },

    scrollBundleLeft() {
      this.$refs.bundleContainer.scrollLeft -= 500; // Adjust the scroll distance as needed
    },
    scrollBundleRight() {
      this.$refs.bundleContainer.scrollLeft += 500; // Adjust the scroll distance as needed
    },

    scrollLeft() {
      const scrollStep = this.isPC ? this.scrollStepPC : this.scrollStepMobile;
      this.animateScroll(this.$refs.scrollingContainer, -scrollStep);
    },
    scrollRight() {
      const scrollStep = this.isPC ? this.scrollStepPC : this.scrollStepMobile;
      this.animateScroll(this.$refs.scrollingContainer, scrollStep);
    },

    animateScroll(element, scrollStep) {
      const start = element.scrollLeft;
      const target = start + scrollStep;
      const duration = 500; // Adjust the animation duration in milliseconds
      const startTime = performance.now();

      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;

        element.scrollLeft = this.easeInOutQuad(elapsedTime, start, target - start, duration);

        if (elapsedTime < duration) {
          requestAnimationFrame(animate);
        }
      };

      requestAnimationFrame(animate);
    },

    easeInOutQuad(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    },

    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
      }
    },

    nextPageSale() {
      if (this.currentPageSale < this.totalPagesOnSale) {
        this.currentPageSale += 1;
      }
    },

    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },

    previousPageSale() {
      if (this.currentPageSale > 1) {
        this.currentPageSale -= 1;
      }
    },

    nextPageArrival() {
      if (this.currentPageArrival < this.totalPagesArrival) {
        this.currentPageArrival += 1;
      }
    },

    previousPageArrival() {
      if (this.currentPageArrival > 1) {
        this.currentPageArrival -= 1;
      }
    },

    getHomeContent() {
      this.loading = true;
      var page = `api/get-home-content`; // Assuming this is the correct endpoint
      axiosInstance.get(page)
          .then(response => {
            this.categoryProduct = response.data.categories;
            // console.log('Category Product:', this.categoryProduct);
            this.loading = false;

            nextTick(() => {
              setTimeout(() => {
                const storedScrollPosition = localStorage.getItem('scrollPosition');
                if (storedScrollPosition) {
                  const position = parseInt(storedScrollPosition, 10);
                  window.scrollTo(0, position); // Restore stored scroll position
                }

                // Set scroll position to 0 after restoring
                localStorage.removeItem('scrollPosition');
              }, 50); // Adding a brief delay for DOM stability
            });
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            this.loading = false;
          });
    },

    mainHome() {
      this.loading = true; // Set loading to true when starting to fetch data

      var page = `api/user-view`;
      axiosInstance.get(page).then(({data}) => {
        this.newProd = data.newProd;
        this.getCategory = data.category;
        this.flash = data.flash;
        this.subCategory = data.subCategory;
        this.bundles = data.bundle;
        this.topProductDetails = data.topProductDetails;
        this.currentPage = 1; // Reset to the first page when new data is loaded
        this.productsOnSale = data.productsOnSale;

        this.loading = false; // Set loading to false when data is successfully fetched
      }).catch(error => {
        console.error('Error fetching data:', error);
        this.loading = false; // Set loading to false even if there's an error
      });
    },

    allBrand() {
      var page = `api/get-all-brands`;
      axiosInstance.get(page).then(({data}) => {
        this.allBrands = data.brands;
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    },

    openPopup2() {
      this.showPopup2 = true;
    },
    // getNewData() {
    //     var page = `api/get-new-data`;
    //     axiosInstance
    //         .get(page)
    //         .then(({ data }) => {
    //             this.newData = data;
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching New Data:", error);
    //         });
    // },

    copyToClipboard() {
      const inputElement = this.$refs.spinnerInput;
      inputElement.select();
      document.execCommand("copy");
      window.getSelection().removeAllRanges(); // Deselect the text
      this.copyMessage = true;
      this.copyMessage = "Text copied to clipboard!";
      // Optionally, you can clear the message after a delay
      setTimeout(() => {
        this.copyMessage = false;
      }, 7000); // Message will disappear after 2 seconds (adjust the time as needed)
    },

    createExplosions() {
      for (let i = 0; i < this.numExplosions; i++) {
        this.addExplosion();
      }
    },
    addExplosion() {
      const explosion = {
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        animationDuration: `${0.5 + Math.random() * 1.5}s`,
        animationDelay: `${Math.random() * 2}s`,
      };
      this.explosions.push(explosion);
    },
    removeExplosion(index) {
      this.explosions.splice(index, 1);
      this.addExplosion();
    },

    sendSpinProResultToServer(selectedItemId) {
      // Define the correct API endpoint for inserting the selected item's ID
      const endpoint = 'api/insert-product-spinner'; // Remove the leading slash

      // Create the payload to send to the server
      const payload = {
        product_detail_id: selectedItemId, // Send the selected item's ID
        user_id: this.user?.id, // Replace with the actual user ID
      };

      // Make a POST request to insert the spin result into the database
      axiosInstance
          .post(endpoint, payload)
          .then((response) => {
            this.GetProductSpinner();
            // Handle the server response to ensure successful insertion
            if (response.data === 'Success!!') {
              console.log()
            } else {
              console.error('Error inserting spin result:', response.data);
            }
          })
          .catch((error) => {
            console.error('Error sending spin result to server:', error);
          });
    },

    onClickRotate() {
      if (this.rolling) {
        return;
      }
      if (!this.spinners || this.spinners.length === 0) {
        alert("No prizes available to spin.");
        return;
      }
      const result = this.randomIndex();
      this.roll(result);
    },

    onClickProductRotate() {
      if (this.productRolling) {
        return;
      }
      if (!this.productspinner || this.productspinner.length === 0) {
        alert("No prizes available to spin.");
        return;
      }
      const result = this.randomIndex();
      this.rollProduct(result);
    },

    roll(result) {
      this.rolling = true;
      const {wheelDeg, spinners} = this;
      this.wheelDeg =
          wheelDeg -
          wheelDeg % 360 +
          100 * 360 +
          (360 - 360 / spinners.length * result);
      setTimeout(() => {
        this.rolling = false;
        this.selectedPrize = spinners[result].id; // Display the ID of the selected item
        // this.sendSpinResultToServer(spinners[result].id);
        this.showPopup2 = false;
        this.showPopup3 = true;
      }, 4500);
    },

    onClickRotateProduct() {
      if (this.productRolling) {
        return;
      }
      if (!this.productspinner || this.productspinner.length === 0) {
        alert("No prizes available to spin.");
        return;
      }
      const result = this.randomProductIndex();
      this.rollProduct(result);
    },
    calculateNumSegments() {
      this.numSegments = this.productspinner.length;
    },

    rollProduct(result) {
      this.productRolling = true;
      const {wheelDeg, productspinner} = this;
      this.wheelDeg =
          wheelDeg -
          wheelDeg % 360 +
          6 * 360 +
          (360 - 360 / productspinner.length * result);
      setTimeout(() => {
        this.productRolling = false;
        this.selectedPrize = productspinner[result].product_detail_id;
        this.sendSpinProResultToServer(productspinner[result].product_detail_id);
        this.showPopup2 = false;
        this.showPopup3 = true;
      }, 4500);
    },

    copyMessages() {
      this.copyMessage = false;
    },

    closePopup2() {
      this.showPopup2 = false;
    },

    closePopup3() {
      this.showPopup3 = false;
    },

    save() {
      this.saveData();
    },

    closePopup() {
      this.selectedProduct = null;
      this.selectedProductDetails = [];
      this.showPopup = false; // Hide the popup
    },

    togglePopup() {
      this.showPopup = !this.showPopup;
    },

    checkIfPC() {
      this.isPC = window.innerWidth >= 768;
    },


    // showSuccessMessage(message) {
    //     this.showMessage(message, false);
    // },

    showErrorMessage(message) {
      this.showMessage(message, true);
    },

    showMessage(message, isError) {
      this.showSuccessMessage = true;
      this.message = message;
      this.isError = isError;
      this.hideMessageAfterDelay(5000);
    },

    isVideo(brand) {
      return brand.type === "video";
    },

    fetchSlider() {
      var page = `api/slider`;
      axiosInstance.get(page).then(({data}) => {
        this.sliders = data.slider;
      });
    },

    GetProductSpinner() {
      var page = `api/get-product-spinner?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({data}) => {
        this.getProducts = data;
      });
    },


    fetchProductSpinner() {
      var page = `api/product-spinner?genderId=${this.user?.gender_id}&dateOfBirth=${this.user?.date_of_birth}`;
      axiosInstance.get(page).then(({data}) => {
        this.productspinner = data;
      });
    },

    fetchData() {
      axiosInstance
          .get(`api/user-get?user_id=${this.user?.id}`)
          .then(() => {
          })
          .catch(() => {
          });
    },

    fetchSale() {
      axiosInstance.get(`api/sale-admin`)
          .then(response => {
            this.sales = response.data;
            // this.startCountdownTimers();
          })
          .catch(error => {
            console.error(error);
          });
    },

    getSpinner() {
      var page = `api/spinner-discount`;
      axiosInstance
          .get(page)
          .then(({data}) => {
            this.isLoading = true; // Set loading state
            this.spinners = data;
          })
          .catch((error) => {
            console.error("Error fetching spinners:", error);
          });
    },

    getSpinnerBazar() {
      var page = `api/get-spinner-bazar`;
      axiosInstance
          .get(page)
          .then(({data}) => {
            this.getSpinerBazar = data;
          })
          .catch((error) => {
            console.error("Error fetching spinners:", error);
          });
    },

    getCountDownNumber() {
      var page = `api/get-count-down`;
      axiosInstance
          .get(page)
          .then(({data}) => {
            this.countDownNumber = data.countDown;
          })
          .catch((error) => {
            console.error("Error fetching spinners:", error);
          });
    },

    getCountDown() {
      var page = `api/get-count-down`;
      axiosInstance
          .get(page)
          .then(({data}) => {
            const toTimestamp = new Date(data.countDown.to_date).getTime();

            const currentTime = new Date().getTime();
            if (currentTime > toTimestamp) {
              // console.log("Countdown has already ended.");
              return;
            }

            this.showCountdown = true;

            const intervalId = setInterval(() => {
              const currentTime = new Date().getTime();
              const remainingTime = Math.max(0, toTimestamp - currentTime);

              const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
              const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
              const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
              const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

              this.counter = {
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds
              };

              if (remainingTime <= 0) {
                clearInterval(intervalId);
              }
            }, 1000);
          })
          .catch((error) => {
            console.error("Error fetching spinners:", error);
          });
    },

    fetchSpinner() {
      var page = `api/get-inserted-spinner?user_id=${this.user?.id}`;
      axiosInstance
          .get(page)
          .then(({data}) => {
            this.isLoading = true; // Set loading state to false after data is loaded or an error occurs
            this.dataSpinner = data;
          })
          .catch((error) => {
            console.error("Error fetching spinners:", error);
          })
          .finally(() => {
            this.isLoading = false; // Set loading state to false regardless of success or failure
          });
    },

    // infiniteScroll() {
    //     if (
    //         window.innerHeight + window.scrollY >=
    //         document.body.offsetHeight - 100
    //     ) {
    //         if (!this.loadingMore && this.currentPages < this.totalPagess) {
    //             this.loadingMore = true;
    //             this.currentPages++;
    //             this.categoryLoad(true); // Pass 'true' to indicate loading more
    //         }
    //     }
    // },

    // getSubcategories(categoryId) {
    //     Filter the subcategories based on the category ID
    //     return this.subcategories.filter(subcategory => subcategory.category_id === categoryId);
    // },

    hideMessageAfterDelay(delay) {
      setTimeout(() => {
        this.showSuccessMessage = false; // Hide the toast message after the specified delay
      }, delay);
    },
  },

  watch: {
    prizeNumber() {
      this.freeze = true;
      this.wheelDeg = 0;

      setTimeout(() => {
        this.freeze = false;
      }, 0);
    },
    productspinner: 'calculateNumSegments',

  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);

    window.removeEventListener("resize", this.checkIfPC);
  },
  mounted() {

    this.getHomeContent();
    this.getCountDown();

    // Access the iframe element and append autoplay parameter

    window.addEventListener('scroll', this.handleScroll);

    this.checkIfPC();
    window.addEventListener("resize", this.checkIfPC);
    this.fetchSale();
    this.fetchData();
    this.productsContainer = document.querySelector(".products-container");

    if (window.innerWidth <= 768) {
      const carousel = this.$refs.carousel;
      if (carousel) {
        let isDragging = false;
        let startPosition = 0;
        let currentTranslate = 0;
        let prevTranslate = 0;
        let animationId = 0;

        const dragStart = (event) => {
          if (event.type === 'touchstart') {
            startPosition = event.touches[0].clientX;
          } else {
            startPosition = event.clientX;
          }
          isDragging = true;

          animationId = requestAnimationFrame(animation);
          carousel.classList.add('grabbing');
        };

        const drag = (event) => {
          if (isDragging) {
            let currentPosition = 0;
            if (event.type === 'touchmove') {
              currentPosition = event.touches[0].clientX;
            } else {
              currentPosition = event.clientX;
            }
            const diff = currentPosition - startPosition;
            currentTranslate = prevTranslate + diff;

            const minTranslate = 0;
            const maxTranslate = carousel.offsetWidth - carousel.scrollWidth;

            if (currentTranslate > minTranslate) {
              currentTranslate = minTranslate;
            } else if (currentTranslate < maxTranslate) {
              currentTranslate = maxTranslate;
            }
          }
        };

        const dragEnd = () => {
          cancelAnimationFrame(animationId);
          isDragging = false;
          prevTranslate = currentTranslate;

          carousel.classList.remove('grabbing');
        };

        const animation = () => {
          carousel.style.transform = `translateX(${currentTranslate}px)`;
          if (isDragging) {
            requestAnimationFrame(animation);
          }
        };

        carousel.addEventListener('mousedown', dragStart);
        carousel.addEventListener('touchstart', dragStart);
        carousel.addEventListener('mouseup', dragEnd);
        carousel.addEventListener('touchend', dragEnd);
        carousel.addEventListener('mouseleave', dragEnd);
        carousel.addEventListener('mousemove', drag);
        carousel.addEventListener('touchmove', drag);
        carousel.addEventListener('dragstart', (e) => e.preventDefault());
      }
    }
  },
};
</script>

<style scoped>
/* @media screen and (min-width: 1200px) {
  #main-top {
    margin: 0 205px !important;
  }
} */

.fa-eye:hover {
  transform: scale(1.2);
}

.fa-eye {
  transition: 0.3s ease-in-out;
}

.carousel__icon {
  width: var(--vc-icn-width);
  height: var(--vc-icn-width);
  fill: currentColor;
  font-size: 50pt !important;

}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  background: var(--vc-nav-background);
  border-radius: var(--vc-nav-border-radius);
  width: var(--vc-nav-width);
  height: var(--vc-nav-height);
  text-align: center;
  font-size: 50pt !important;
  padding: 0;
  color: red !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 0;
  cursor: pointer;
  margin: 0 10px;
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 768px) {

  .line {
    background-color: #154068 !important;
    color: #154068 !important;
    border: 1px solid #154068 !important;
    width: 150px !important;
  }

  .countdown-container {
    flex-direction: column;
    /* Display items vertically */
    align-items: center;
    /* Center items horizontally */
  }

  .countdown-item {
    position: relative;
    /* Reset position for stacking items */
    margin-bottom: 10px;
    /* Add spacing between countdown items */
    right: auto;
    /* Reset right positioning */
    text-align: center;
    /* Center text */
  }

  .countdown-label {
    display: block;
    /* Display labels on separate lines */
    margin-top: 5px;
    /* Add margin between number and label */
  }

  /* Adjust position for Orders Left label */
  .countdown-item:last-child {
    position: relative;
    /* Reset position */
    margin: 0;
    /* Reset margin */
    top: 0;
    /* Reset top positioning */
  }
}


.countdown-container {
  position: relative;
  /* Ensure countdown items are positioned relative to this container */
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown-item {
  position: absolute;
  margin: 0 15px;
  text-align: center;
  animation: fadeIn 1s ease-out;
}

.countdown-number-container {
  background-color: white;
  border-radius: 5px;
  padding: 21px;
}

.countdown-number {
  font-size: 2em;
  font-weight: bold;
  color: #333;
}

.countdown-label {
  font-size: 0.8em;
  color: #666;
}


.bundle-left {
  left: 0;
}

.bundle-right {
  right: 0;
}

.arrow-btn {
  /* position: absolute; */
  top: 50%;
  transform: translateY(-50%);
  border: none;
  font-size: 20px;
  cursor: pointer;
  background-color: transparent;
  color: #888;
  padding: 10px;
}


.shop-category {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  /* Set your preferred font family */
  font-size: 18px !important;
  /* Set your preferred font size */
  color: #000;
  /* Set your preferred text color */
  letter-spacing: 2px;
  /* Set your preferred letter spacing */
  text-transform: uppercase;
}

hr {
  border: 1px solid #000;
  /* You can customize the color of the line */
  width: 30%;
  /* Adjust the width of the line as needed */
  display: inline-block;
  margin: 0 10px;
  /* Adjust the spacing between the line and text */
}

.circle {
  border-radius: 50% !important;
}


.col-lg-3 {
  display: flex;
  align-items: center;
}

.col-lg-3 img {
  height: 330px;
  width: 330px;
}

.text-center {
  text-align: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

@media screen and (min-width: 768px) {

  .line {
    background-color: #154068 !important;
    color: #154068 !important;
    border: 2px solid #154068 !important;
    width: 1000px !important;
  }


  .winter-offer {
    margin: 0;
    text-align: center;
    align-items: center;
  }

  .scrolling-container {
    position: relative;
    overflow-x: hidden;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    /* Display images horizontally */
  }

}


@media screen and (max-width: 767px) {
  .scrolling-container {
    position: relative;
    overflow-x: scroll;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    scroll-behavior: smooth;
    overflow-y: hidden;
  }

  .mar-t {
    margin-top: 10px !important;
    /* Adjust the margin for smaller screens */
  }

  .pad-x {
    padding-left: 10px !important;
    /* Adjust the padding for smaller screens */
    padding-right: 10px !important;
  }

  hr {
    border: 1px solid #000;
    /* You can customize the color of the line */
    width: 16%;
    /* Adjust the width of the line as needed */
    display: inline-block;
    margin: 0 10px;
    /* Adjust the spacing between the line and text */
  }

  .shop-category {
    font-size: 16px !important;
    /* Adjust the font size for smaller screens */
  }

  .btn-circle-right {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    margin-left: 10px !important;
    margin-right: 10px !important;
    /* Adjust the background color as needed */
  }

  .category-scroll-container {
    width: 100%;
    overflow-x: auto;
  }

  .category-wrapper {
    display: flex;
    /* Make the wrapper a flex container */
  }

  .category-item {
    flex: 0 0 auto;
    /* Do not grow or shrink, maintain the original size */
    margin-right: 10px;
  }

  .category-image {
    height: 330px;
    /* Adjust as needed */
    width: 100%;
    /* Take up the full width of the container */
  }

  .categories {
    height: 150px !important;
  }

  .row {
    position: relative;
    overflow-x: hidden;
    white-space: nowrap;
  }

  .col-lg-3 {
    display: inline-block;
    white-space: normal;
    vertical-align: top;
  }

  .col-lg-3 img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .row {
    flex-direction: column;
    margin: 0;
  }

  .col-7,
  .col-5 {
    flex-basis: 100%;
    max-width: 100%;
    margin: 0 0 20px;
    /* Add margin between columns for spacing */
  }


}

.pi {
  font-size: 14pt !important;
}

.product-navigation {
  display: flex;
  align-items: center;
  max-width: 1500px !important;
  overflow-x: auto;
}

.arrow-button {
  background-color: transparent;
  color: #888;
  padding: 10px;
  border: none;
  cursor: pointer;
  position: absolute;
}

video {
  pointer-events: none;
  /* Prevent hover effect from affecting the video */
}

.arrow-left {
  /* margin-left: 10px; */
  left: 0;
  z-index: 1;
  /* Add margin for spacing */
}

.arrow-right {
  margin-left: 10px;
  right: 0;
  /* Add margin for spacing */
}

img {
  width: 100%;
}

.figure-grid {
  position: relative;
  text-align: center;
  height: 100%;
  /* Ensure that the figure-grid takes the full height */
}

.products-row {
  display: flex;
  flex-wrap: wrap;
}

.bundle-container {
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  /* Enable smooth scrolling */
  transition: transform 0.3s ease;
  /* Add smooth transition effect */
}

.bundle-items {
  display: flex;
  animation: scrollAnimation 15s linear infinite;
}

.bundle-container:hover .bundle-items {
  animation-play-state: paused;
}

.bundle-item {
  flex: 0 0 auto;
  width: 10%;
  /* Adjust this as needed */
  max-width: 100%;
}

.product-image {
  width: 100%;
  height: auto;
}

.bundle-items .bundle-item {
  flex: 0 0 auto;
  /* Ensure each item has a fixed size */
  margin-right: 10px;
  /* Adjust margin as needed */
}

/* Keyframes for continuous scrolling animation */
/* @keyframes scrollAnimation {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }

} */

.btn-arrow-left,
.btn-arrow-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  border-radius: 50%;
}

.btn-circle-left,
.btn-circle-right {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
  margin-left: 8px !important;
  margin-right: 8px !important;
  z-index: 10 !important;
  /* Adjust the background color as needed */
}

.btn-circle-right {
  position: absolute;
  right: 0;
  /* Adjust the background color as needed */
}

.btn-circle-left {
  position: absolute;
  left: 0;
  /* Adjust the background color as needed */
}

.btn-arrow-left i,
.btn-arrow-right i {
  font-size: 1.5rem;
  /* Adjust the arrow size as needed */
  color: white;
  /* Adjust the arrow color as needed */
}

.btn-circle-left {
  left: 0;
}

.btn-circle-right {
  right: 0;
}

.sub-image {
  display: block;
  margin-right: 10px;
  transition: 0.5s ease-in-out;
  /* Adjust the spacing between images as needed */
}

.sub-image:hover {
  transform: scale(1.1);
}

/* .btn {
    margin-top: 10px;
} */

.rounded-circle {
  border-radius: 50%;
}

.col-1 img {
  width: 100px;
  height: 100px;
}

.col-1 {
  margin-right: 12px !important;
  flex-shrink: 0;
}

/* Media query for screens with a maximum width of 767px (typical mobile devices) */
@media screen and (max-width: 767px) {
  .bundle-container {
    display: inline-flex !important;
    min-width: 100%;
    overflow-x: auto;
    -ms-overflow-style: auto;
    scrollbar-width: auto;
    white-space: normal;
    /* Use 'normal' instead of 'inherit' */

    &::-webkit-scrollbar {
      display: auto;
    }
  }

  .sub-image {
    width: auto !important;
  }

  .col-1 {
    margin-right: 100px !important;
    flex-shrink: 0;
  }
}

.center-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.btn-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btn-spin {
  border-radius: 4px;
  background-color: #000;
  border: none;
  color: #fff;
  text-align: center;
  font-size: 32px;
  width: 30px;
  /* Set initial width to fit only the arrow */
  height: 40px;
  /* Set the fixed height */
  transition: all 0.5s;
  cursor: pointer;
  position: relative;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.7);
  overflow: hidden;
  /* Hide the overflow content */
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-spin i {
  opacity: 1;
  transition: 0.5s;
  position: relative;
  left: 0;
  width: 30px;
  /* Set initial width to fit only the arrow */
  margin-left: 37px;
}

.btn-spin span {
  opacity: 0;
  transition: 0.5s;
  margin-left: 0;
  width: 120px;
  /* Set the width for the text part */
}

.btn-spin:hover {
  width: 150px;
  /* Expand the button's width on hover */
}

.btn-spin:hover i {
  width: 40px;
  /* Adjust arrow width */
  margin-left: 0;
}

.btn-spin:hover span {
  opacity: 1;
  margin-left: 0;
}


.toast {
  position: fixed !important;
  max-width: 50% !important;
  top: 20px !important;
  right: -100% !important;
  /* Start offscreen on the right */
  background-color: #28a745 !important;
  color: #fff !important;
  padding: 12px 16px !important;
  border-radius: 4px !important;
  transition: right 0.5s ease-in-out !important;
  z-index: 99999999 !important;
}

.toast.error {
  position: fixed !important;
  max-width: 50% !important;
  top: 20px !important;
  right: -100% !important;
  /* Start offscreen on the right */
  background-color: #dc3545 !important;
  /* Background color for error */
  color: #fff !important;
  padding: 12px 16px !important;
  border-radius: 4px !important;
  transition: right 0.5s ease-in-out !important;
  z-index: 99999999 !important;
}

.toast.show {
  right: 20px !important;
  /* Slide in to the desired position */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


.popup-overlay {
  position: fixed !important;
  top: 0;
  /* Set top to 0 to center vertically */
  left: 0;
  /* Set left to 0 to center horizontally */
  width: 100%;
  /* Take up full width */
  height: 100%;
  /* Take up full height */
  background-color: rgba(0, 0, 0, 0.9) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: opacity 0.3s ease-in-out !important;
  z-index: 999999 !important;
}

.popup-content {
  width: 500px;
  height: 60vh;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  animation: slide-up 0.5s ease-in-out, fade-in 0.5s ease-in-out, scale-in 0.5s ease-in-out;
}

@keyframes slide-up {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scale-in {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}

.open-popup-button {
  background-color: #007bff !important;
  color: white !important;
  padding: 10px 20px !important;
  border: none !important;
  cursor: pointer !important;
}

.close-popup-button {
  background-color: #dc3545 !important;
  color: white !important;
  padding: 10px 20px !important;
  border: none !important;
  cursor: pointer !important;
  margin-top: 10px !important;
}


.photo-carousel-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 10px;
  margin-bottom: 20px;
}

.photo-carousel {
  display: flex;
  /* Ensure the items are laid out in a row */
  gap: 10px;
  padding: 5px;
  cursor: grab;
  scroll-snap-type: x mandatory;
  /* Snap to the items */
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  /* Enable smooth touch scrolling on iOS */
  overflow-x: scroll;
  /* Enable horizontal scroll */
}

.product-item-sub {
  flex: 0 0 100%;
  scroll-snap-align: start;
  margin-right: 10px;
}

.image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.photo-carousel.grabbing {
  cursor: grabbing;
}

.pc {
  background-color: white;
}

@media (min-width: 1366px) {
  .temp {
    width: 96%;
    max-width: 1500px !important;
    overflow-x: hidden !important;
  }

  .arrow-left {
    margin-left: 10% !important;
    /* Add margin for spacing */
  }

  .arrow-right {
    margin-right: 10% !important;
    /* Add margin for spacing */
  }
}

@media (min-width: 1366px) {
  .temp {
    width: 96%;
    max-width: 1500px !important;
    overflow-x: hidden !important;
  }

  .arrow-left {
    margin-left: 90px;
    /* Add margin for spacing */
  }

  .arrow-right {
    margin-right: 90px;
    /* Add margin for spacing */
  }
}

@media (min-width: 1200px) {

  .arrow-left {
    margin-left: 100px;
    /* Add margin for spacing */
  }

  .arrow-right {
    margin-right: 100px;
    /* Add margin for spacing */
  }
}

@media (max-width: 767px) {
  .temp {
    overflow-x: hidden !important;
  }

  .pc-image {
    display: none;
  }

  .mobile-image {
    display: block;
  }
}

/* Media Query for PCs and Larger Devices */
@media (min-width: 768px) {
  .pc-image {
    display: block;
  }

  .mobile-image {
    display: none;
  }

  .photo-carousel-wrapper {
    overflow-x: hidden;
  }

  .photo-carousel {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 5px;
    cursor: grab;
  }

  .product-item-sub {
    width: 100%;
  }
}


@media (max-width: 768px) {
  .photo-carousel-wrapper {
    overflow-x: scroll;
    /* Enable horizontal scroll on mobile */
    -webkit-overflow-scrolling: touch;
    /* Enable smooth touch scrolling on iOS */
    padding: 10px;
    margin-bottom: 20px;
  }

  .photo-carousel {
    display: flex;
    /* Ensure the items are laid out in a row */
    gap: 10px;
    padding: 5px;
    cursor: grab;
    scroll-snap-type: x mandatory;
    /* Snap to the items */
    scroll-behavior: smooth;
    overflow-x: scroll;
    /* Enable horizontal scroll */
  }

  .product-item-sub {
    flex: 0 0 100%;
    scroll-snap-align: start;
    margin-right: 10px;
  }

  .image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .photo-carousel.grabbing {
    cursor: grabbing;
  }
}


/* Additional styles for mobile */
/* @media (max-width: 768px) {
    .photo-carousel-wrapper {
        overflow-x: auto;
        -webkit-overflow-scrolling: auto;
    }

    .photo-carousel {
        display: flex;
        width: 100%;
    }

    .product-item-sub {
        flex: 0 0 1%;
        margin: 0;
    }

    .image {
        width: 100%;
        height: auto;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .photo-carousel.grabbing {
        cursor: grabbing;
    }

} */

.arrow {
  position: absolute;
  top: 74%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 333;
  background-color: black;
  border-radius: 100%;
  font-weight: 800;
  color: white;
}

.left-arrow {
  left: 0;
}

.right-arrow {
  right: 0;
}

.products-container {
  overflow-x: hidden;
  /* Disable horizontal scroll on mobile */
  white-space: nowrap;
  /* Prevent items from wrapping to a new line */
  padding: 10px 0;
  -webkit-overflow-scrolling: touch;
  /* Enable smooth scrolling on iOS devices */
}

.products-inner {
  display: inline-flex;
  /* Display items in a single line */
}

.product-item-sub {
  flex-shrink: 0;
  margin-right: 10px;
}

.product-item {
  flex-shrink: 0;
  width: 180px;
  margin-right: 10px;
}

.product-item-product {
  flex-shrink: 0;
  width: 240px;
  margin-right: 10px;
}

/* Additional styles for mobile */
@media (max-width: 767px) {

  .margin-top {
    margin-top: 10px !important;
  }

  .products-container {
    overflow-x: scroll;
    /* Enable horizontal scroll only for mobile touch */
    -webkit-overflow-scrolling: touch;
    /* Enable smooth scrolling on iOS devices */
    scrollbar-width: none;
    /* Hide scrollbar on mobile */
    -ms-overflow-style: none;
    /* Hide scrollbar on Microsoft Edge */
  }

  .products-container::-webkit-scrollbar {
    /* Hide scrollbar on Chrome and Safari */
    display: none;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.pagination button {
  margin-right: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2e73bb;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 12px;
  transition: background-color 0.3s ease;
}

.pagination button i {
  margin-right: 5px;
}

.pagination button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.pagination button:hover {
  background-color: #666666;
}

.slide-image {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {

  /* Adjust the max-width as needed */
  .slide-image {
    height: 300px;
    /* Set the desired height for mobile */
  }
}

.slide-container {
  position: relative;
}

.middle-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-container {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.sale-image {
  height: 30vh;
  margin-right: 10px;
}

.time-container {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
}

.product-image-category {
  height: 350px;
  object-fit: cover;
}

@keyframes countdownPulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}

.sale-item {
  display: inline-block;
  max-width: 100%;
  margin: 0 auto;
  animation: slideIn 0.5s ease-out;
}

.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  animation: scalePulse 2s infinite;
  transition: transform 0.3s;
}

.image-container:hover {
  animation: none;
  transform: scale(1.05);
}

.sale-image {
  display: block;
  width: 100%;
  height: auto;
  transition: filter 0.3s;
}

.image-container:hover .sale-image {
  filter: brightness(1.2);
}

.countdown-container {
  /* background-color: black; */
  padding: 20px;
  text-align: center;
  width: 100%;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #fff;
  font-size: 16px;
  transition: background-color 0.3s, opacity 0.3s;
  opacity: 0.9;
}

.countdown-label {
  font-weight: bold;
  margin-bottom: 8px;
}

.countdown-timer {
  font-size: 24px;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  animation: countdownPulse 0.5s infinite alternate;
}

.image {
  overflow: hidden;
  position: relative;
}

.image img {
  transition: transform 0.3s ease;
}

.image:hover img {
  transform: scale(1.1);
  /* Adjust the scale factor as needed */
}

@media (max-width: 767px) {
  .product-item {
    margin-right: 5px;
    /* Adjust the spacing between products on smaller screens */
  }
}

.fav:hover {
  cursor: pointer;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.eye:hover {
  cursor: pointer;
}

.product-colors {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.custom-radio {
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}

.product-attributes {
  display: flex;
  flex-wrap: wrap;
}

.custom-control-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio-group {
  display: inline-flex;
  /* Add other styling for the radio group container here */
}

.custom-control-label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

.custom-control-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 20px;
  height: 20px;
  border: 2px solid #aaa;
  border-radius: 50%;
  background-color: #fff;
  transition: all 0.3s ease;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.custom-control-label::after {
  content: "";
  position: absolute;
  left: 7px;
  top: 9px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: white;
  transition: all 0.3s ease;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::after {
  opacity: 1;
  background-color: #007bff;
}

.info-box {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.info-box {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.label-and-input {
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.product-colors {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.btn-primary {
  margin-top: 10px;
  /* Add some space between the radio buttons and the button */
}

.addcart:hover {
  color: white;
  cursor: pointer;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}


.close-button {
  background-color: #ccc;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.lamp {
  position: absolute;
  width: 5px;
  /* Adjust the size of the lamps as needed */
  height: 20px;
  /* Adjust the size of the lamps as needed */
  background-color: yellow;
  /* Set the lamp color as needed */
  border-radius: 50%;
  transform-origin: center bottom;
  transform: rotate(0deg);
  /* Adjust the initial rotation angle */
  top: 0;
  /* Adjust the position to align with the circle border */
  left: 50%;
  /* Center the lamps horizontally */
  margin-left: -2.5px;
  /* Half of the width to center the lamps */
}

.spinner-container {
  position: relative;
  z-index: 10;
}

.chair-base {
  position: absolute;
  bottom: -400px;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: 200px;
  background-color: #A50410;
  /* Adjust the lightness value as needed */
  clip-path: polygon(20% 0, 80% 0, 100% 100%, 0 100%);
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;
}

.chair-base2 {
  position: absolute;
  bottom: -400px;
  left: 50%;
  transform: translateX(-50%);
  width: 64%;
  height: 30px;
  background-color: #870414;
  /* Adjust the lightness value as needed */
  border-top-left-radius: 10%;
  border-top-right-radius: 10%;
}

.wheel-wrapper {
  width: 400px;
  height: 400px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 11;
}

.wheel-pointer {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 80px;
  z-index: 10;
  cursor: pointer;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  border: 6px solid #CAA512;
  color: white;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wheel-pointer::before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url('../../../../public/photos/coin2.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  animation: rotateWheel 3s infinite linear;
}

@keyframes rotateWheel {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

.wheel-pointer::after {
  content: "";
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 30px 20px;
  border-color: transparent transparent #CAA512 transparent;
  z-index: 1;
}

.pointer-inner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #FF4500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.pointer-text {
  font-size: 12px;
  margin-top: 4px;
}


.wheel-base {
  width: 100%;
  height: 100px;
  /* Adjust the height as needed */
  background: #8B4513;
  /* Set the base color to match your design */
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.wheel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.white-border {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 1;
  overflow: hidden;
  top: 0;
  left: 0;
}

/* Additional shapes above the red border */
.triangle {
  position: absolute;
  width: 0;
  height: 0;
  /* border-style: solid; */
  border-width: 10px;
  /* Adjust the size as needed */
  z-index: 2;
}

.shape {
  position: absolute;
  border-radius: 50%;
  z-index: 2;
  /* Set a higher z-index than the red border and white border */
}

.shape-top {
  width: 12px;
  height: 12px;
  background-color: rgb(239, 214, 123);
  top: 22px;
  left: 28%;
}

.shape-left-top {
  width: 12px;
  height: 12px;
  background-color: rgb(239, 214, 123);
  top: 125px;
  left: 4%;
}

.shape-left-bottom {
  width: 12px;
  height: 12px;
  background-color: rgb(239, 214, 123);
  top: 265px;
  left: 4%;
}

.shape-bottom {
  width: 12px;
  height: 12px;
  background-color: rgb(239, 214, 123);
  top: 365px;
  left: 28%;
}

.shape-bottom-right {
  width: 12px;
  height: 12px;
  background-color: rgb(239, 214, 123);
  top: 365px;
  left: 68%;
}

.shape-right-top {
  width: 12px;
  height: 12px;
  background-color: rgb(239, 214, 123);
  top: 20px;
  right: 29%;
}

.shape-right {
  width: 12px;
  height: 12px;
  background-color: rgb(239, 214, 123);
  top: 125px;
  right: 4%;
}

.shape-right-bottom {
  width: 12px;
  height: 12px;
  background-color: rgb(239, 214, 123);
  top: 265px;
  right: 4%;
}

.top {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 17.3px solid white;
  /* Adjust the color and size as needed */
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
}

.left {
  border-top: 15px solid transparent;
  border-right: 18px solid white;
  /* Adjust the color and size as needed */
  border-bottom: 15px solid transparent;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}

.right {
  border-top: 15px solid transparent;
  border-left: 18px solid white;
  border-bottom: 15px solid transparent;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.bottom {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 17.3px solid white;
  /* Adjust the color and size as needed */
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
}

.left-top,
.right-top,
.left-bottom,
.right-bottom {
  border-width: 17.3px;
  /* Adjust the size as needed */
  z-index: 3;
  /* Set a higher z-index for these triangles */
}

.left-top {
  border-top: 25px solid white;
  border-right: 25px solid transparent;
  top: 20%;
  left: 15%;
  transform: translateY(-50%);
}

.right-top {
  border-top: 25px solid white;
  border-left: 25px solid transparent;
  top: 20%;
  right: 15%;
  transform: translateY(-50%);
}


.left-bottom {
  border-bottom: 25px solid white;
  border-right: 25px solid transparent;
  bottom: 20%;
  left: 15%;
  transform: translateY(50%);
}

.right-bottom {
  border-bottom: 25px solid white;
  border-left: 25px solid transparent;
  bottom: 20%;
  right: 15%;
  transform: translateY(50%);
}


.wheel-bg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: linear-gradient(45deg, #b0b0b0, #808080);
  border: 22px solid #E21820;
  transition: transform 4s ease-in-out;
  z-index: 0;
}

.wheel-bg::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 10px solid white;
  box-sizing: border-box;
  z-index: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.circles::before {
  width: 127px !important;
  height: 127px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  z-index: 9;
  border: 1px solid transparent;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Top shape */
.circles::before {
  border-top: 2px solid blue;
  /* Adjust the thickness and color as needed */
  z-index: 9;
}

/* Bottom shape */
.circles::before {
  border-bottom: 2px solid green;
  /* Adjust the thickness and color as needed */
  z-index: 9;

}

/* Left shape */
.circles::before {
  border-left: 2px solid yellow;
  /* Adjust the thickness and color as needed */
  z-index: 9;

}

/* Right shape */
.circles::before {
  border-right: 2px solid purple;
  /* Adjust the thickness and color as needed */
  z-index: 9;

}

/* .wheel-bg::before {
    top: -3px;
    left: 50%;
    transform: translateX(-50%);
} */

.wheel-bg::after {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.dot-1 {
  top: -3px;
  left: 25%;
  transform: translateX(-50%);
}

.dot-2 {
  bottom: -3px;
  left: 75%;
  transform: translateX(-50%);
}

/* Additional Dots */
.dot-3 {
  top: 25%;
  left: -3px;
  transform: translateY(-50%);
}

.dot-4 {
  top: 75%;
  right: -3px;
  transform: translateY(-50%);
}

@keyframes flicker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}


@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.wheel-bg.freeze {
  transition: none;
  background: radial-gradient(circle, #8B4513 0%, #4D2600 100%);
  box-shadow: 0 0 20px rgba(255, 165, 0, 0.7);
}

.prize-list {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
}

.prize-item-wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 200px;
}

.prize-item {
  width: 100%;
  height: 100%;
  background-color: #FF4500;
  /* Background color for the values */
  /* border-radius: 50%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  transform: rotate(0deg);
  transition: transform 4s ease-in-out;
  /* Add a spinning animation */
  position: absolute;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
  /* Create a square shape */
  transform-origin: bottom;
}

.prize-item:nth-child(even) {
  background-color: #FFD700;
  /* Background color for even values */
}


.prize-item .prize-name {
  padding: 16px 0;
  font-size: 8pt !important;
  position: relative;
  writing-mode: vertical-rl;
  /* vertical right-to-left */
  max-width: 80% !important;
}

.triangle-bg {
  width: 0;
  height: 0;
  border-left: 80px solid transparent;
  border-right: 80px solid transparent;
  border-top: 172px solid #8B4513;
  /* Change the color to a wooden texture image or a URL */
  border-bottom: 50px solid transparent;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: -1;
  /* Place it behind the title */
  /* background: url('wood-texture.jpg') center center no-repeat;  */
  background-size: cover;
}

.triangle-bg img {
  margin: 5px;
}

.no-triangle::before {
  content: none;
  /* Remove the triangle */
}


.pyro > .before,
.pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: -249px -330.6666666667px #005eff, 38px -305.6666666667px #ff8400, -46px -347.6666666667px #008cff, 170px -211.6666666667px #1aff00, -77px 0.3333333333px #00ffa6, 224px -316.6666666667px #ff4800, 83px -321.6666666667px blue, -10px -271.6666666667px #ff006a, -95px 74.3333333333px #0048ff, 208px -56.6666666667px #00ffd5, -220px -345.6666666667px #ff00ea, -65px -256.6666666667px #00ffe1, 90px -248.6666666667px #b3ff00, 187px -99.6666666667px #0066ff, -3px -219.6666666667px #4dff00, -235px -392.6666666667px #00ff04, 4px 19.3333333333px #1a00ff, -11px -321.6666666667px #001aff, -121px -369.6666666667px #ff00c8, 44px -177.6666666667px #6aff00, 113px -247.6666666667px #bf00ff, -182px 20.3333333333px #a600ff, 247px -398.6666666667px #e6ff00, -234px 6.3333333333px #ff0095, 25px -42.6666666667px #00ff11, 43px -38.6666666667px #33ff00, -243px -236.6666666667px #006fff, -200px -403.6666666667px #0091ff, 223px -80.6666666667px #0084ff, -230px -133.6666666667px #0088ff, -197px -139.6666666667px #00e1ff, 88px -159.6666666667px deepskyblue, -41px -154.6666666667px #00ff48, 183px -201.6666666667px #8400ff, 150px -352.6666666667px #3700ff, -67px -279.6666666667px #00d9ff, 48px -237.6666666667px #003cff, 178px -389.6666666667px #4800ff, -205px -299.6666666667px #aaff00, -34px -313.6666666667px #a200ff, 101px -380.6666666667px #ff2200, -7px 29.3333333333px #ff0084, -124px -356.6666666667px #1100ff, 27px -393.6666666667px #00ff99, -162px -224.6666666667px #00ff11, 175px -103.6666666667px #bbff00, 142px -114.6666666667px #ff2600, -70px -283.6666666667px #ff0073, 216px -118.6666666667px #f7ff00, -168px -403.6666666667px #0400ff, -16px -152.6666666667px #eaff00;
  }
}

@-moz-keyframes bang {
  to {
    box-shadow: -249px -330.6666666667px #005eff, 38px -305.6666666667px #ff8400, -46px -347.6666666667px #008cff, 170px -211.6666666667px #1aff00, -77px 0.3333333333px #00ffa6, 224px -316.6666666667px #ff4800, 83px -321.6666666667px blue, -10px -271.6666666667px #ff006a, -95px 74.3333333333px #0048ff, 208px -56.6666666667px #00ffd5, -220px -345.6666666667px #ff00ea, -65px -256.6666666667px #00ffe1, 90px -248.6666666667px #b3ff00, 187px -99.6666666667px #0066ff, -3px -219.6666666667px #4dff00, -235px -392.6666666667px #00ff04, 4px 19.3333333333px #1a00ff, -11px -321.6666666667px #001aff, -121px -369.6666666667px #ff00c8, 44px -177.6666666667px #6aff00, 113px -247.6666666667px #bf00ff, -182px 20.3333333333px #a600ff, 247px -398.6666666667px #e6ff00, -234px 6.3333333333px #ff0095, 25px -42.6666666667px #00ff11, 43px -38.6666666667px #33ff00, -243px -236.6666666667px #006fff, -200px -403.6666666667px #0091ff, 223px -80.6666666667px #0084ff, -230px -133.6666666667px #0088ff, -197px -139.6666666667px #00e1ff, 88px -159.6666666667px deepskyblue, -41px -154.6666666667px #00ff48, 183px -201.6666666667px #8400ff, 150px -352.6666666667px #3700ff, -67px -279.6666666667px #00d9ff, 48px -237.6666666667px #003cff, 178px -389.6666666667px #4800ff, -205px -299.6666666667px #aaff00, -34px -313.6666666667px #a200ff, 101px -380.6666666667px #ff2200, -7px 29.3333333333px #ff0084, -124px -356.6666666667px #1100ff, 27px -393.6666666667px #00ff99, -162px -224.6666666667px #00ff11, 175px -103.6666666667px #bbff00, 142px -114.6666666667px #ff2600, -70px -283.6666666667px #ff0073, 216px -118.6666666667px #f7ff00, -168px -403.6666666667px #0400ff, -16px -152.6666666667px #eaff00;
  }
}

@-o-keyframes bang {
  to {
    box-shadow: -249px -330.6666666667px #005eff, 38px -305.6666666667px #ff8400, -46px -347.6666666667px #008cff, 170px -211.6666666667px #1aff00, -77px 0.3333333333px #00ffa6, 224px -316.6666666667px #ff4800, 83px -321.6666666667px blue, -10px -271.6666666667px #ff006a, -95px 74.3333333333px #0048ff, 208px -56.6666666667px #00ffd5, -220px -345.6666666667px #ff00ea, -65px -256.6666666667px #00ffe1, 90px -248.6666666667px #b3ff00, 187px -99.6666666667px #0066ff, -3px -219.6666666667px #4dff00, -235px -392.6666666667px #00ff04, 4px 19.3333333333px #1a00ff, -11px -321.6666666667px #001aff, -121px -369.6666666667px #ff00c8, 44px -177.6666666667px #6aff00, 113px -247.6666666667px #bf00ff, -182px 20.3333333333px #a600ff, 247px -398.6666666667px #e6ff00, -234px 6.3333333333px #ff0095, 25px -42.6666666667px #00ff11, 43px -38.6666666667px #33ff00, -243px -236.6666666667px #006fff, -200px -403.6666666667px #0091ff, 223px -80.6666666667px #0084ff, -230px -133.6666666667px #0088ff, -197px -139.6666666667px #00e1ff, 88px -159.6666666667px deepskyblue, -41px -154.6666666667px #00ff48, 183px -201.6666666667px #8400ff, 150px -352.6666666667px #3700ff, -67px -279.6666666667px #00d9ff, 48px -237.6666666667px #003cff, 178px -389.6666666667px #4800ff, -205px -299.6666666667px #aaff00, -34px -313.6666666667px #a200ff, 101px -380.6666666667px #ff2200, -7px 29.3333333333px #ff0084, -124px -356.6666666667px #1100ff, 27px -393.6666666667px #00ff99, -162px -224.6666666667px #00ff11, 175px -103.6666666667px #bbff00, 142px -114.6666666667px #ff2600, -70px -283.6666666667px #ff0073, 216px -118.6666666667px #f7ff00, -168px -403.6666666667px #0400ff, -16px -152.6666666667px #eaff00;
  }
}

@-ms-keyframes bang {
  to {
    box-shadow: -249px -330.6666666667px #005eff, 38px -305.6666666667px #ff8400, -46px -347.6666666667px #008cff, 170px -211.6666666667px #1aff00, -77px 0.3333333333px #00ffa6, 224px -316.6666666667px #ff4800, 83px -321.6666666667px blue, -10px -271.6666666667px #ff006a, -95px 74.3333333333px #0048ff, 208px -56.6666666667px #00ffd5, -220px -345.6666666667px #ff00ea, -65px -256.6666666667px #00ffe1, 90px -248.6666666667px #b3ff00, 187px -99.6666666667px #0066ff, -3px -219.6666666667px #4dff00, -235px -392.6666666667px #00ff04, 4px 19.3333333333px #1a00ff, -11px -321.6666666667px #001aff, -121px -369.6666666667px #ff00c8, 44px -177.6666666667px #6aff00, 113px -247.6666666667px #bf00ff, -182px 20.3333333333px #a600ff, 247px -398.6666666667px #e6ff00, -234px 6.3333333333px #ff0095, 25px -42.6666666667px #00ff11, 43px -38.6666666667px #33ff00, -243px -236.6666666667px #006fff, -200px -403.6666666667px #0091ff, 223px -80.6666666667px #0084ff, -230px -133.6666666667px #0088ff, -197px -139.6666666667px #00e1ff, 88px -159.6666666667px deepskyblue, -41px -154.6666666667px #00ff48, 183px -201.6666666667px #8400ff, 150px -352.6666666667px #3700ff, -67px -279.6666666667px #00d9ff, 48px -237.6666666667px #003cff, 178px -389.6666666667px #4800ff, -205px -299.6666666667px #aaff00, -34px -313.6666666667px #a200ff, 101px -380.6666666667px #ff2200, -7px 29.3333333333px #ff0084, -124px -356.6666666667px #1100ff, 27px -393.6666666667px #00ff99, -162px -224.6666666667px #00ff11, 175px -103.6666666667px #bbff00, 142px -114.6666666667px #ff2600, -70px -283.6666666667px #ff0073, 216px -118.6666666667px #f7ff00, -168px -403.6666666667px #0400ff, -16px -152.6666666667px #eaff00;
  }
}

@keyframes bang {
  to {
    box-shadow: -249px -330.6666666667px #005eff, 38px -305.6666666667px #ff8400, -46px -347.6666666667px #008cff, 170px -211.6666666667px #1aff00, -77px 0.3333333333px #00ffa6, 224px -316.6666666667px #ff4800, 83px -321.6666666667px blue, -10px -271.6666666667px #ff006a, -95px 74.3333333333px #0048ff, 208px -56.6666666667px #00ffd5, -220px -345.6666666667px #ff00ea, -65px -256.6666666667px #00ffe1, 90px -248.6666666667px #b3ff00, 187px -99.6666666667px #0066ff, -3px -219.6666666667px #4dff00, -235px -392.6666666667px #00ff04, 4px 19.3333333333px #1a00ff, -11px -321.6666666667px #001aff, -121px -369.6666666667px #ff00c8, 44px -177.6666666667px #6aff00, 113px -247.6666666667px #bf00ff, -182px 20.3333333333px #a600ff, 247px -398.6666666667px #e6ff00, -234px 6.3333333333px #ff0095, 25px -42.6666666667px #00ff11, 43px -38.6666666667px #33ff00, -243px -236.6666666667px #006fff, -200px -403.6666666667px #0091ff, 223px -80.6666666667px #0084ff, -230px -133.6666666667px #0088ff, -197px -139.6666666667px #00e1ff, 88px -159.6666666667px deepskyblue, -41px -154.6666666667px #00ff48, 183px -201.6666666667px #8400ff, 150px -352.6666666667px #3700ff, -67px -279.6666666667px #00d9ff, 48px -237.6666666667px #003cff, 178px -389.6666666667px #4800ff, -205px -299.6666666667px #aaff00, -34px -313.6666666667px #a200ff, 101px -380.6666666667px #ff2200, -7px 29.3333333333px #ff0084, -124px -356.6666666667px #1100ff, 27px -393.6666666667px #00ff99, -162px -224.6666666667px #00ff11, 175px -103.6666666667px #bbff00, 142px -114.6666666667px #ff2600, -70px -283.6666666667px #ff0073, 216px -118.6666666667px #f7ff00, -168px -403.6666666667px #0400ff, -16px -152.6666666667px #eaff00;
  }
}

@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-webkit-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-moz-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-o-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-ms-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

.message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.message {
  text-align: center;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.spinner-input {
  width: 100%;
  margin-top: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.copy-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #0056b3;
}

[v-cloak] {
  display: none !important;
}

/*
.category-subcategory {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.category {
    width: 50%;
}

.subcategories {
    width: 50%;
}

.subcategory-row {
    display: flex;
    flex-wrap: wrap;
}

.subcategory-item {
    flex-basis: calc(50% - 10px);
    margin: 5px;
}

.third-photo {
    flex-basis: 100%;
}




a {
    text-decoration: none;
}

.product-card {
    width: 240px;
    position: relative;
    margin-right: 20px;
    background: #fafafa;
}

.badge {
    position: absolute;
    left: 0;
    top: 20px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    background: red;
    color: #fff;
    padding: 3px 10px;
}

.product-tumb {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    padding: 50px;
    background: #f0f0f0;
}

.product-tumb img {
    max-width: auto;
    max-height: auto;
}

.product-details {
    padding: 30px;
    max-height: 200px;
    overflow: hidden;
    white-space: nowrap;
}

.product-catagory {
    display: block;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ccc;
    margin-bottom: 18px;
}

.product-details h4 a {
    display: block;
    margin-bottom: 18px;
    text-transform: uppercase;
    color: #363636;
    text-decoration: none;
    transition: 0.3s;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

.product-details h4 a:hover {
    color: #fbb72c;
    text-overflow: clip;
}

.product-details p {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 18px;
    color: #999;
}

.product-bottom-details {
    overflow: hidden;
    border-top: 1px solid #eee;
    padding-top: 20px;
}

.product-bottom-details div {
    float: left;
    width: 50%;
}

.product-price {
    font-size: 18px;
    color: black;
    font-weight: 600;
}

.product-price small {
    font-size: 80%;
    font-weight: 400;
    text-decoration: line-through;
    display: inline-block;
    margin-right: 5px;
}

.product-links {
    text-align: right;
}

.product-links a {
    display: inline-block;
    margin-left: 5px;
    color: #e1e1e1;
    transition: 0.3s;
    font-size: 17px;
}

.product-links a:hover {
    color: #fbb72c;
}

.product-container {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    white-space: nowrap;
    cursor: grab;
}

*/
</style>