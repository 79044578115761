<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <div class="toast" :class="{ 'show': showSuccessMessage, 'error': isError }">
            {{ message }}
          </div>

          <div class="col-6">
            <label for="brand-select">Select User:</label>
            <select class="form-control w-100" v-model="cart.user_id"
                    :class="{ 'is-invalid': !isUserSelected }" required>
              <option value="" hidden selected>Users / Sub Affiliate</option>
              <option v-for="user in users" :key="user.id" :value="user.user_id">{{
                  user.user.first_name
                }} {{ user.user.last_name }}
              </option>
            </select>
            <div class="invalid-feedback" v-if="!isUserSelected">Please select a user.</div>
          </div>

          <div class="bundle-container">
            <img :src="'https://api.gocami.com/bundle_image/' + bundleImages.bundle_image"
                 style="height:40vh"/>
            <div class="quantity-group mb-5 mt-3">
              <label>Quantity:</label>
              <div class="quantity-controls">
                <input type="number" id="allQty" v-model="selectedQuantity" :min="1"
                       class="form-control w-25" :max="this.maxQty"/>
              </div>
            </div>

            <form @submit.prevent="save" class="bundle-form">
              <div v-for="bundleview in bundleviews" :key="bundleview.id">
                <span class="mb-2">{{ bundleview.product_name }}</span>
                <select v-model="selectedProduct[bundleview.id]" class="form-select mb-2"
                        @change="updateOverallQuantity(bundleview.id, selectedProduct[bundleview.id], confs[bundleview.id])"
                        required>
                  <option v-for="conf in confs[bundleview.id]" :key="conf.id" :value="conf.id"
                          :disabled="isProductDisabled(conf)">
                    {{ conf.sku }} - {{ conf.title }} - {{ conf.attribute_type_name }}
                  </option>

                  <div class="configurations hide" v-if="selectedProduct[bundleview.id]">
                    <!-- Individual Quantity Control -->
                    <div class="quantity-group">
                      <label>Quantity:</label>
                      <div class="quantity-controls">
                        <input type="number" class="form-control"
                               :id="`quantity_${selectedProduct[bundleview.id].id}`"
                               v-model="quantities[selectedProduct[bundleview.id].id]"
                               :max="getMaxQuantity(bundleview.id)"/>
                      </div>
                    </div>
                  </div>
                </select>
              </div>

              <button :disabled="!areAllSelected()" type="submit"
                      class="btn btn-primary submit-button">Submit
              </button>


            </form>
          </div>


        </div>

      </div>
    </div>
  </div>
</template>


<script>
import axiosInstance from '../../../../../axiosInstance';
import {useUserStore} from "../../../../../store";

export default {
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },

  data() {
    return {
      bundleImages: [],
      bundleviews: [],
      products: [],
      confs: [],
      minQty: -1,
      maxQty: 0,
      selectedProduct: {}, // Store the selected product_detail_id for each product
      selectedConfs: {}, // Store the configurations for each selected product
      quantities: {}, // New property to store quantities for each selected product
      cart: {
        quantity: 1,
        user_id: ''
      },
      selectedProductNames: [],
      message: '',
      isError: false,
      showSuccessMessage: false,
      users: [],
      selectedQuantity: 1,
    };
  },

  computed: {

    // areAllSelected() {
    //     return this.bundleviews.every(bundleview => this.selectedProduct[bundleview.id] !== undefined);
    // },

    isUserSelected() {
      return !!this.cart.user_id;
    },
  },

  created() {
    this.dataLoad();
  },

  mounted() {
    // Call getMaxQuantityForAllProducts when the component is mounted
    this.getMaxQuantityForAllProducts();
  },

  methods: {

    areAllSelected() {
      const allSelected = Object.values(this.selectedProduct).every(product => {
        console.log(`Product ID: ${product.id}, Selected: ${!!product}`);
        return !!product;
      });

      return allSelected;
    },

    getMinSelectedProductQuantity() {
      const selectedQuantities = Object.values(this.selectedProduct).map(
          (product) => product?.quantity || 0
      );
      return Math.max(1, Math.min(...selectedQuantities));
    },
    decreaseOverallQuantity() {
      this.selectedQuantity = Math.max(1, this.selectedQuantity - 1);
    },
    increaseOverallQuantity() {
      this.selectedQuantity++;
    },
    updateOverallQuantity(bundleid, confId, confs) {

      const foundIndex = confs.findIndex(obj => obj.id === confId);
      if (foundIndex !== -1) {
        // Access the 'quantity' property of the found object
        const quantity = confs[foundIndex].quantity;
        if (quantity != 0 && this.maxQty == 0) {
          this.maxQty = quantity
        } else if (quantity != 0 && quantity < this.maxQty) {
          this.maxQty = quantity
        } else if (quantity == 0) {
          this.maxQty = 0
        }

        console.log(`Quantity for id ${confId}: ${quantity}: ${this.maxQty}`);
      } else {
        // Handle the case where the object was not found
        this.maxQty = 0;
      }


      this.selectedQuantity = this.getMinSelectedProductQuantity();
    },


    updateQuantity(selectedProductId) {
      const selectedProduct = this.selectedProduct[selectedProductId];

      if (selectedProduct) {
        const newQuantity = this.quantities[selectedProduct];
        const maxQuantity = this.getMaxQuantity(selectedProduct);

        // Ensure the new quantity is within the valid range
        const parsedQuantity = parseInt(newQuantity, 10);
        if (!isNaN(parsedQuantity) && parsedQuantity >= 1 && parsedQuantity <= maxQuantity) {
          this.quantities[selectedProduct] = parsedQuantity;
        }
      }
    },
    getQuantity(selectedProduct) {
      // Check if the quantity is already set for the selected product
      if (this.quantities[selectedProduct] !== undefined) {
        return this.quantities[selectedProduct];
      } else {
        // If the quantity is not set, initialize it with the quantity from the database or 1 if not available
        const selectedConfsForProduct = this.selectedConfs[selectedProduct];
        if (selectedConfsForProduct && selectedConfsForProduct.quantity >= 0) {
          this.quantities[selectedProduct] = selectedConfsForProduct.quantity;
        } else {
          this.quantities[selectedProduct] = 0;
        }
        return this.quantities[selectedProduct];
      }
    },

    getMaxQuantityForAllProducts() {
      // Loop through all products and set the max quantity
      for (const bundleview of this.bundleviews) {
        const selectedProduct = this.selectedProduct[bundleview.id];
        if (selectedProduct) {
          const maxQuantity = this.getMaxQuantity(selectedProduct);
          // Update the selected product's quantity in the quantities object
          this.$set(this.quantities, selectedProduct, maxQuantity);
        }
      }
    },

    getMaxQuantity(bundleId) {
      // Calculate the maximum quantity for the selected product
      console.log("tes2t" + bundleId);
      const selectedProductId = this.selectedProduct[bundleId];
      console.log("test" + selectedProductId);
      return selectedProductId ? this.quantities[selectedProductId] || 0 : 1;
    },


    async dataLoad() {
      try {
        var page = `api/get-affiliate-bundle-view/${this.$route.params.id}?user_id=${this.user?.id}`;
        const {data} = await axiosInstance.get(page);

        this.bundleImages = data.bundleImage;
        this.bundleviews = data.bundleview;
        this.products = data.product;
        this.confs = data.conf;
        this.users = data.user;
        this.processConfData(data.conf);

        // Ensure that data is loaded before calling getMaxQuantityForAllProducts
        this.getMaxQuantityForAllProducts();
      } catch (error) {
        console.error('Error loading data:', error);
      }
    },

    processConfData(confData) {
      this.confs = confData.reduce((acc, conf) => {
        if (!acc[conf.bundle_id]) {
          acc[conf.bundle_id] = [];
        }
        acc[conf.bundle_id].push(conf);
        return acc;
      }, {});
    },
    save() {
      this.saveData();
    },
    saveData() {
      for (const bundleview of this.bundleviews) {
        this.saveBundleData(bundleview);
      }
    },

    isProductDisabled(productConfig) {
      // Assuming 'productConfig' is the product configuration data for the selected product
      return productConfig && productConfig.quantity === 0;
    },


    saveBundleData(bundleview) {
      const selectedProduct = this.selectedProduct[bundleview.id];
      const selectedProductDetail = this.confs[bundleview.id]?.find(
          (conf) => conf.id === selectedProduct
      );

      if (selectedProductDetail) {
        const bundle_id = bundleview.bundle_id; // Make sure this is the correct main bundle_id
        const quantity = this.selectedQuantity; // Use the overall quantity

        // Calculate the sale price with the applied bundle discount
        const salePrice = selectedProductDetail.sale_price;
        const bundleDiscount = selectedProductDetail.bundle_discount;
        const discountedPrice = salePrice - (salePrice * bundleDiscount / 100);
        console.log("Discounted Price:", discountedPrice); // Add this line to log discounted price

        const formData = new FormData();
        formData.append("quantity", quantity);
        formData.append("brand_id", selectedProductDetail.brand_id);
        formData.append("bundle_id", bundle_id); // Send the main bundle_id
        formData.append("product_detail_id", selectedProduct);
        formData.append("sale_price", discountedPrice); // Send the discounted price
        formData.append("user_id", this.cart.user_id);
        this.makeApiCall(formData);
      }
    },

    makeApiCall(formData) {
      axiosInstance
          .post(`api/insert-affiliate-bundle`, formData)
          .then((response) => {
            if (response.status === 200) {
              this.showSuccessMessage = true;
              this.message = "Product has been added to cart successfully";
              this.isError = false;
              this.hideMessageAfterDelay(5000);

              // Delay the redirect to ensure the success message is displayed
              setTimeout(() => {
                this.$router.push({path: '/affiliate-bundles'});
              }, 1000);
            } else {
              this.handleErrorMessage("Failed to add to cart");
            }
          })
          .catch((error) => {
            this.handleErrorMessage("Failed to add to cart", error);
          });
    },

    hideMessageAfterDelay(delay) {
      setTimeout(() => {
        this.showSuccessMessage = false; // Hide the toast message after the specified delay
      }, delay);
    },

  },
}
</script>

<style scoped>
.submit-button {
  margin-top: 20px;
}


.toast {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #28a745;
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.error-message {
  color: red;
  font-size: 12px;
}

.toast.error {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #dc3545;
  /* Background color for error */
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast.show {
  right: 20px;
  /* Slide in to the desired position */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>