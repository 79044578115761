<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h3 class="mb-5">Product Refund Panel</h3>

          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Select</th> <!-- Checkbox column -->
              <th>User Name</th>
              <th>Location</th>
              <th>Address</th>
              <th>Phone</th>
              <th>Quantity (Order)</th>
              <th>Quantity (After Refund)</th>
              <th>Refund Quantity</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="invoice in refund" :key="invoice.id">
              <th scope="row">{{ invoice.id }}</th>
              <td>
                <!-- Styled checkbox -->
                <label class="custom-checkbox">
                  <input type="checkbox" v-model="invoice.selected" />
                  <span class="checkmark"></span>
                </label>
              </td>
              <td><img :src="'https://api.loop-delivery.com/product_detail/' + invoice.image" /></td>
              <td>{{ invoice.sku }}</td>
              <td>{{ invoice.title }}</td>
              <td>{{ invoice.phone }}</td>
              <td>{{ invoice.quantity }}</td>
              <td>{{ invoice.refund_quantity }}</td>
              <td>
                <input type="number" v-model="invoice.quantity" :max="invoice.refund_quantity" :min="0"
                       class="form-control h-25" />
              </td>
            </tr>
            </tbody>
          </table>
          <button @click="submitRefunds" class="btn btn-primary mt-4">Submit Refunds</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
  name: "RefundView",

  data() {
    return {
      refund: [], // Each refund object will include a 'selected' property for the checkbox
    };
  },

  created() {
    this.productSaleLoad();
  },

  methods: {
    productSaleLoad() {
      const page = `api/refund-product/${this.$route.params.id}`;
      axiosInstance.get(page).then(({ data }) => {
        // Initialize 'selected' property to false for each invoice
        this.refund = data.map(invoice => ({
          ...invoice,
          selected: false
        }));
      });
    },

    updateRefundProduct(invoice) {
      const url = `api/update-refund-product/${invoice.id}`;
      const data = {
        quantity: invoice.quantity,
        product_detail_id: invoice.product_detail_id
      };

      console.log('Updating refund with data:', data);

      axiosInstance
          .put(url, data)
          .then(() => {
            console.log('Refund updated successfully');
          })
          .catch(error => {
            console.error('Failed to update refund:', error);
          });
    },

    submitRefunds() {
      // Only update selected invoices
      this.refund.forEach(invoice => {
        if (invoice.selected) {
          this.updateRefundProduct(invoice);
        }
      });
    }
  },
};
</script>


<style scoped>
  /* Basic table styling for clarity */
.table th, .table td {
  vertical-align: middle;
  text-align: center;
}

/* Custom checkbox styling */
.custom-checkbox {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.custom-checkbox input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #eee;
  border-radius: 4px;
}

.custom-checkbox input:checked + .checkmark {
  background-color: #4caf50; /* Green when checked */
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked + .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
</style>

