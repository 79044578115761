<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card shadow-sm">
        <div class="card-body">
          <h3 class="mb-5 text-primary">Orders Management Panel</h3>

          <!-- Success or Error Message -->
          <div v-if="message" :class="{'alert alert-success': isSuccess, 'alert alert-danger': !isSuccess}">
            {{ message }}
          </div>

          <div class="mb-3">
            <label for="deliverySelect">Select Delivery:</label>
            <select id="deliverySelect" class="form-control" v-model="filters.delivery_id" @change="applySearchFilter">
              <option value="">All Deliveries</option>
              <option v-for="delivery in delivery" :value="delivery.id" :key="delivery.id">{{
                  delivery.delivery_name
                }}
              </option>
            </select>
          </div>

          <div class="search-section mb-4">
            <label for="search" class="form-label">Search by Name or Phone:</label>
            <div class="input-group mb-3">
              <input
                  type="text"
                  v-model="filters.search"
                  id="search"
                  class="form-control"
                  placeholder="Enter name or phone"
              />
            </div>

            <div class="row">
              <div class="col-6">
                <label for="fromDate" class="form-label">From Date:</label>
                <div class="input-group mb-3">
                  <input
                      type="date"
                      v-model="filters.from_date"
                      id="fromDate"
                      class="form-control"
                  />
                </div>
              </div>
              <div class="col-6"><label for="toDate" class="form-label">To Date:</label>
                <div class="input-group mb-3">
                  <input
                      type="date"
                      v-model="filters.to_date"
                      id="toDate"
                      class="form-control"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6"><label for="fromPaymentDate" class="form-label">From Payment Date:</label>
                <div class="input-group mb-3">
                  <input
                      type="date"
                      v-model="filters.from_payment_date"
                      id="fromPaymentDate"
                      class="form-control"
                  />
                </div>
              </div>
              <div class="col-6"><label for="toPaymentDate" class="form-label">To Payment Date:</label>
                <div class="input-group mb-3">
                  <input
                      type="date"
                      v-model="filters.to_payment_date"
                      id="toPaymentDate"
                      class="form-control"
                  />
                </div>
              </div>
            </div>


            <button @click="applySearchFilter" class="btn btn-primary" :disabled="!isSearchValid">
              Apply Filter
            </button>

            <button @click="printFilteredData" class="btn btn-secondary ml-3">Print</button>

            <button @click="exportToCSV" class="btn btn-success ml-3">
              Export as CSV
            </button>

          </div>

          <!-- Orders Table -->
          <div v-if="filteredOrders.length > 0">
            <table class="table table-striped table-bordered">
              <thead class="table-dark">
              <tr>
                <th>#</th>
                <th>User Name</th>
                <th>Phone</th>
                <th>Created at</th>
                <th>Address</th>
                <th>Location</th>
                <th>Barcode</th>
                <th>Total</th>
                <th>Aramex Total</th>
                <th>Delivery</th>
                <th>مرتجع</th>
                <th>Payment Date</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="order in filteredOrders" :key="order.id">
                <td>{{ order.id }}</td>
                <td>{{ order.first_name }} {{ order.middle_name }} {{ order.last_name }}</td>
                <td>{{ order.phone }}</td>
                <td>{{ order.created_at }}</td>
                <td>{{ order.address }}</td>
                <td>{{ order.governorates_name }} - {{ order.city_name }} - {{ order.area_name }}</td>
                <td v-if="order.invoice_barcode">{{ order.invoice_barcode }}</td>
                <td v-else>-</td>
                <td>{{ order.total.toFixed(2) }}</td>
                <td>{{ order.total_receivable.toFixed(2) }}</td>
                <td>{{ order.delivery_price }}</td>
                <td v-if="order.status == 10">مرتجع</td>
                <td v-else>-</td>
                <td>{{ order.payment_date }}</td>
              </tr>
              </tbody>
            </table>

            <!-- Display Aramex Total Sum -->
            <div class="mt-3">
              <strong>Total Aramex Amount:</strong> ${{ aramexTotalSum }}
            </div>
          </div>

          <!-- Message when no orders are available -->
          <div v-else-if="allOrder.length === 0 && hasFiltered" class="alert alert-warning text-center">
            No orders found for the provided search criteria.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axiosInstance from '../../../axiosInstance';

export default {
  name: 'OrderTracking',

  data() {
    return {
      delivery: [],
      allOrder: [],
      filters: {
        search: '',
        from_date: '',
        to_date: '',
        from_payment_date: '',  // New field for from payment date
        to_payment_date: '',    // New field for to payment date
        delivery_id: '',
      },
      message: '',
      isSuccess: true,
      hasFiltered: false,
    };
  },

  computed: {
    // Filter orders based on search, from_date, and to_date
    filteredOrders() {
      let filtered = this.allOrder;

      if (this.filters.search.trim()) {
        filtered = filtered.filter(order =>
            order.phone.includes(this.filters.search) ||
            `${order.first_name} ${order.middle_name} ${order.last_name}`.toLowerCase().includes(this.filters.search.toLowerCase())
        );
      }

      if (this.filters.from_date) {
        filtered = filtered.filter(order =>
            new Date(order.created_at) >= new Date(this.filters.from_date)
        );
      }

      if (this.filters.to_date) {
        filtered = filtered.filter(order =>
            new Date(order.created_at) <= new Date(this.filters.to_date)
        );
      }

      return filtered;
    },

    // Compute the total of the Aramex Total (total_receivable) for the filtered orders
    aramexTotalSum() {
      return this.filteredOrders.reduce((sum, order) => {
        return sum + parseFloat(order.total_receivable || 0);
      }, 0).toFixed(2);  // Fix to 2 decimal places
    },

    // Ensure that at least one filter is valid before enabling search
    isSearchValid() {
      return this.filters.search || this.filters.from_date || this.filters.to_date || this.filters.delivery_id;
    },
  },

  methods: {
    // Load all orders initially
    orderLoad() {
      if (!this.selectedDelivery) return; // Prevent loading if no delivery is selected

      axiosInstance.get('api/order-export-management-completed', {
        params: {
          delivery_id: this.selectedDelivery, // Pass the selected delivery ID to the API
        }
      })
          .then(({data}) => {
            this.allOrder = data[0]; // Assuming the invoices are the first item in the response array
            this.delivery = data[1]; // Assuming deliveries are the second item
          })
          .catch(error => {
            console.error('Error loading orders:', error);
          });
    },

    // Apply search filter
    applySearchFilter() {
      axiosInstance.get('api/order-export-management-completed', {
        params: {
          search: this.filters.search,
          from_date: this.filters.from_date,
          to_date: this.filters.to_date,
          from_payment_date: this.filters.from_payment_date,  // Send the payment date filter
          to_payment_date: this.filters.to_payment_date,      // Send the payment date filter
          delivery_id: this.filters.delivery_id,
        }
      })
          .then(({data}) => {
            this.allOrder = data[0];  // Invoices data
            this.delivery = data[1];  // Deliveries data
          })
          .catch(error => {
            console.error('Error fetching orders:', error);
          });
    }
    ,

    getDeliveryName(deliveryId) {
      const delivery = this.delivery.find(d => d.id === deliveryId);
      return delivery ? delivery.delivery_name : 'Unknown';
    },

    // Print filtered data
    printFilteredData() {
      const printWindow = window.open('', '_blank');
      let htmlContent = `
        <h3>Filtered Orders</h3>
        <p><strong>Delivery Name:</strong> ${this.getDeliveryName(this.filters.delivery_id)}</p>
        <p><strong>Date Range:</strong> ${this.filters.from_date} to ${this.filters.to_date}</p>
        <table border="1" cellpadding="10" cellspacing="0">
          <thead>
            <tr>
              <th>#</th>
              <th>User Name</th>
              <th>Phone</th>
              <th>Created at</th>
              <th>Total</th>
              <th>Aramex Total</th>
              <th>Payment Date</th>
            </tr>
          </thead>
          <tbody>
      `;

      this.filteredOrders.forEach(order => {
        htmlContent += `
          <tr>
            <td>${order.id}</td>
            <td>${order.first_name} ${order.middle_name} ${order.last_name}</td>
            <td>${order.phone}</td>
            <td>${order.created_at}</td>
            <td>${order.total.toFixed(2)}</td>
            <td>${order.total_receivable.toFixed(2)}</td>
            <td>${order.payment_date}}</td>
          </tr>
        `;
      });

      htmlContent += `
          </tbody>
        </table>
        <p><strong>Total Amount:</strong> $${this.aramexTotalSum}</p>
      `;

      printWindow.document.write(htmlContent);
      printWindow.document.close();
      printWindow.print();
    },

    // Export filtered orders to CSV
    exportToCSV() {
      const headers = [
        'ID', 'User Name', 'Phone', 'Created At', 'Address', 'Location', 'Total', 'Aramex Total', 'Delivery Price', 'مرتجع', 'payment_date'
      ];

      const escapeField = (field) => {
        if (field === null || field === undefined) return '';
        return `"${String(field).replace(/"/g, '""')}"`;
      };

      const rows = this.filteredOrders.map(order => [
        escapeField(order.id),
        escapeField(`${order.first_name} ${order.middle_name} ${order.last_name}`),
        escapeField(order.phone),
        escapeField(order.created_at),
        escapeField(order.address),
        escapeField(`${order.governorates_name} - ${order.city_name} - ${order.area_name}`),
        escapeField(order.total),
        escapeField(order.total_receivable),
        escapeField(order.delivery_price),
        escapeField(order.status === 10 ? 'مرتجع' : ''),
        escapeField(order.payment_date),

      ]);

      const csvContent = [
        headers.map(escapeField).join(','),
        ...rows.map(row => row.join(','))
      ].join('\n');

      const bom = '\uFEFF'; // BOM character
      const blob = new Blob([bom + csvContent], {type: 'text/csv;charset=utf-8;'});

      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'orders.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  },

  mounted() {
    // Load deliveries initially when the component is mounted
    axiosInstance.get('api/order-export-management-completed')
        .then(({data}) => {
          this.delivery = data[1];  // Populate the delivery dropdown
        })
        .catch(error => {
          console.error('Error fetching deliveries:', error);
        });
  },

  created() {
    this.orderLoad(); // Load orders when the component is created
    this.applySearchFilter();
  }
};
</script>


<style scoped>
/* General Panel Styling */
.main-panel {
  background-color: #f8f9fa;
  padding: 20px;
}

.card {
  border-radius: 10px;
}

.card-body {
  padding: 20px;
}

h3 {
  font-weight: bold;
}

/* Search Section Styling */
.search-section {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-group {
  display: flex;
}

.input-group .form-control {
  flex: 1;
}

.input-group button {
  margin-left: 10px;
}

/* Table Styling */
.table {
  margin-top: 20px;
  border-collapse: collapse;
  width: 100%;
}

.table th, .table td {
  padding: 15px;
  text-align: left;
}

.table-bordered th, .table-bordered td {
  border: 1px solid #ddd;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-dark {
  background-color: #343a40;
  color: white;
}

.alert {
  margin-top: 20px;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .table {
    font-size: 12px;
  }
}
</style>
