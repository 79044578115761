<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Orders Panel</h3>
          <table class="responsive table-responsive table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>Product Sku</th>
              <th>Quantity</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="order in carts" :key="order.id">
              <th scope="row">{{ order.id }}</th>
              <th scope="row">{{ order.title }}</th>
              <td>{{ order.sku }}</td>
              <td><input v-model="order.quantity" type="number"/></td> <!-- Allow quantity editing -->
              <td style="display: flex;">
                <button @click="updateCart(order)" class="btn btn-primary">Update</button>
                <button @click="deleteCart(order)" class="btn btn-danger">Delete</button> <!-- Delete button -->
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
  name: "AllOrder",

  data() {
    return {
      carts: [],
    };
  },

  created() {
    this.orderLoad();
  },

  methods: {
    orderLoad() {
      axiosInstance.get(`api/get-all-products-carts/${this.$route.params.id}`)
          .then(({data}) => {
            this.carts = data;
          })
          .catch((error) => {
            console.error("API call failed:", error);
          });
    },

    updateCart(order) {
      // API call to update cart and insert refund
      axiosInstance.put(`api/cart/${order.id}`, {
        quantity: order.quantity,
        note: 'Updated quantity',
      })
          .then(response => {
            console.log(response.data);
            this.orderLoad();  // Reload the data
          })
          .catch(error => {
            console.error("Update failed:", error);
          });
    },

    deleteCart(order) {
      // Make sure cart_id, product_detail_id, and invoice_id are passed in the request body
      axiosInstance.delete(`api/cart/${order.id}`, {
        data: {
          cart_id: order.id,  // Pass the cart_id properly
          product_detail_id: order.product_detail_id,  // Pass the product_detail_id
          invoice_id: order.invoice_id,  // Pass the invoice_id
          quantity: order.quantity,  // Send the quantity in the body
          note: 'Cart deleted',
        }
      })
          .then(response => {
            console.log(response.data);
            this.orderLoad();  // Reload the data
          })
          .catch(error => {
            console.error("Delete failed:", error);
          });
    }

  },
};
</script>