<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h3 class="mb-5 text-center">Prices Log</h3>
          <input
              v-model="searchSku"
              @input="orderLoad"
              placeholder="Search by SKU"
              class="input"
          />

          <!-- Optional chaining to check length safely -->
          <table>
            <thead>
            <tr>
              <th>SKU</th>
              <th>Product Name</th>
              <th>Current Sale Price</th>
              <th>Old Sale Price</th>
              <th>New Sale Price</th>
              <th>Last Updated</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="log in logs" :key="log.sku">
              <td>{{ log.sku }}</td>
              <td>{{ log.name }}</td>
              <td>{{ log.current_sale_price }}</td>
              <td>{{ log.old_value }}</td>
              <td>{{ log.new_sale_price }}</td>
              <td>{{ log.updated_at }}</td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
  data() {
    return {
      logs: [],            // Ensure `logs` is initialized as an empty array
      searchSku: ''        // Added `searchSku` to bind to input
    };
  },
  methods: {
    orderLoad() {
      console.log('orderLoad triggered'); // Debugging log
      const page = `api/get-prices-logs`;
      const params = { sku: this.searchSku };
      axiosInstance.get(page, { params }).then(({ data }) => {
        this.logs = data || [];
      }).catch(error => {
        console.error('Error fetching logs:', error);
      });
    }

  },
  created() {
    this.orderLoad();
  }
};
</script>

<style scoped>
.input {
  padding: 0.5em;
  margin: 1em 0;
  width: 100%;
  max-width: 400px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 0.5em;
  border: 1px solid #ddd;
  text-align: left;
}
</style>
