<script>
import axiosInstance from '../../../../../axiosInstance';
import {useUserStore} from "../../../../../store";

export default {
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },

  data() {
    return {
      carts: [],
      selectedLoadOption: "target", // Default to target data
      filterFrom: null, // Initialize to null or another default value
      filterTo: null,
      totalBeforeDiscount: 0,
      totalAfterDiscount: 0,
      totalCom: 0,
      totalClient: 0,
      totalComNoTarget: 0,
    };
  },

  created() {
    this.userLoad();
    this.userLoadNoTarget();
    // this.onOptionChange(); // Load initial data
    this.loadDataBasedOnSelection();
  },


  methods: {

    totalClientPercentage() {
      this.totalClient = this.carts.reduce((totalClient, cart) => {
        totalClient += cart.client_percentage;
        return totalClient;
      }, 0);
    },

    totalCommision() {
      this.totalCom = this.carts.reduce((totalCom, cart) => {
        totalCom += cart.quantity * cart.sale_price * cart.client_percentage;
        return totalCom;
      }, 0);
    },

    totalCommissionNoTarget() {
      this.totalCom = this.carts.reduce((totalCom, cart) => {
        if (cart.percentage == null) {
          totalCom += cart.sale_price * cart.quantity * cart.total_commission / 100
              * cart.sub_affiliate_commission / 100;
        }
        return totalCom
      }, 0);
    },

    calculateTargetBeforeDiscount() {
      this.totalBeforeDiscount = this.carts.reduce((totalBeforeDiscount, cart) => {
        if (cart.quantity > 0) {
          totalBeforeDiscount += cart.sale_price * cart.quantity;
        }

        return totalBeforeDiscount;
      }, 0);
    }
    ,

    calculateTargetAfterDiscount() {

      this.totalAfterDiscount = this.carts.reduce((totalAfterDiscount, cart) => {
        if (cart.quantity > 0) {
          if (cart.percentage != null) {
            totalAfterDiscount += cart.sale_price * cart.quantity - (cart.sale_price * cart.quantity * cart.percentage / 100);
          }
        }

        return totalAfterDiscount;
      }, 0);
    }
    ,


    calculateNoTargetBeforeDiscount() {
      this.totalBeforeDiscount = this.carts.reduce((totalBeforeDiscount, cart) => {
        if (cart.quantity > 0) {
          if (cart.cart_discount > 0) {
            totalBeforeDiscount += cart.sale_price * cart.quantity - (cart.sale_price * cart.quantity * cart.cart_discount / 100);
          } else {
            totalBeforeDiscount += cart.sale_price * cart.quantity;
          }
        }

        return totalBeforeDiscount;
      }, 0);
    }
    ,

    calculateNoTargetAfterDiscount() {
      this.totalAfterDiscount = this.carts.reduce((totalAfterDiscount, cart) => {
        if (cart.quantity > 0) {
          if (cart.percentage != null && cart.cart_discount > 0) {
            totalAfterDiscount += (cart.sale_price * cart.quantity - (cart.sale_price * cart.quantity * cart.cart_discount / 100)) - cart.percentage;
          } else if (cart.percentage == null && cart.cart_discount > 0) {
            totalAfterDiscount += (cart.sale_price * cart.quantity - (cart.sale_price * cart.quantity * cart.cart_discount / 100)) - (cart.sale_price * cart.quantity * cart.total_commission / 100 * cart.sub_affiliate_commission / 100);
          } else if(cart.percentage != null && (cart.cart_discount == 0 || cart.cart_discount == null)) {
            totalAfterDiscount += cart.sale_price * cart.quantity - cart.percentage;
          }else if(cart.percentage == null && (cart.cart_discount == 0 || cart.cart_discount == null)) {
            totalAfterDiscount += cart.sale_price * cart.quantity - (cart.sale_price * cart.quantity * cart.total_commission / 100 * cart.sub_affiliate_commission / 100);
          }
        }
        return totalAfterDiscount;
      }, 0);
    }
    ,


    userLoadNoTarget(startDate, endDate) {
      const page = `api/get-sub-affiliate-commission-no-target`;
      const params = {
        user_id: this.$route.params.id,
        from: startDate,
        to: endDate,
      };

      axiosInstance.get(page, {params})
          .then(({data}) => {
            this.carts = data; // Ensure data is updated
            this.calculateNoTargetAfterDiscount();
            this.calculateNoTargetBeforeDiscount();
            this.totalCommissionNoTarget();
          })
          .catch((error) => {
            console.error("API call failed:", error);
          });
    }
    ,

    userLoad(startDate, endDate) {
      const page = `api/get-sub-affiliate-commission-target`;
      const params = {
        user_id: this.$route.params.id,
        from: startDate,
        to: endDate,
      };

      axiosInstance.get(page, {params})
          .then(({data}) => {
            this.carts = data; // Ensure data is updated
            this.calculateTargetAfterDiscount();
            this.calculateTargetBeforeDiscount();
            this.totalCommision();
            this.totalClientPercentage();
          })
          .catch((error) => {
            console.error("API call failed:", error);
          });
    }
    ,

    loadDataBasedOnSelection() {
      let startDate = "";
      let endDate = "";

      if (this.filterFrom) {
        const parsedFromDate = new Date(this.filterFrom);
        if (!isNaN(parsedFromDate.getTime())) {
          startDate = parsedFromDate.toISOString().split("T")[0]; // Ensure valid date
        }
      }

      if (this.filterTo) {
        const parsedToDate = new Date(this.filterTo);
        if (!isNaN(parsedToDate.getTime())) {
          endDate = parsedToDate.toISOString().split("T")[0]; // Ensure valid date
        }
      }

      // Ensure startDate and endDate are valid before calling userLoad/userLoadNoTarget
      if (startDate && endDate) {
        if (this.selectedLoadOption === "noTarget") {
          this.userLoadNoTarget(startDate, endDate);
          this.calculateNoTargetBeforeDiscount();
          this.calculateNoTargetAfterDiscount();
          this.totalCommissionNoTarget();
        } else {
          this.userLoad(startDate, endDate);
          this.calculateTargetBeforeDiscount();
          this.calculateTargetAfterDiscount();
          this.totalCommision();
          this.totalClientPercentage();
        }
      }
    }
    ,

    applyDateFilter() {
      let startDate = null;
      let endDate = null;

      if (this.filterFrom) {
        const parsedFromDate = new Date(this.filterFrom);
        if (!isNaN(parsedFromDate.getTime())) {
          startDate = parsedFromDate.toISOString().split("T")[0]; // Convert to date part
        } else {
          console.error("Invalid 'from' date");
        }
      }

      if (this.filterTo) {
        const parsedToDate = new Date(this.filterTo);
        if (!isNaN(parsedToDate.getTime())) {
          endDate = parsedToDate.toISOString().split("T")[0]; // Convert to date part
        } else {
          console.error("Invalid 'to' date");
        }
      }

      if (startDate && endDate) {
        this.loadDataBasedOnSelection(startDate, endDate); // Pass valid dates
      } else {
        console.error("Invalid date range");
      }
    }
    ,

  }
}
;
</script>

<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <div class="row mb-4">
            <div class="col-md-4">
              <label for="dataLoadOption" class="form-label">Select Data:</label>
              <!-- Select element triggering the function -->
              <select v-model="selectedLoadOption" class="form-select" @change="loadDataBasedOnSelection">
                <option value="target">Target Data</option>
                <option value="noTarget">No Target Data</option>
              </select>
            </div>
            <div class="col-md-4">
              <label for="from" class="form-label">From:</label>
              <input type="date" id="from" class="form-control" v-model="filterFrom" @change="applyDateFilter"/>
            </div>
            <div class="col-md-4">
              <label for="to" class="form-label">To:</label>
              <input type="date" id="to" class="form-control" v-model="filterTo" @change="applyDateFilter"/>
            </div>
          </div>

          <table class="table table-bordered table-striped">
            <thead>
            <tr>
              <th>#</th>
              <th>User Name</th>
              <th>Client</th>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total Before Discount</th>
              <th>Discount</th>
              <th>Product Discount</th>
              <th>Total After Discount</th>
              <th>Affiliate Percentage</th>
              <th>Administrator Percentage</th>
              <th>Created at</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="cart in carts" :key="cart.id">
              <td>INV00{{ cart.invoice_id }}</td>
              <td>{{ cart.first_name }} {{ cart.middle_name }} {{ cart.last_name }}</td>
              <td class="text-dark" :style="{ backgroundColor: 'cyan' }">{{ cart.user_first_name }}
                {{ cart.user_middle_name }} {{ cart.user_last_name }}
              </td>
              <td>{{ cart.title }} - {{ cart.sku }}</td>
              <td :class="cart.quantity == 0 ? 'bg-danger' : 'bg-success'">{{ cart.quantity }}</td>
              <td>${{ cart.sale_price.toFixed(2) }}</td>
              <td>${{ (cart.quantity * cart.sale_price).toFixed(2) }}</td>
              <td v-if="cart.main_brand == 1">%{{ cart.percentage }}</td>
              <td v-else>${{
                  (cart.sale_price * cart.quantity * cart.total_commission / 100 * cart.sub_affiliate_commission / 100).toFixed(2)
                }}
              </td>
              <td>%{{ cart.cart_discount }}</td>
              <td v-if="cart.main_brand == 1">${{
                  (cart.quantity * cart.sale_price - (cart.quantity * cart.sale_price * cart.percentage / 100)).toFixed(2)
                }}
              </td>
              <td v-else>${{
                  (cart.quantity * cart.sale_price - (cart.sale_price * cart.total_commission / 100 * cart.sub_affiliate_commission / 100 * cart.quantity)).toFixed(2)
                }}
              </td>
              <td v-if="cart.client_percentage !== null">%{{ cart.client_percentage }}</td>
              <td v-else>-</td>
              <td v-if="cart.affiliate_percentage !== null">{{ cart.affiliate_percentage }}</td>
              <td v-else>-</td>
              <td>{{ cart.created_at }}</td>
            </tr>
            </tbody>
          </table>

          <div class="mt-5">
            <p>Total Before Discount: <span class="badge bg-primary p-2">{{ totalBeforeDiscount.toFixed(2) }}</span></p>
            <p class="mt-3">Total After Discount: <span class="badge bg-success p-2">{{
                totalAfterDiscount.toFixed(2)
              }}</span></p>
            <!--            <p class="mt-3" v-if="selectedLoadOption === 'target'">Commission: <span-->
            <!--                class="badge bg-success p-2">{{ totalCom.toFixed(2) }}</span> / Exchange: <span-->
            <!--                class="badge bg-primary p-2">{{ totalClient.toFixed(2) }}</span></p>-->
            <!--            <p class="mt-3" v-else>Commission: <span class="badge bg-success p-2">{{ totalCom.toFixed(2) }}</span></p>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card {
  margin-bottom: 1rem;
}

.table thead th {
  background-color: #f8f9fa;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-success {
  background-color: #28a745 !important;
}
</style>