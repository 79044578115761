<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <select v-model="selectedBrand" @change="loadProductsByBrand">
            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
              {{ brand.brand_name }}
            </option>
          </select>

          <h3 class="mb-5">Product Detail</h3>

          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Sku</th>
              <th>Title</th>
              <th>Sale Price</th>
              <th>Image</th>
              <th>Total Commission</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in productDetail" :key="product.id">
              <td>{{ product.id }}</td>
              <td>{{ product.sku }}</td>
              <td>{{ product.title }}</td>
              <td v-if="product.sale_price">${{ product.sale_price }}</td>
              <td><img :src="'https://api.gocami.com/product_detail/' + product.image" width="75"/>
              </td>
              <td>{{product.total_commission}}</td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
  data() {
    return {
      selectedBrand: null,
      productDetail: [],
      brands: [],
      products: [],
      childcategory: [],
      file: null

    };
  },

  created() {
    this.fetchAllData();
  },

  methods: {
    loadProductsByBrand() {
      if (this.selectedBrand) {
        this.fetchAllData(this.selectedBrand);
      }
    },

    fetchAllData(brandId) {
      return axiosInstance
          .get(`api/target-change/${brandId}`)
          .then(response => {
            this.productDetail = response.data.productDetail;
            this.brands = response.data.brands;
          })
          .catch(error => {
            console.error('Error fetching main data:', error);
          });
    },

  },

}
</script>
