<!-- Vue Component -->

<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Product Detail</h3>

          <div v-if="successMessage" style="position: fixed !important" class="alert alert-success" role="alert">
            {{ successMessage }}
          </div>
          <div v-if="errorMessage" style="position: fixed !important" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>

          <div class="mb-3">
            <input type="text" v-model="enteredBarcode" class="form-control" placeholder="Enter Barcode">
          </div>

          <select v-model="selectedBrand" @change="loadProductsByBrand" class="form-select my-3">
            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
              {{ brand.brand_name }}
            </option>
          </select>
          <br/>
          <button @click="updateCheckedQuantities" class="btn btn-success my-4"
                  :disabled="!selectedSupplier || !receiveDate || !invoiceNumber">Update Checked Quantities
          </button>

          <div class="mb-3">
            <select v-model="selectedSupplier" class="form-select" :class="{ 'is-invalid': !selectedSupplier }"
                    required>
              <option disabled value="">Select Supplier</option>
              <option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id">{{
                  supplier.supplier_name
                }}
              </option>
            </select>
            <div class="invalid-feedback">Please select a supplier.</div>
          </div>

          <div class="mb-3">
            <input type="date" v-model="receiveDate" class="form-control" required placeholder="Receive Date">
          </div>

          <div class="mb-3">
            <input type="text" v-model="invoiceNumber" class="form-control" required placeholder="Invoice Number">
          </div>

          <table class="table w-100 responsive">
            <thead>
            <tr>
              <th width="15%">Image</th>
              <th width="15%">Sku</th>
              <th width="15%">Title</th>
              <th>Quantity</th>
              <th width="30%">New Quantity</th>
              <th width="15%">Select</th>
              <th>Barcode</th>
              <th>Patch Number</th>
              <th>Expiry Date</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in allProductDetails" :key="product.id" :id="'product-row-' + product.id">
              <td><img :src="'https://api.gocami.com/product_detail/' + product.image" width="75"/></td>
              <td>{{ product.sku }}</td>
              <td>{{ product.title }}</td>
              <td>{{ product.quantity }}</td>
              <td>
                <input type="number" class="form-control w-100" v-model="product.newQuantity"
                       style="width: 150px !important"/>
              </td>
              <td><input type="checkbox" class="form-check-input" v-model="product.checked"/></td>
              <td>{{ product.barcode }}</td>
              <td>
                <input type="text" class="form-control w-100" v-model="product.patchNumber"
                       style="width: 150px !important"/>
              </td>
              <td>
                <input type="date" class="form-control w-100" v-model="product.expiryDate"
                       style="width: 150px !important"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
  data() {
    return {
      allProductDetails: [],
      currentPage: 1,
      selectedBrand: null,
      selectedSupplier: null,
      receiveDate: '',
      invoiceNumber: '',
      brands: [],
      suppliers: [],
      enteredBarcode: '',
      successMessage: '',
      errorMessage: ''
    };
  },

  created() {
    this.userLoad();
  },

  watch: {
    enteredBarcode(newBarcode) {
      this.findProductByBarcode(newBarcode);
    }
  },

  methods: {
    loadProductsByBrand() {
      if (this.selectedBrand) {
        this.userLoad(this.selectedBrand);
      } else {
        this.userLoad();
      }
    },

    userLoad(brandId) {
      var nextPage = `api/get-quantity-data/${brandId}`;
      axiosInstance.get(nextPage).then(({data}) => {
        this.allProductDetails = data.products.map(product => ({...product, checked: false}));
        this.brands = data.brands;
        this.suppliers = data.supplier;
      });
    },

    updateCheckedQuantities() {
      if (!this.selectedSupplier || !this.receiveDate || !this.invoiceNumber) {
        this.errorMessage = 'Please select a supplier, enter a receive date, and enter an invoice number.';
        this.clearMessageAfterTimeout('errorMessage');
        return;
      }

      const checkedProducts = this.allProductDetails.filter(product => product.checked);
      checkedProducts.forEach(product => {
        const oldQuantity = product.quantity;
        const newQuantity = parseInt(product.newQuantity);
        const productDetailId = product.id;

        axiosInstance.put('api/update-quantity-and-check', {
          productDetailId,
          oldQuantity,
          newQuantity,
          supplierId: this.selectedSupplier,
          receiveDate: this.receiveDate,
          invoiceNumber: this.invoiceNumber,
          patchNumber: product.patchNumber,
          expiryDate: product.expiryDate,
        }).then(response => {
          console.log(response);
          product.quantity = newQuantity;
          product.newQuantity = '';
          product.checked = false;
          product.patchNumber = ''; // Clear patch number
          product.expiryDate = ''; // Clear patch number
          this.successMessage = 'Quantities updated successfully!';
          this.clearMessageAfterTimeout('successMessage');
        }).catch(error => {
          console.log(error);
          this.errorMessage = 'Error updating quantities!';
          this.clearMessageAfterTimeout('errorMessage');
        });
      });

      // Clear other data after success
      this.selectedSupplier = null;
      this.receiveDate = '';
      this.invoiceNumber = '';
    },


    clearMessageAfterTimeout(messageType) {
      setTimeout(() => {
        this[messageType] = '';
      }, 5000);
    },

    findProductByBarcode(enteredBarcode) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      this.searchTimeout = setTimeout(() => {
        if (enteredBarcode) {
          const foundProduct = this.allProductDetails.find(product => product.barcode === enteredBarcode.trim());
          if (foundProduct) {
            const rowId = `product-row-${foundProduct.id}`;
            const rowElement = document.getElementById(rowId);
            if (rowElement) {
              rowElement.scrollIntoView({behavior: "smooth", block: "center"});
              this.enteredBarcode = '';
            } else {
              console.log("Row element not found:", rowId);
            }
          } else {
            console.log("Product not found.");
          }
        } else {
          console.log("Please enter a barcode.");
        }
      }, 1000);
    }
  }
};
</script>
