<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <div class="filter-section mb-3">
            <label for="from" class="me-2">From:</label>
            <input type="date" class="form-control w-25 d-inline-block me-3" v-model="filterFrom"
                   @change="fetchCommissions"/>
            <label for="to" class="me-2">To:</label>
            <input type="date" class="form-control w-25 d-inline-block" v-model="filterTo" @change="fetchCommissions"/>
          </div>
          <table class="table table-striped w-100">
            <thead>
            <tr>
              <th>Affiliate ID</th>
              <th>Affiliate Name</th>
              <th>Total Commission (Not Used)</th>
              <th>Current Level</th>
              <th>Next Level</th>
              <th>Total Commission (Used)</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="affiliate in commissions" :key="affiliate.user_id">
              <tr>
                <td>{{ affiliate.user_id }}</td>
                <td>{{ affiliate.first_name }} {{ affiliate.last_name }}</td>
                <td>{{ affiliate.total_commission_status_0 }}</td>
                <td>{{ getCurrentLevel(affiliate.total_commission_status_0) }}</td>
                <td>{{ getNextLevel(affiliate.total_commission_status_0) }}</td>
                <td>{{ affiliate.total_commission_status_1 }}</td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, onMounted} from 'vue';
import axiosInstance from '../../../axiosInstance.js';

export default {
  setup() {
    const commissions = ref([]);
    const filterFrom = ref(null);
    const filterTo = ref(null);

    const fetchCommissions = () => {
      const apiEndpoint = `api/sub-affiliate-medals`;
      const params = {
        from: filterFrom.value,
        to: filterTo.value,
      };

      axiosInstance
          .get(apiEndpoint, {params})
          .then(response => {
            console.log(response.data); // Log response data to verify
            commissions.value = response.data;
          })
          .catch(error => {
            console.error('Error fetching affiliate commissions:', error);
          });
    };

    const getCurrentLevel = (total_commission_status_0) => {
      if (total_commission_status_0 >= 0 && total_commission_status_0 < 150) {
        return '-';
      } else if (total_commission_status_0 >= 150 && total_commission_status_0 < 250) {
        return 'Silver 1';
      } else if (total_commission_status_0 >= 250 && total_commission_status_0 < 350) {
        return 'Silver 2';
      } else if (total_commission_status_0 >= 350 && total_commission_status_0 < 400) {
        return 'Silver 3';
      } else if (total_commission_status_0 >= 400 && total_commission_status_0 < 675) {
        return 'Gold 1';
      } else if (total_commission_status_0 >= 675 && total_commission_status_0 < 750) {
        return 'Gold 2';
      } else if (total_commission_status_0 >= 750 && total_commission_status_0 < 850) {
        return 'Gold 3';
      } else if (total_commission_status_0 >= 850 && total_commission_status_0 < 975) {
        return 'Platinum 1';
      } else if (total_commission_status_0 >= 975 && total_commission_status_0 < 1100) {
        return 'Platinum 2';
      } else if (total_commission_status_0 >= 1100) {
        return 'Platinum 3';
      } else {
        return 'No Level';
      }
    };

    const getNextLevel = (total_commission_status_0) => {
      if (total_commission_status_0 < 150) {
        return 'Silver 1';
      } else if (total_commission_status_0 >= 150 && total_commission_status_0 < 250) {
        return 'Silver 2';
      } else if (total_commission_status_0 >= 250 && total_commission_status_0 < 350) {
        return 'Silver 3';
      } else if (total_commission_status_0 >= 350 && total_commission_status_0 < 400) {
        return 'Gold 1';
      } else if (total_commission_status_0 >= 400 && total_commission_status_0 < 675) {
        return 'Gold 2';
      } else if (total_commission_status_0 >= 675 && total_commission_status_0 < 750) {
        return 'Gold 3';
      } else if (total_commission_status_0 >= 750 && total_commission_status_0 < 850) {
        return 'Platinum 1';
      } else if (total_commission_status_0 >= 850 && total_commission_status_0 < 975) {
        return 'Platinum 2';
      } else if (total_commission_status_0 >= 975 && total_commission_status_0 < 1100) {
        return 'Platinum 3';
      } else {
        return 'Highest Level Achieved';
      }
    };

    onMounted(() => {
      fetchCommissions();
    });

    return {
      commissions,
      filterFrom,
      filterTo,
      fetchCommissions,
      getCurrentLevel,
      getNextLevel,
    };
  },
};
</script>

<style scoped>
.main-panel {
  padding: 1rem;
}

.filter-section {
  display: flex;
  align-items: center;
}

.table-striped {
  margin-top: 1rem;
}

.table th,
.table td {
  vertical-align: middle;
}

.form-control {
  max-width: 200px;
}
</style>

<style scoped>
.card {
  margin-bottom: 1rem;
}
</style>


<style scoped>
.table {
  border-collapse: collapse;
  width: 100%;
}

.table th, .table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.table th {
  background-color: #f2f2f2;
  font-weight: bold;
}
</style>

