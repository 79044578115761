<template>
    <div class="temp mx-auto">

  
      <!-- ========================  Product ======================== -->
  
      <section class="product">
  
        <div class="toast" :class="{ 'show': showSuccessMessage, 'error': isError }">
          {{ message }}
        </div>
  
        <form @submit.prevent="save" v-if="products">
          <div class="main">
            <div class="container">
              <div class="row product-flex">
  
                <div class="col-md-12 col-sm-12 product-flex-info mt-5">
                  <div class="clearfix">
                    <!-- === product-title === -->
  
                    <!-- <form @submit.prevent="addFavorite">
  
                      <div class="info-box info-box-addto added">
                        <span>
                          <template v-if="productInFavorites">
                            <i class="added"><i class="fa fa-heart"></i> Product is in favorites</i>
                          </template>
                          <template v-else>
                            <button type="submit" style="border:none;background-color: white;" @click="favoritedetail()">
                              <i class="added"><i class="fa fa-heart"></i> Add to favorites</i>
                            </button>
                          </template>
                        </span>
                      </div>
  
                    </form> -->
  
                  </div>
                </div>
              </div>
              <div v-if="isLoading" class="loading-indicator">
                <div class="loading-spinner"></div>
                Loading...
              </div>
  
              <div v-else>
  
                <div class="product-container">
                  <div class="row">
                    <div class="col-lg-1 col-md-1 col-sm-12">
                      <div class="video-container" v-if="products.video_url != null" @click="displayVideo">
                        <img :src="'https://img.youtube.com/vi/' + products.video_url + '/maxresdefault.jpg'"
                          alt="Video Thumbnail" width="100" height="100">
                      </div>
  
                      <div v-if="Array.isArray(products.multi_image) && products.multi_image.length > 0" class="multi-image">
                        <div v-for="(image, index) in products.multi_image" :key="index">
                          <img :src="getMultiImageUrl(image)" width="100" height="100" @click="displayImage(image)" />
                        </div>
                      </div>
  
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-12 product-flex-gallery">
                      <div class="owl-product-gallery open-popup-gallery">
                        <div class="zoom-container" @mousemove="handleImageHover" @mouseleave="resetZoom"
                          ref="zoomContainer">
                          <div v-if="displayedMediaType === 'image'">
                            <img :src="displayedImage" alt="" style="width: 100% !important;" :style="zoomedImageStyle"
                              class="zoomed-image" />
                          </div>
                          <div v-else-if="displayedMediaType === 'video'">
                            <!-- Video container -->
                            <div class="video-container">
                              <iframe ref="youtubeVideo"
                                :src="'https://www.youtube.com/embed/' + products.video_url + '?enablejsapi=1&autoplay=1&mute=1&controls=0&showinfo=0&loop=0'"
                                frameborder="0" allow="autoplay; encrypted-media" allowfullscreen
                                sandbox="allow-scripts allow-same-origin"
                                style="width: 90% !important; height: 50vh; padding-left: 20px;"
                                @load="onYouTubeIframeAPIReady"></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    <div class="col-md-5 col-sm-12 product-flex-info">
                      <!-- <div class="zoomed-in-image" v-if="displayZoomedInImage" :style="zoomedInImagePosition"
                        style="border-radius: 100%;"></div> -->
  
  
                      <h1 class="title" data-title="">
                        {{ products.title }}
                      </h1>
  
                      <h5>SKU: {{ products.sku }}</h5>
                      <div class="clearfix">
                        <!-- === price wrapper === -->
                        <div class="price" v-if="products">
  
                          <span class="h3" v-if="products.discount > 0">
  
  
                            Before Discount: <del class="text-danger"> $ {{ Math.floor(products.sale_price) }}.<span 
                              style="vertical-align: super; font-size: 90% !important;">{{
                                (products.sale_price % 1).toFixed(2).slice(-2) }}</span></del> <br />
  
  
                            After Discount: $ {{ Math.floor(products.sale_price - (products.sale_price * products.discount)
                              /
                              100) }}.<span style="vertical-align: super; font-size:90% !important">
                              {{ (
                                products.sale_price -
                                (products.sale_price *
                                  products.discount) /
                                100
                              ).toFixed(2).slice(-2) }}
                            </span>
  
                            <br/>
                            <!-- <small class="text-danger" v-if="products.sale_price">
                              $ {{ products.sale_price ? products.sale_price.toFixed(2) : "" }}
                            </small><br /> -->
  
  
                            Saving: <span class="badge bg-success"
                              style="font-size: 10pt !important;font-family: 'Courier New', Courier, monospace;">{{
                                parseFloat(products.discount) }}% OFF</span>
                          </span>
  
                          <span class="h3" v-else>
                            Price: $ {{ Math.floor(products.sale_price) }}.<span
                              style="vertical-align: super; font-size: 90% !important;">{{
                                (products.sale_price % 1).toFixed(2).slice(-2) }}</span> <br />
                          </span>
  
                          <br />
  
                          <!-- <span class="h3">
                          Points: {{ products.point_price }}pts
                        </span> -->
  
                          <br />
                          <div v-if="products.sales_quantity">
                            <span v-if="products.sales_quantity > 0" style="color:red">
                              Quantites left: {{ products.sales_quantity }}
                            </span>
                            <span v-else style="color:red">
                              Quantites left: 0
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <h3>About this Item</h3>
                      <p v-html="products.description"></p>
  
                      <hr />
  
  
                      <div class="info-box">
                        <span><strong>Brand: </strong></span>
                        <span style="padding-left:5px">{{ products.brand_name }}</span>
                      </div>
  
                      <!-- Display availability status once data is loaded -->
                      <div class="info-box"
                        v-if="(products.sales_quantity > 0 || (products.sales_quantity === null && products.quantity > 0))">
                        <span><strong>Availability</strong></span>
                        <span style="padding-left:5px"><i class="fa fa-check-square"></i> In stock</span>
                      </div>
                      <div class="info-box" v-else-if="products.sales_quantity <= 0 && products.quantity <= 0">
                        <span><strong>Availability:</strong></span>
                        <span style="padding-left:5px; color:red"><i class="fa fa-truck"></i> Out of stock</span>
                      </div>
  
                      <div class="col-md-12 mt-2" style="display: flex;">
                        <div class="mr-3">
                          <button type="submit" class="btn border text-white rounded-lg" style="background-color: #8CBDC0;"
                            v-if="(products.sales_quantity > 0 || (products.sales_quantity === null && products.quantity > 0)) && publicKey">
                            <i class="fa fa-shopping-cart pe-2"></i>Add to cart
                          </button>
                          <router-link
                            v-else-if="(products.sales_quantity > 0 || (products.sales_quantity === null && products.quantity > 0)) && !this.user?.id"
                            :to="{ path: '/login', query: { from: $route.fullPath } }"
                            class="btn text-white border rounded-lg" style="background-color: #8CBDC0;">
                            <i class="fa fa-shopping-cart pr-2"></i>Add to cart
                          </router-link>
                        </div>
                        <div>
                          <div v-if="this.user?.id">
                            <form @submit.prevent="addFavorite">
                              <button type="submit" class="btn bg-danger border rounded-lg"><i
                                  class="fa fa-heart"></i></button>
                            </form>
                          </div>
  
                          <div v-else>
                            <router-link :to="{ path: '/login', query: { from: $route.fullPath } }"
                              class="btn bg-danger border rounded-lg"><i class="fa fa-heart"></i></router-link>
                          </div>
                        </div>
                      </div>
  
                      <!-- <div class="col-md-12 mt-2" style="display: flex;" v-if="this.user?.id">
                      <div class="mr-3">
                        <form @submit.prevent="point">
                          <button class="btn bg-warning border rounded-lg" style="color:white !important"><i
                              class="fa fa-info-circle"> Buy by
                              points</i></button>
                        </form>
                      </div>
                    </div>
  
                    <div class="col-md-12 mt-2" style="display: flex;" v-else>
                      <div class="mr-3">
                        <router-link :to="{ path: '/login', query: { from: $route.fullPath } }"
                          class="btn bg-warning border rounded-lg" style="color:white !important"><i
                            class="fa fa-info-circle"> Buy by
                            points</i></router-link>
                      </div>
                    </div> -->
  
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="col-md-12 col-sm-12 product-flex-info mt-5">
                <div class="clearfix">
                  <div class="clearfix">
  
                    <!-- === info-box === -->
                    <div class="product-options">
  
  
                      <div v-if="colors.size > 0" class="info-box">
                        <span><strong>Available Colors: </strong></span>
                        <input v-if="colors.size > 10" class="form-control" placeholder="Search..." v-model="searchQuery" />
                        <br />
                        <div class="product-colors clearfix">
                          <fieldset>
                            <div class="custom-radio" v-for="color in filteredColors" :key="color && color.id">
                              <input type="radio" class="custom-control-input" :checked="selectedColor === color"
                                :id="color" name="colors" field="color" @click="changeColor('color_name', color)" />
                              <label class="custom-control-label" :for="color">{{ color }}</label>
                            </div>
                          </fieldset>
                        </div>
                        <button v-if="colors.size > 10" type="button" class="btn btn-primary" @click="showMoreColors">
                          {{ showAllColors ? 'Show Less' : 'Read More' }}
                        </button>
                      </div>
  
                      <div v-if="sizes.size > 0" class="info-box">
                        <span><strong>Choose size: </strong></span>
                        <input v-if="sizes.size > 10" class="form-control" placeholder="Search..." v-model="searchQuery" />
                        <br />
                        <div class="product-colors clearfix">
                          <fieldset>
                            <div class="custom-radio" v-for="size in filteredSizes" :key="size && size.id">
                              <input type="radio" class="custom-control-input" :checked="selectedSize === size" :id="size"
                                :key="size" name="sizes" :value="size" @click="changeSize('size_name', size)" />
                              <label class="custom-control-label" :for="size">{{ size }}</label>
                            </div>
                          </fieldset>
                        </div>
                        <button v-if="sizes.size > 10" type="button" class="btn btn-primary" @click="showMoreSizes">
                          {{ showAllSizes ? 'Show Less' : 'Read More' }}
                        </button>
                      </div>
  
                      <div v-if="attributes.size > 0" class="info-box">
                        <span><strong>{{ products.attribute }}: </strong></span>
                        <input v-if="attributes.size > 10" class="form-control" placeholder="Search..."
                          v-model="searchQuery" />
                        <br />
                        <div class="product-colors clearfix">
                          <fieldset>
                            <div class="custom-radio" v-for="attribute in filteredAttributes"
                              :key="attribute && attribute.id">
  
                              <input type="radio" class="custom-control-input" :checked="selectedAttribute === attribute"
                                :id="attribute" name="attributes" field="attribute"
                                @click="changeAttribute('attribut_type_name', attribute)" />
                              <label class="custom-control-label" :for="attribute">{{ attribute }}</label>
                            </div>
                          </fieldset>
                        </div>
                        <button v-if="attributes.size > 10" type="button" class="btn btn-primary"
                          @click="showMoreAttributes">
                          {{ showAllAttributes ? 'Show Less' : 'Read More' }}
                        </button>
                      </div>
  
                    </div>
  
                  </div>
                  <!--/clearfix-->
                </div>
                <!--/product-info-wrapper-->
              </div>
              <!--/col-md-4-->
              <!-- === product item gallery === -->
  
  
  
            </div>
          </div>
        </form>
  
        <!-- === product-info === -->
  
        <div class="info" v-if="products">
          <div class="container">
            <div class="row">
  
              <div class="col-md-9">
                <ul class="nav nav-tabs" role="tablist">
                  <li role="presentation" :class="{ active: activeTab === 'about' }">
                    <a @click="changeTab('about')" href="#about" aria-controls="about" role="tab">
                      <i class="icon icon-history"></i>
                      <span>About</span>
                    </a>
                  </li>
                </ul>
  
  
                <div class="tab-content" style="width: 110vh;">
  
                  <div role="tabpanel" class="tab-pane" :class="{ active: activeTab === 'about' }" id="about">
                    <div class="content">
                      <h3>About this Item</h3>
                      <p v-html="products.details"></p>
                      <hr />
                    </div>
                  </div>
  

                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div v-else style="padding: 50px; font-size: 18pt; font-weight: 700;">No product found..</div>
        <!--/info-->
      </section>
  

    </div>
  </template>
  
  <script>
  import axiosInstance from '../../../../axiosInstance';
  import { useUserStore } from "../../../../store";
  
  
  export default {
    setup() {
      const store = useUserStore();
      const user = store.user;
      return {
        user,
      };
    },
  
    computed: {
      defaultImageSrc() {
        return 'https://api.gocami.com/product_detail/' + this.products.image;
      },
  
      paginatedRates() {
        const startIndex = (this.currentPage - 1) * this.ratesPerPage;
        const endIndex = startIndex + this.ratesPerPage;
        return this.rates.slice(startIndex, endIndex);
      },
      zoomedImageStyle() {
        const zoomFactor = 1; // Adjust the zoom factor as needed
        return {
          transform: `scale(${zoomFactor})`,
          transformOrigin: `${this.cursorPosition.x}px ${this.cursorPosition.y}px`
        };
      },
      zoomedInImagePosition() {
        const zoomFactor = 2.6; // Adjust the same zoom factor here
        const bgPosX = -this.cursorPosition.x * (zoomFactor - 1);
        const bgPosY = -this.cursorPosition.y * (zoomFactor - 1);
        return {
          backgroundImage: `url(${this.selectedImage || this.defaultImageSrc})`, // Use this.defaultImageSrc
          backgroundSize: `${zoomFactor * 100}%`,
          backgroundPosition: `${bgPosX}px ${bgPosY}px`,
          width: "300px", // Adjust to your desired size
          height: "300px", // Adjust to your desired size
          // borderRadius: "100%",
          marginLeft: "50px",
        };
      },
      limitedAttributes() {
        const MAX_DISPLAYED_ATTRIBUTES = 10;
        return Array.from(this.attributes).slice(0, MAX_DISPLAYED_ATTRIBUTES);
      },
  
      filteredAttributes() {
        if (this.searchQuery) {
          return Array.from(this.attributes).filter(attribute => attribute.toLowerCase().includes(this.searchQuery.toLowerCase()));
        }
        return this.showAllAttributes ? Array.from(this.attributes) : this.limitedAttributes;
      },
  
      limitedColors() {
        const MAX_DISPLAYED_ATTRIBUTES = 10;
        return Array.from(this.colors).slice(0, MAX_DISPLAYED_ATTRIBUTES);
      },
  
      filteredColors() {
        if (this.searchQuery) {
          return Array.from(this.colors).filter(color => color.toLowerCase().includes(this.searchQuery.toLowerCase()));
        }
        return this.showAllColors ? Array.from(this.colors) : this.limitedColors;
      },
  
      limitedSizes() {
        const MAX_DISPLAYED_ATTRIBUTES = 10;
        return Array.from(this.sizes).slice(0, MAX_DISPLAYED_ATTRIBUTES);
      },
  
      filteredSizes() {
        if (this.searchQuery) {
          return Array.from(this.sizes).filter(size => size.toLowerCase().includes(this.searchQuery.toLowerCase()));
        }
        return this.showAllSizes ? Array.from(this.sizes) : this.limitedSizes;
      },
  
    },
  
    data() {
      return {
        displayedMediaType: 'image', // Default to image
        displayedImage: null,
        selectedImage: null,
        selectedProductDetailId: null, // Initialize with the initial product detail ID
        rating: {
          feedback: '',
          rating: '',
        },
        averageRating: [],
        currentPage: 1, // Initially display page 1
        ratesPerPage: 5, // Number of rates to display per page
        rates: [], // Store rates as an array
        activeTab: 'about',
        isLoading: true,
        selectedAttribute: null,
        displayedAttributes: [],
        showAllAttributes: false,
        showAllColors: false,
        showAllSizes: false,
        productnames: [],
        products: [],
        searchQuery: '',
        allProducts: [],
        colors: new Set(),
        sizes: new Set(),
        brands: [],
        related: [],
        selectedColor: null,
        selectedSize: null,
        previousColorData: null,
        attributes: new Set(),
        message: '',
        isError: false,
        publicKey: this.user?.first_name.concat(" ", this.user?.last_name),
        showSuccessMessage: false,
        productInFavorites: false, // Initialize as false
        cart: {
          quantity: 1,
          // product_detail_id: 654
        },
        addToCart: false,
        displayZoomedInImage: false,
        cursorPosition: { x: 0, y: 0 },
        displayedVideo: null,
  
      };
    },
  
    created() {
      this.dataLoad();
      this.fetchRates();
      this.fetchAverage();
      this.multiImageLoad();
      this.getCart();
    },
  
    watch: {
      "$route.params.id": function () {
        this.dataLoad();
      },
    },
  
    beforeRouteEnter(to, from, next) {
      if (to.query.addToCart) {
        next(vm => {
          vm.showSuccessMessage = true;
          setTimeout(() => {
            vm.showSuccessMessage = false;
          }, 5000);
        });
      } else {
        next();
      }
    },
  
    methods: {
      loadVideo() {
        // Get the video container
        const container = this.$refs.videoContainer;
  
        // Get the source URL of the video
        const videoUrl = this.products.video_url;
  
        // Replace the container content with the iframe to load the video
        container.innerHTML = `<iframe 
          src="https://www.youtube.com/embed/${videoUrl}?autoplay=1" 
          frameborder="0" 
          allow="autoplay; encrypted-media" 
          allowfullscreen
          width="100%" 
          height="100%">
        </iframe>`;
      },
  
      resetDisplayedImage() {
        this.displayedImage = this.defaultImageSrc;
        this.displayedMediaType = 'image';
      },
      displayVideo() {
        this.displayedMediaType = 'video';
        this.selectedImage = this.displayedImage; // Store the selected image URL
      },
      displayImage(image = null) {
        this.displayedImage = image ? 'https://api.gocami.com/product_multi_image/' + image : this.defaultImageSrc;
        this.selectedImage = this.displayedImage; // Store the selected image URL
        this.displayedMediaType = 'image';
      },
      getMultiImageUrl(image) {
        return 'https://api.gocami.com/product_multi_image/' + image;
      },
  
      fetchRates(productDetailId) {
        axiosInstance.get(`api/rates/${productDetailId}`).then(({ data }) => {
          this.rates = data.rates; // Assuming your API returns an array of rates
        });
      },
  
      fetchAverage(productDetailId) {
        axiosInstance.get(`api/average/${productDetailId}`)
          .then(({ data }) => {
            if (data && data.averageRatings) {
              this.averageRating = data.averageRatings;
            } else {
              // Handle the case where data is empty or doesn't have the expected structure
              this.averageRating = null; // Set to a default value or handle the error appropriately
            }
          })
          .catch(error => {
            console.error('An error occurred while fetching average rating:', error);
            this.averageRating = null; // Handle the error appropriately
          });
      },
  
      previousPage() {
        if (this.currentPage > 1) {
          this.currentPage -= 1;
        }
      },
      nextPage() {
        if (this.currentPage * this.ratesPerPage < this.rates.length) {
          this.currentPage += 1;
        }
      },
  
      changeTab(tab) {
        this.activeTab = tab;
      },
      handleImageHover(event) {
        const containerRect = this.$refs.zoomContainer.getBoundingClientRect();
        const x = event.clientX - containerRect.left;
        const y = event.clientY - containerRect.top;
        this.cursorPosition = { x, y };
        this.displayZoomedInImage = true;
      },
      resetZoom() {
        this.cursorPosition = { x: 0, y: 0 };
        this.displayZoomedInImage = false;
        // this.resetDisplayedImage();
      },
  
      navigateToProductDetail() {
        this.$nextTick(() => {
          window.scrollTo(0, 0);
        });
      },
  
      changeColor(key, val) {
        const newProd = this.allProducts.filter((prod) => prod.color_name === val);
  
        if (newProd.length > 0) {
          // Filter based on color, size, and attribute if selected
          const filteredProd = newProd.filter(
            (prod) =>
              (!this.selectedSize || prod.size_name === this.selectedSize) &&
              (!this.selectedAttribute || prod.attribute_type_name === this.selectedAttribute)
          );
  
          if (filteredProd.length > 0) {
            this.selectedColor = val;
            this.products = filteredProd[0]; // Update the displayed product based on color, size, and attribute selection
            this.fetchRates(this.products.product_detail_id); // Fetch rates for the selected product detail
            this.fetchAverage(this.products.product_detail_id);
            this.resetDisplayedImage();
            this.multiImageLoad(this.products.product_detail_id);
            this.selectedImage = null;
  
            // Update sizes and attributes based on the filtered products
            const sizes = newProd.map((prod) => prod.size_name).filter(Boolean);
            const attributes = newProd.map((prod) => prod.attribute_type_name).filter(Boolean);
  
            if (sizes.length > 0) {
              this.sizes = new Set(sizes);
            } else {
              this.sizes.clear(); // Clear the sizes set
            }
  
            if (attributes.length > 0) {
              this.attributes = new Set(attributes);
            } else {
              this.attributes.clear(); // Clear the attributes set
            }
          } else {
            // Handle case when no matching product is found for the selected color, size, and attribute
            // Reset values here
            this.selectedColor = null;
            this.products = this.previousColorData;
            this.sizes.clear();
            this.attributes.clear();
          }
        } else {
          // Handle case when no products are found for the selected color
          // Reset values here
          this.selectedColor = null;
          this.products = this.previousColorData;
          this.sizes.clear();
          this.attributes.clear();
        }
      },
  
  
  
      changeSize(key, val) {
        const newProd = this.allProducts.filter((prod) => prod.size_name === val);
  
        if (newProd.length > 0) {
          // Filter based on color, size, and attribute if selected
          const filteredProd = newProd.filter(
            (prod) =>
              (!this.selectedColor || prod.color_name === this.selectedColor) &&
              (!this.selectedAttribute || prod.attribute_type_name === this.selectedAttribute)
          );
  
          if (filteredProd.length > 0) {
            this.selectedSize = val;
            this.products = filteredProd[0]; // Update the displayed product based on color, size, and attribute selection
            this.fetchRates(this.products.product_detail_id); // Fetch rates for the selected product detail
            this.fetchAverage(this.products.product_detail_id);
            this.resetDisplayedImage();
            this.multiImageLoad(this.products.product_detail_id);
            this.selectedImage = null;
  
            // Update colors and attributes based on the filtered products
            const colors = newProd.map((prod) => prod.color_name).filter(Boolean);
            const attributes = newProd.map((prod) => prod.attribute_type_name).filter(Boolean);
  
            if (colors.length > 0) {
              this.colors = new Set(colors);
            } else {
              this.colors.clear(); // Clear the colors set
            }
  
            if (attributes.length > 0) {
              this.attributes = new Set(attributes);
            } else {
              this.attributes.clear(); // Clear the attributes set
            }
          } else {
            // Handle case when no matching product is found for the selected color, size, and attribute
            // Reset values here
            this.selectedSize = null;
            this.products = this.previousSizeData;
            this.colors.clear();
            this.attributes.clear();
          }
        } else {
          // Handle case when no products are found for the selected size
          // Reset values here
          this.selectedSize = null;
          this.products = this.previousSizeData;
          this.colors.clear();
          this.attributes.clear();
        }
      },
  
  
  
      changeAttribute(key, val) {
        const newProd = this.allProducts.filter((prod) => prod.attribute_type_name === val);
  
        if (newProd.length > 0) {
          this.selectedAttribute = val;
          this.products = newProd[0]; // Update the displayed product based on attribute selection
          this.fetchRates(this.products.product_detail_id); // Fetch rates for the selected product detail
          this.fetchAverage(this.products.product_detail_id);
          this.resetDisplayedImage();
          this.multiImageLoad(this.products.product_detail_id);
          this.selectedImage = null;
          const colors = newProd.map((prod) => prod.color_name).filter(Boolean);
          const sizes = newProd.map((prod) => prod.size_name).filter(Boolean);
  
          if (colors.length > 0) {
            this.colors = new Set(colors);
          } else {
            this.colors.clear(); // Clear the colors set
          }
  
          if (sizes.length > 0) {
            this.sizes = new Set(sizes);
          } else {
            this.sizes.clear(); // Clear the sizes set
          }
        } else {
          this.selectedAttribute = null;
          this.products = this.previousAttributeData;
          this.colors.clear();
          this.sizes.clear();
        }
      },
  
  
      showMoreAttributes() {
        this.showAllAttributes = !this.showAllAttributes;
      },
  
      showMoreColors() {
        this.showAllColors = !this.showAllColors;
      },
  
      showMoreSizes() {
        this.showAllSizes = !this.showAllSizes;
      },
  
      multiImageLoad(productDetailId) {
        var page = `api/productdetail-view/${this.$route.params.id}`;
        axiosInstance.get(page).then(({ data }) => {
          // Filter the multi-images based on the product detail ID
          const multiImages = data.product
            .filter(product => product.product_detail_id === productDetailId)
            .map(product => product.multi_image)
            .filter(image => image !== null);
  
          // Assign the filtered multi-images to the corresponding product
          const productIndex = this.allProducts.findIndex(product => product.product_detail_id === productDetailId);
          if (productIndex !== -1) {
            this.allProducts[productIndex].multi_image = multiImages;
          }
        });
      },
  
      getCart() {
        var page = `api/get-cart-count?user_id=${this.user?.id}`;
        axiosInstance.get(page).then(({ data }) => {
          this.getcart = data.total;
        })
      },
  
      dataLoad() {
        var page = `api/super-admin-disabled-product-detail/${this.$route.params.id}`;
        axiosInstance.get(page).then(({ data }) => {
  
          console.log(data); // Log the data to the console for inspection
  
          if (data.product.length > 0) {
            this.products = data.product[0];
            this.productnames = data.productname;
            this.allProducts = data.product;
            // this.related = data.relatedProducts;
            var pd = data.product;
            this.resetDisplayedImage();
  
            this.multiImageLoad();
  
            let color = [];
            pd.forEach(function (val) {
              if (val.color_name) color.push(val.color_name);
            });
            this.colors = new Set(color);
  
            let attribute = [];
            pd.forEach(function (val) {
              if (val.attribute_type_name) attribute.push(val.attribute_type_name);
            });
            this.attributes = new Set(attribute);
  
            let size = [];
            pd.forEach(function (val) {
              if (val.size_name) size.push(val.size_name);
            });
            this.sizes = new Set(size);
  
            // Check if products.product_detail_id is available before fetching rates
            if (this.products && this.products.product_detail_id) {
              this.fetchRates(this.products.product_detail_id);
  
            } else {
              // Handle the case where product_detail_id is not available
              console.error();
            }
  
            if (this.products && this.products.product_detail_id) {
              this.fetchAverage(this.products.product_detail_id);
            } else {
              // Handle the case where product_detail_id is not available
              console.error();
            }
  
            if (this.products && this.products.product_detail_id) {
              this.multiImageLoad(this.products.product_detail_id);
            } else {
              console.error();
            }
  
          } else {
            // Handle the case where no product data is available
            console.error();
          }
        });
      },
  
      save() {
        this.saveData();
      },
  
      point() {
        this.savePoint();
      },
  
      rate() {
        this.saveRate();
      },
  
      addFavorite() {
        this.favoritedetail();
      },
  
      favoritedetail() {
        let formData = new FormData();
        formData.append("user_id", this.user?.id);
        formData.append("product_detail_id", this.products.product_detail_id);
  
        axiosInstance.post(`api/check-product-favorite`, formData)  // Change the API endpoint to your actual endpoint
          .then((response) => {
            if (response.data.isFavorite) {
              // Product is already in favorites, update the state
              this.productInFavorites = true;
              // Optionally, you can show a message indicating that the product is already in favorites
              this.message = "Product is already in favorites.";
              this.isError = false; // Reset isError to false (no error)
              this.showSuccessMessage = true; // Show the toast message
              this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
            } else {
              // Product is not in favorites, proceed with adding
              axiosInstance.post(`api/product-detail-favorite-store`, formData)
                .then((response) => {
                  if (response.status === 200) {
                    this.showSuccessMessage = true;
                    this.message = "Product has been added to favorite successfully"; // Update success message
                    this.isError = false; // Reset isError to false (no error)
                  } else {
                    this.message = "Failed to add to favorite"; // Update error message
                    this.isError = true; // Set isError to true (error)
                    this.showSuccessMessage = true; // Show the toast message
                  }
                  this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
                })
                .catch((error) => {
                  this.message = "Failed to add to favorite"; // Update error message
                  this.isError = true; // Set isError to true (error)
                  this.showSuccessMessage = true; // Show the toast message
                  console.error("Failed to add to favorite", error);
                  this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
                });
            }
          })
          .catch((error) => {
            console.error("Failed to check product favorite status", error);
          });
      },
  
      saveData() {
        let formData = new FormData();
        formData.append("quantity", this.cart.quantity);
        formData.append("sale_price", this.products.sale_price);
        formData.append("brand_id", this.products.brand_id);
        formData.append("product_detail_id", this.products.product_detail_id); // set product_detail_id from products.id
        formData.append("user_id", this.user?.id);
        axiosInstance
          .post(`api/cart-store`, formData)
          .then((response) => {
            if (response.status === 200) {
              this.showSuccessMessage = true;
              this.message = "Product has been added to cart successfully"; // Update success message
              this.isError = false; // Reset isError to false (no error)
            } else {
              this.message = "Failed to add to cart"; // Update error message
              this.isError = true; // Set isError to true (error)
              this.showSuccessMessage = true; // Show the toast message
  
            }
            this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
            this.getCart();
          })
          .catch((error) => {
            this.message = "Failed to add to cart"; // Update error message
            this.isError = true; // Set isError to true (error)
            this.showSuccessMessage = true; // Show the toast message
            console.error("Failed to add to cart", error);
            this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
          });
      },
  
      savePoint() {
        let formData = new FormData();
        formData.append("sale_price", 0);
        formData.append("quantity", this.cart.quantity);
        formData.append("brand_id", this.products.brand_id);
        formData.append("product_detail_id", this.products.product_detail_id); // set product_detail_id from products.id
        formData.append("user_id", this.user?.id);
        axiosInstance
          .post(`api/cart-point-store`, formData)
          .then((response) => {
            if (response.status === 200) {
              this.showSuccessMessage = true;
              this.message = "Product has been added to cart successfully"; // Update success message
              this.isError = false; // Reset isError to false (no error)
            } else {
              this.message = "Failed to add to cart"; // Update error message
              this.isError = true; // Set isError to true (error)
              this.showSuccessMessage = true; // Show the toast message
  
            }
            this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
          })
          .catch((error) => {
            this.message = "Failed to add to cart"; // Update error message
            this.isError = true; // Set isError to true (error)
            this.showSuccessMessage = true; // Show the toast message
            console.error("Failed to add to cart", error);
            this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
          });
      },
  
      saveRate() {
        let formData = new FormData();
        formData.append("rating", this.rating.rating);
        formData.append("feedback", this.rating.feedback);
        formData.append("product_detail_id", this.products.product_detail_id); // set product_detail_id from products.id
        formData.append("user_id", this.user?.id);
  
        axiosInstance
          .post(`api/rate-store`, formData)
          .then((response) => {
            if (response.status === 200) {
              this.showSuccessMessage = true;
              this.message = "Rate has been added successfully"; // Update success message
              this.isError = false; // Reset isError to false (no error)
            } else {
              if (response.data === 'You have already rated this product_detail_id') {
                this.message = 'You have already rated this product_detail_id'; // Display the "already rated" message
              } else {
                this.message = "Failed to add to rate"; // Update error message
              }
              this.isError = true; // Set isError to true (error)
              this.showSuccessMessage = true; // Show the toast message
            }
            this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
          })
          .catch((error) => {
            this.message = "Failed to add to rate"; // Update error message
            this.isError = true; // Set isError to true (error)
            this.showSuccessMessage = true; // Show the toast message
            console.error("Failed to add to rate", error);
            this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
          });
      },
  
      hideMessageAfterDelay(delay) {
        setTimeout(() => {
          this.showSuccessMessage = false; // Hide the toast message after the specified delay
        }, delay);
      },
    },
  
    mounted() {
      // const iframe = this.$refs.youtubeVideo;
      // iframe.src += (iframe.src.includes('?') ? '&' : '?') + 'autoplay=1';
      // Simulate fetching data (replace with your actual data fetching logic)
      setTimeout(() => {
        this.isLoading = false; // Data has been loaded
      }, 2000); // Simulated delay
  
    },
  
  };
  </script>
  
  <style scoped>
  @media (min-width: 1366px) {
    .temp {
      width: 96%;
      max-width: 1500px !important;
      overflow-x: hidden !important;
    }
  }
  
  @media (max-width: 767px) {
    .temp {
      overflow-x: hidden !important;
    }
  }
  
  .star-rating {
    margin: 10px;
    display: flex;
    flex-direction: row-reverse;
    /* Display stars from right to left */
    font-size: 0;
    /* Prevent inline-block spacing */
    align-items: center;
  }
  
  .star-rating input {
    display: none;
  }
  
  .star-rating label {
    font-size: 24px !important;
    /* Adjust as needed */
    color: #ccc;
    cursor: pointer;
    margin: 0 2px;
  }
  
  .star-rating label:before {
    content: '\2605';
    /* Unicode character for star outline */
    display: block;
  }
  
  .star-rating input:checked~label,
  .star-rating input:checked~label~label {
    color: #2e73bb;
    /* Color for filled star */
  }
  
  .feedback-container {
    margin-top: 10px;
  }
  
  .feedback-label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .feedback-input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  template {
    padding: 0 !important;
    margin: 0 !important;
  }
  
  .alert-danger {
    background-color: #dc143c !important;
    color: white !important;
  }
  
  /* Custom radio button style */
  .custom-radio {
    display: inline-block !important;
    /* Set the container element to inline-block */
  }
  
  .custom-radio .custom-control-input {
    display: none !important;
    /* Hide the default radio button */
  }
  
  .custom-radio .custom-control-label {
    display: inline-block !important;
    padding: 10px !important;
    border: 1px solid #ccc !important;
    margin: 5px !important;
    cursor: pointer !important;
    /* Add your custom styles here */
  }
  
  .custom-radio .custom-control-input:checked~.custom-control-label {
    background-color: #ccc !important;
    /* Add your custom styles here */
  }
  
  /* Additional styles to display product options inline */
  .product-options {
    display: inline-block !important;
  }
  
  .info-box {
    display: inline-block !important;
    margin-right: 20px !important;
  }
  
  .info-box:last-child {
    margin-right: 0 !important;
  }
  
  .toast {
    position: fixed !important;
    max-width: 50% !important;
    top: 20px !important;
    right: -100% !important;
    /* Start offscreen on the right */
    background-color: #28a745 !important;
    color: #fff !important;
    padding: 12px 16px !important;
    border-radius: 4px !important;
    transition: right 0.5s ease-in-out !important;
    z-index: 999999 !important;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .toast.error {
    position: fixed !important;
    max-width: 50% !important;
    top: 20px !important;
    right: -100% !important;
    /* Start offscreen on the right */
    background-color: #dc3545 !important;
    /* Background color for error */
    color: #fff !important;
    padding: 12px 16px !important;
    border-radius: 4px !important;
    transition: right 0.5s ease-in-out !important;
    z-index: 999999 !important;
  }
  
  .toast.show {
    right: 20px !important;
    /* Slide in to the desired position */
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
  
    90% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  }
  
  .loading-indicator {
    display: flex;
    align-items: center;
  }
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #000;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  .btn-heart {
    background: #2e73bb;
    position: absolute;
    overflow: hidden;
    color: white;
    border: 0;
    width: 80px;
    height: 80px;
    cursor: pointer;
    z-index: 9;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
  }
  
  .btn-buys {
    background: #2e73bb;
    position: absolute;
    overflow: hidden;
    color: white;
    border: 0;
    width: 80px;
    height: 80px;
    cursor: pointer;
    z-index: 9;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
  }
  
  .fa-shopping-cart {
    font-size: 12px !important;
  }
  
  .fa-heart {
    font-size: 12px !important;
  }
  
  .addToFavorite {
    position: absolute;
    right: 11%;
  }
  
  .zoom-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .zoomed-in-image {
    position: absolute;
    overflow: hidden;
    right: 1%;
    z-index: 9999999999;
  }
  
  
  @media (max-width: 768px) {
    .tab-content {
      width: 100% !important;
      /* Allow content to take up the full width of the viewport */
    }
  
    .multi-image{
      display: flex;
      overflow-x: auto;
    }
  }
  </style>
  