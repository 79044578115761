export function downloadAsTxt(cartData) {
    const firstCart = cartData[0]; // Get the first cart object
    const note = firstCart.note; // Extract the note from the first cart

    let firstCartSalePrice = firstCart.sale_price; // Separate variable for the first cart

    if (firstCart.is_simple_discount === 1) {
        // Apply the discount if is_simple_discount is 1
        firstCartSalePrice -= (firstCart.sale_price * firstCart.user_simple_discount / 100);
    } else if (firstCart.is_sale === 1) {
        firstCartSalePrice -= (firstCart.sale_price * firstCart.cart_discount / 100);
    }

    const txtContent = [
        `Invoice Note: ${note}`,
        `${firstCart.sku},${firstCart.quantity},${firstCartSalePrice}`
    ];

    // Iterate over the rest of the carts (starting from the second one)
    for (let i = 1; i < cartData.length; i++) {
        const cart = cartData[i];
        let salePrice = cart.sale_price; // Separate variable for each cart

        if (cart.is_simple_discount === 1) {
            // Apply the discount if is_simple_discount is 1
            salePrice -= (cart.sale_price * cart.user_simple_discount / 100);
        } else if (cart.is_sale === 1) {
            salePrice -= (cart.sale_price * cart.cart_discount / 100);
        }

        txtContent.push(
            `${cart.sku},${cart.quantity},${salePrice}`
        );
    }

    const element = document.createElement("a");
    const file = new Blob([txtContent.join("\n")], {type: "text/plain"});
    element.href = URL.createObjectURL(file);
    element.download = "cart_data.txt";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}
