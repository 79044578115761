<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h3 class="mb-5">Product Configurations Panel</h3>

          <div v-if="successMessage" class="alert alert-success" role="alert">
            {{ successMessage }}
          </div>
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>

          <select v-model="selectedBrand" @change="loadProductsByBrand">
            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
              {{ brand.brand_name }}
            </option>
          </select>
          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Product Title</th>
              <th>Product Sku</th>
              <th>Product Color</th>
              <th>Product Size</th>
              <th>Product Attribute Name</th>
              <th>Product Quantity</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="productconf in productconfs" :key="productconf.id">
              <th scope="row">{{ productconf.id }}</th>
              <td>{{ productconf?.title }}</td>
              <td>{{ productconf?.sku }}</td>
              <td>{{ productconf.color_name ? productconf.color_name : '' }}</td>
              <td>{{ productconf.size_name ? productconf.size_name : '' }}</td>
              <td>{{ productconf.attribute_type_name ? productconf.attribute_type_name : '' }}</td>
              <td>
                <input type="number" v-model="productconf.quantity">
              </td>
              <td>
                <button @click="updateQuantity(productconf)" class="btn btn-success">Submit</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
  data() {
    return {
      productconfs: [],
      selectedBrand: null,
      brands: [],
      successMessage: '',
      errorMessage: ''
    };
  },
  created() {
    this.loadProductConfigs();
  },
  methods: {
    loadProductsByBrand() {
      if (this.selectedBrand) {
        this.loadProductConfigs(this.selectedBrand);
      }
    },
    loadProductConfigs(brandId) {
      var page = `api/product-conf/${brandId}`;
      axiosInstance.get(page).then(({ data }) => {
        console.log(data);
        this.productconfs = data.productconf;
        this.brands = data.brands;
      });
    },
    updateQuantity(productconf) {
      axiosInstance.put(`api/update-product-conf/${productconf.id}`, { quantity: productconf.quantity })
          .then(({ data }) => {
            console.log(data);
            this.successMessage = 'Quantity updated successfully!';
            this.clearMessageAfterTimeout('successMessage');
          })
          .catch(error => {
            console.log(error);
            this.errorMessage = 'Error updating quantity!';
            this.clearMessageAfterTimeout('errorMessage');
          });
    },
    clearMessageAfterTimeout(messageType) {
      setTimeout(() => {
        this[messageType] = '';
      }, 5000);
    }
  },
};
</script>
