<template>
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <!-- Open Graph Metadata -->

    <title>Gocami</title>

  </head>

  <body>

    <router-view />

  </body>
</template>
    
<script>
export default {
  data() {
    return {
      loginSuccess: false, // Control the visibility of the success message

    };
  },

  beforeRouteEnter(to, from, next) {
    if (to.query.loginSuccess) {
      next(vm => {
        vm.showSuccessMessage = true;
        setTimeout(() => {
          vm.showSuccessMessage = false;
        }, 5000);
      });
    } else {
      next();
    }
  },
}
</script>

<style>

* {
  font-size: 10pt !important;
  font-weight: 600 !important;
}

sup {
  font-size: 10pt !important;
}
</style>
