<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <!-- Success Message -->
          <div v-if="successMessage" class="alert alert-success">
            {{ successMessage }}
          </div>

          <select v-model="selectedBrand" @change="loadProductsByBrand">
            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
              {{ brand.brand_name }}
            </option>
          </select>

          <h3 class="mb-5">Product Detail</h3>

          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Sku</th>
              <th>Title</th>
              <th>Sale Price</th>
              <th>Image</th>
              <th>Quantity</th>
              <th>Main Price</th>
              <th>Update Price</th>
              <th>Cost Display</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in productDetail" :key="product.id">
              <td>{{ product.id }}</td>
              <td v-if="product.sku">{{ product.sku }}</td>
              <td v-else>-</td>
              <td v-if="product.title">{{ product.title }}</td>
              <td v-else>-</td>
              <td v-if="product.sale_price">${{ product.sale_price }}</td>
              <td v-else>-</td>
              <td v-if="product.image">
                <img :src="'https://api.gocami.com/product_detail/' + product.image" width="75" />
              </td>
              <td v-else>-</td>
              <td v-if="product.quantity > 0">{{ product.quantity }}</td>
              <td v-else>Out Of stock</td>
              <td>
                <input
                    type="number"
                    v-model="product.main_price"
                    @blur="updateMainPrice(product)"
                    class="form-control"
                />
              </td>
              <td>
                <button class="btn btn-success" @click="updateMainPrice(product)">Update</button>
              </td>
              <!-- Switch for Cost Display -->
              <td>
                <label class="switch">
                  <input
                      type="checkbox"
                      v-model="product.cost_display"
                      @change="toggleCostDisplay(product)"
                      :checked="product.cost_display === 1"
                  />
                  <span class="slider round"></span>
                </label>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
  data() {
    return {
      selectedBrand: null,
      productDetail: [],
      brands: [],
      successMessage: '', // For storing the success message
    };
  },

  created() {
    this.fetchAllData();
  },

  methods: {
    loadProductsByBrand() {
      if (this.selectedBrand) {
        this.fetchAllData(this.selectedBrand);
      }
    },

    fetchAllData(brandId) {
      return axiosInstance
          .get(`api/target-change/${brandId}`)
          .then((response) => {
            this.productDetail = response.data.productDetail.map((product) => {
              // Ensure cost_display is either true or false based on its value
              product.cost_display = product.cost_display === 1;
              return product;
            });
            this.brands = response.data.brands;
          })
          .catch((error) => {
            console.error('Error fetching main data:', error);
          });
    },

    toggleCostDisplay(product) {
      const newValue = product.cost_display ? 1 : 0; // Convert to 1 or 0 for the backend

      axiosInstance
          .put(`api/product/${product.id}/toggle-cost-display`, {
            cost_display: newValue,
          })
          .then((response) => {
            console.log('Cost display updated successfully:', response.data);
          })
          .catch((error) => {
            console.error('Error updating cost display:', error);
          });
    },

    updateMainPrice(product) {
      axiosInstance
          .put(`api/product/${product.id}/update-main-price`, {
            main_price: product.main_price,
          })
          .then(() => {
            // Show the success message
            this.successMessage = 'Main price updated successfully.';

            // Clear the success message after 3 seconds
            setTimeout(() => {
              this.successMessage = '';
            }, 3000);
          })
          .catch((error) => {
            console.error('Error updating main price:', error);
          });
    },
  },
};
</script>


<style scoped>
/* Custom toggle switch styling */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 25px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 2.5px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(24px);
}

.slider.round {
  border-radius: 25px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>