<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Orders Panel</h3>

          <!-- Search input for order ID -->
          <div class="mb-3">
            <input v-model="searchId" @input="searchOrders" type="text" class="form-control" placeholder="Search by Order ID">
          </div>

          <table class="responsive table-responsive table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>User Name</th>
              <th>Note</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <!-- Display filtered orders -->
            <tr v-for="order in allOrder.data" :key="order.id">
              <th scope="row">{{ order.id }}</th>
              <td>{{ order.first_name }} {{ order.middle_name }} {{ order.last_name }}</td>
              <td>{{ order.note }}</td>
              <td>{{ new Date(order.created_at).toLocaleString() }}</td>
              <td style="display: flex;">
                <router-link :to="{ name: 'ViewOrder', params: { id: order.id } }" class="btn btn-success me-2">
                  <i class="fa fa-eye"></i>
                </router-link>
                <router-link :to="{ name: 'ExportInvoice', params: { id: order.id } }" class="btn btn-primary me-2">
                  Invoice
                </router-link>
                <span v-if="order.is_printed === 1" class="badge bg-success">Printed!</span>
                <span v-else class="badge bg-danger">Print It!</span>
                <span v-if="order.action === 2" class="badge bg-warning ms-5 text-white">Returned</span>
                <span v-else class="ms-5">-</span>
              </td>
            </tr>
            </tbody>
          </table>

          <!-- Pagination -->
          <div class="pagination">
            <button @click="orderLoad(allOrder.current_page - 1)" :disabled="allOrder.current_page === 1" class="btn btn-primary">Previous</button>
            <button @click="orderLoad(allOrder.current_page + 1)" :disabled="allOrder.current_page === allOrder.last_page" class="btn btn-primary">Next</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';
import { debounce } from 'lodash';

export default {
  name: "AllOrder",

  data() {
    return {
      allOrder: {
        data: [],  // Array to store the orders
        current_page: 1,  // Current page number
        last_page: 1,  // Total number of pages
      },
      searchId: '',  // Search input for filtering by order ID
    };
  },

  created() {
    this.orderLoad();
  },

  methods: {
    orderLoad(page = 1) {
      let endpoint = `api/all-orders?page=${page}`;

      // Add search filter to the API if searchId is not empty
      if (this.searchId) {
        endpoint += `&searchId=${this.searchId}`;
      }

      axiosInstance.get(endpoint).then(({ data }) => {
        this.allOrder.data = data.data;  // Update the orders array
        this.allOrder.current_page = data.current_page;  // Update the current page
        this.allOrder.last_page = data.last_page;  // Update the last page
      });
    },

    // Debounce search input to limit the number of API requests
    searchOrders: debounce(function () {
      this.orderLoad(1);  // Reset to page 1 when searching
    }, 300),  // Debounce by 300ms
  },
};
</script>
