<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <table class="table w-100" ref="table">
            <thead>
            <tr>
              <th>Affiliate ID</th>
              <th>Affiliate Name</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="affiliate in carts" :key="affiliate.id">
              <td>{{affiliate.id}}</td>
              <td>
                <router-link :to="{ name: 'SuperAdminSubAffiliatePercent', params: { id: affiliate.id } }">
                  {{ affiliate.first_name }} {{ affiliate.last_name }}
                </router-link>
              </td>

            </tr>
            </tbody>
          </table>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance.js';
import {useUserStore} from "../../../store";

export default {
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },

  data() {
    return {
      carts: [],
      filterFrom: null,
      filterTo: null,
    }
  },

  methods: {
    userLoad() {
      return axiosInstance
          .get(`api/super-admin-affiliate-chart`)
          .then(response => {
            this.carts = response.data;
            console.log(this.carts);
          })
          .catch(error => {
            console.error('Error fetching main data:', error);
          });
    },


  },


  created() {
    this.userLoad();
  },
};
</script>
