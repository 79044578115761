<template>
    <div class="main-panel">
        <div class="content-wrapper">

            <div class="row">
                <div class="col-12">
                    <h5>Filter by Date:</h5>
                    <div class="form-row">
                        <div class="col">
                            <input type="date" class="form-control" v-model="fromDate">
                        </div>
                        <div class="col">
                            <input type="date" class="form-control" v-model="toDate">
                        </div>
                        <div class="col">
                            <button class="btn btn-primary" @click="applyDateFilter">Apply Filter</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mx-4 my-5">

                <div class="col-6">
                    <h5>Filter by Invoice Status:</h5>
                    <select v-model="invoiceStatus" class="form-control">
                        <option value="">All</option>
                        <option value="0">Pending Order</option>
                        <option value="1">Invoice Under Preparation</option>
                        <option value="2">Invoice is Closed</option>
                        <option value="3">Shipped</option>
                    </select>
                </div>

                <div class="col-6">
                    <h5>Filter by Printed Status:</h5>
                    <select v-model="isPrinted" class="form-control">
                        <option value="">All</option>
                        <option value="1">Printed</option>
                        <option value="0">Not Printed</option>
                    </select>
                </div>

                <div class="col-6">
                    <h5>Filter by Exported Status:</h5>
                    <select v-model="isExported" class="form-control">
                        <option value="">All</option>
                        <option value="1">Exported</option>
                        <option value="0">Not Exported</option>
                    </select>
                </div>

                <div class="col-6">
                    <h5>Filter by Completed Status:</h5>
                    <select v-model="isCompleted" class="form-control">
                        <option value="">All</option>
                        <option value="1">Completed</option>
                        <option value="0">Not Completed</option>
                    </select>
                </div>

                <div class="col-6">
                    <h5>Filter by Exceptioned Status:</h5>
                    <select v-model="isException" class="form-control">
                        <option value="">All</option>
                        <option value="1">Exceptioned</option>
                        <option value="0">Not Exceptioned</option>
                    </select>
                </div>

                <div class="col-6">
                    <h5>Filter by Scanned Status:</h5>
                    <select v-model="isScanned" class="form-control">
                        <option value="">All</option>
                        <option value="1">Scanned</option>
                        <option value="0">Not Scanned</option>
                    </select>
                </div>

            </div>

            <div class="mx-5">
                <h4>Total: {{ allProductDetails.invoices.total }}</h4>
                <h4>Displayed Count: {{ displayedCount }}</h4>
                <h4>Printed Count: {{ allProductDetails.printedCount }}</h4> <!-- Display Printed Count -->
            </div>

            <div class="card">
                <div class="card-body">
                    <h3 class="mb-5">Orders</h3>
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-responsive w-100">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Full Name</th>
                                        <th>Printed order(Rasha)</th>
                                        <th>Exported(Rasha)</th>
                                        <th>Exception</th>
                                        <th>Complete Product Scan</th>
                                        <th>Invoice Status</th>
                                        <th>Invoice Scan</th>
                                        <th>Created</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="product in allProductDetails.invoices.data" :key="product.id">
                                        <th scope="row">{{ product.id }}</th>
                                        <td>{{ product.first_name }} {{ product.middle_name }} {{ product.last_name }}</td>
                                        <td :style="{ color: product.is_printed === 1 ? 'green' : 'red' }">{{
                                            product.is_printed === 1 ? 'printed' : 'not printed' }}</td>
                                        <td :style="{ color: product.is_exported === 1 ? 'green' : 'red' }">{{
                                            product.is_exported === 1 ? 'exported' : 'not exported' }}</td>
                                        <td :style="{ color: product.is_exception === 1 ? 'red' : 'green' }">{{
                                            product.is_exception === 1 ? 'Invoice Exception' : 'No exception' }}
                                        </td>
                                        <td :style="{ color: product.complete_product === 1 ? 'green' : 'red' }">{{
                                            product.complete_product === 1 ? 'Products readed' : 'Missing product' }}</td>
                                        <td v-if="product.status == 0" class="bg-danger">Pending Order</td>
                                        <td v-else-if="product.status == 1" class="bg-primary">Invoice Under Preperation
                                        </td>
                                        <td v-else-if="product.status == 2" class="bg-success">Invoice is Closed</td>
                                        <td v-else>Shipped</td>
                                        <td :style="{ color: product.is_scanned === 1 ? 'green' : 'red' }">
                                            {{ product.is_scanned === 1 ? 'Invoice Scanned' : 'Invoice Not Scanned' }}
                                        </td>
                                        <td>{{ new Date(product.created_at).toLocaleString() }}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="row d-flex m-auto">
                                <div class="col-12 d-flex">
                                    <div class="pagination-container">
                                        <nav aria-label="Page navigation">
                                            <ul class="pagination justify-content-center">
                                                <li class="page-item"
                                                    :class="{ disabled: !allProductDetails.invoices.prev_page_url }">
                                                    <button class="page-link btn-custom"
                                                        @click="changePage(allProductDetails.invoices.current_page - 1)"
                                                        :disabled="!allProductDetails.invoices.prev_page_url" aria-label="Previous">
                                                        <span aria-hidden="true">&laquo;</span>
                                                    </button>
                                                </li>
                                                <li v-for="page in displayedPages" :key="page"
                                                    :class="{ active: page === allProductDetails.invoices.current_page }">
                                                    <button class="page-link btn-custom" @click="changePage(page)">{{ page
                                                    }}</button>
                                                </li>
                                                <li class="page-item"
                                                    :class="{ disabled: !allProductDetails.invoices.next_page_url }">
                                                    <button class="page-link btn-custom"
                                                        @click="changePage(allProductDetails.invoices.current_page + 1)"
                                                        :disabled="!allProductDetails.invoices.next_page_url" aria-label="Next">
                                                        <span aria-hidden="true">&raquo;</span>
                                                    </button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
    data() {
        return {
            allProductDetails: {
                invoices: {
                    data: [],
                    current_page: 1,
                    last_page: 1,
                    total: 0,
                    prev_page_url: null,
                    next_page_url: null,
                },
                printedCount: 0
            },
            currentPage: 1,
            fromDate: null,
            toDate: null,
            invoiceStatus: '', // Added variable for invoice status filter
            isPrinted: '', // Added variable for is_printed filter
            isExported: '', // Added variable for is_printed filter
            isCompleted: '', // Added variable for is_printed filter
            isException: '', // Added variable for is_printed filter
            isScanned: '', // Added variable for is_printed filter
        };
    },

    created() {
        this.userLoad();
    },

    computed: {
        displayedPages() {
            const total = this.allProductDetails.invoices.last_page;
            const current = this.allProductDetails.invoices.current_page;
            const pages = [];

            for (let i = Math.max(current - 2, 1); i <= Math.min(current + 2, total); i++) {
                pages.push(i);
            }

            return pages;
        },
        displayedCount() {
            return this.allProductDetails.invoices.data.length;
        },
    },
    methods: {
        applyDateFilter() {
            this.currentPage = 1;
            this.userLoad();
        },

        userLoad() {
            let nextPage = `api/super-admin-orders?page=${this.currentPage}`;

            // Add from_date and to_date to the request if they are provided
            if (this.fromDate) {
                nextPage += `&from_date=${this.fromDate}`;
            }

            if (this.toDate) {
                nextPage += `&to_date=${this.toDate}`;
            }

            // Add invoiceStatus and isPrinted filters to the request if they are provided
            if (this.invoiceStatus) {
                nextPage += `&invoice_status=${this.invoiceStatus}`;
            }

            if (this.isPrinted !== '') {
                nextPage += `&is_printed=${this.isPrinted}`;
            }

            if (this.isExported !== '') {
                nextPage += `&is_exported=${this.isExported}`;
            }

            if (this.isCompleted !== '') {
                nextPage += `&is_complete=${this.isCompleted}`;
            }

            if (this.isException !== '') {
                nextPage += `&is_exception=${this.isException}`;
            }

            if (this.isScanned !== '') {
                nextPage += `&is_scanned=${this.isScanned}`;
            }

            axiosInstance.get(nextPage).then(({ data }) => {
                this.allProductDetails = data;
            });
        },

        changePage(page) {
            this.currentPage = page;
            this.userLoad();
        },
    },
}
</script>
  
<style scoped>
* {
    font-size: 12pt !important;
}

.pagination-container {
    margin-top: 20px;
}

.pagination {
    margin: 0;
}

.btn-custom {
    cursor: pointer;
    background-color: #4caf50;
    /* Green */
    color: white;
    border: 1px solid #4caf50;
    /* Green */
}

.btn-custom:hover {
    background-color: #45a049;
    /* Darker green */
    border: 1px solid #45a049;
    /* Darker green */
}

.page-item.disabled .page-link {
    cursor: not-allowed;
    background-color: #ddd;
    color: #888;
    border: 1px solid #ddd;
}

.page-item.active .page-link {
    background-color: #2196f3;
    /* Blue */
    border: 1px solid #2196f3;
    /* Blue */
    color: white;
}
</style>
  