<template>
  <div class="container">
    <div v-for="(bazar, index) in bazars" :key="bazar.sale_id">
      <template v-if="countdowns[index] != null">
        <div class="sale-item">
          <div class="image-container">
            <img :src="'https://api.gocami.com/sale/' + bazar.sale_image" alt="">
            <div class="countdown-container">
              <div class="countdown">
                <span class="countdown-label">Sale Ends In:</span>
                <span class="countdown-timer" style="color:red; padding-left: 5px;font-weight: 600;">{{
                    countdowns[index]
                  }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!--            <div class="arrow-buttons">-->
      <!--                <button @click="prevProduct(bazar)" class="arrow-button">◀</button>-->
      <!--                <button @click="nextProduct(bazar)" class="arrow-button">▶</button>-->
      <!--            </div>-->
      <div class="row row-clean" style="display: flex; justify-content: center; align-items: center;">
        <div v-for="product in bazar.products" :key="product.product_detail_id"
             class="col-xs-6 col-sm-4 col-lg-2">
          <!--                    v-show="index >= bazar.currentProductIndex && index < bazar.currentProductIndex + 6">-->
          <section class="products" style="margin-top: 2%; margin-bottom: 3%">
            <!-- Display product details here -->
            <div class="product-item">
              <article style="margin:5px">
                <div class="figure-grid">
                  <div class="image">
                    <router-link :to="{ name: 'ProductDetail', params: { id: product.productId } }"
                                 class="mfp-open">
                      <img :src="'https://api.gocami.com/product_detail/' + product.product_image"
                           alt="" width="360" class="product-image pt-5"/>
                    </router-link>
                  </div>
                  <div class="text">
                    <h2 class="title h5">
                      <router-link :to="{ name: 'ProductDetail', params: { id: product.productId } }">
                        {{ product.product_title }}
                      </router-link>
                    </h2>
                    <sup class="text-success">${{
                        (product.product_sale_price - (product.product_sale_price *
                            product.product_discount / 100)).toFixed(2)
                      }}</sup>-
                    <del>$ {{
                        product.product_sale_price.toFixed(2)
                      }}
                    </del>
                    <br/>
                    <span class="badge bg-danger">% {{ parseFloat(product.product_discount) }}</span>
                    <sup style="color:red" v-if="product.saleQuantity < 0">Quantity left: 0</sup>
                    <sup style="color:red" v-else>Quantity left: {{ product.saleQuantity }}</sup>
                  </div>
                </div>
              </article>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';
import moment from 'moment';
import 'moment-timezone';

export default {
  data() {
    return {
      bazars: [],
      countdowns: [],
      countdownIntervals: {},
    }
  },

  methods: {
    fetchSlider() {
      var page = `api/bazar-view`;
      axiosInstance.get(page).then(({data}) => {
        // Convert object properties to an array
        this.bazars = Object.values(data);
        // Initialize currentProductIndex for each bazar
        this.bazars.forEach(bazar => {
          bazar.currentProductIndex = 0;
        });
      });
    },
    prevProduct(bazar) {
      bazar.currentProductIndex = Math.max(bazar.currentProductIndex - 6, 0);
    },
    nextProduct(bazar) {
      bazar.currentProductIndex = Math.min(bazar.currentProductIndex + 6, bazar.products.length - 1);
    },

    updateCountdowns() {
      const date = moment().tz('Asia/Beirut');
      const now = new Date(date);
      this.countdowns = this.bazars.map((data) => {
        const distance = new Date(data.countdown_time).getTime() - now;
        if (distance < 0) {
          // Target date has passed, return null
          return null;
        }
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
      });
    },

    startCountdowns() {
      setInterval(() => {
        this.updateCountdowns();
      }, 1000);
    },

    startCountdownTimers() {
      this.bazars.forEach(bazar => {
        if (!bazar.countdown_time) {
          return;
        }

        const targetDate = new Date(bazar.countdown_time);
        const interval = setInterval(() => {
          const currentDate = new Date();
          const diff = targetDate - currentDate;

          if (diff <= 0) {
            bazar.countdown = 'Expired';
            clearInterval(interval);
          } else {
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((diff % (1000 * 60)) / 1000);
            bazar.countdown = `${days}d ${hours}h ${minutes}m ${seconds}s`;
          }
        }, 1000);
      });
    },

    getCountdownTime(timeRemaining) {
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

      return `${days} Days, ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
  },
  mounted() {
    this.startCountdowns();
    this.fetchSlider();

    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    // eslint-disable-next-line no-undef
    fbq('init', '1043302017143606');
    // eslint-disable-next-line no-undef
    fbq('track', 'PageView');

  },
  created() {
    this.fetchSlider();
    this.bazars.forEach(bazar => {
      bazar.currentProductIndex = 0;
    });
  }
}
</script>

<style scoped>
img {
  width: 100%;
}

.arrow-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.arrow-button {
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 5px;
}


@keyframes countdownPulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}

.sale-item {
  display: inline-block;
  max-width: 100%;
  margin: 0 auto;
  animation: slideIn 0.5s ease-out;
}

.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  animation: scalePulse 2s infinite;
  transition: transform 0.3s;
}

.image-container:hover {
  animation: none;
  transform: scale(1.05);
}

.sale-image {
  display: block;
  width: 100%;
  height: auto;
  transition: filter 0.3s;
}

.image-container:hover .sale-image {
  filter: brightness(1.2);
}

.countdown-container {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  text-align: center;
  width: 100%;
  /* Adjust as needed */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #fff;
  font-size: 16px;
  transition: background-color 0.3s, opacity 0.3s;
  opacity: 0.9;
  animation: countdownPulse 1.5s infinite;
}

.countdown-container:hover {
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1;
  animation: none;
}

.countdown-label {
  font-weight: bold;
  margin-bottom: 8px;
}

.countdown-timer {
  font-size: 24px;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  animation: countdownPulse 1s infinite alternate;
}

/* Add these changes to your existing styles */
.container {
  /* Set a maximum width for content */
  margin: 0 auto;
  /* Center align content */

  /* Additional styles for mobile responsiveness */
  @media (max-width: 768px) {

    /* Apply these styles for screens up to 768px width */
    .arrow-buttons {
      display: flex;
      justify-content: space-between;
      /* Spread the buttons */
      margin-top: 10px;
    }

    .sale-item {
      max-width: 100%;
      /* Adjust width for mobile screens */
    }

    .image-container {
      border-radius: 5px;
      /* Smaller radius for mobile */
    }

    .countdown-container {
      padding: 10px;
      /* Reduce padding for mobile */
      font-size: 14px;
      /* Smaller font size for mobile */
    }

    .countdown-timer {
      font-size: 18px;
      /* Adjust font size */
    }

    .col-xs-6 {
      width: 50%;
      /* Make columns take half width on mobile */
    }

    /* Additional mobile-specific styles for product details */
    .text {
      padding: 5px;

      /* Add spacing around text */

      h2.title {
        font-size: 14px;
        /* Adjust font size */
      }

      sup {
        font-size: 12px;
        /* Adjust font size */
      }
    }
  }
}
</style>
