<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h3 class="mb-5">Product Detail</h3>
          <select v-model="selectedBrand" @change="loadProductsByBrand">
            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
              {{ brand.brand_name }}
            </option>
          </select>
          <table class="table table-responsive">
            <thead>
            <tr>
              <th>#</th>
              <th>Product Sku</th>
              <th>Product title</th>
              <th>Image</th>
              <th>Dimension</th>
              <th>Bullet Points</th>
              <th>Patch</th>
              <th>Included Item</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in allProductDetails" :key="product.id">
              <th scope="row">{{ product.id }}</th>
              <th>{{ product.sku }}</th>
              <th>{{ product.title }}</th>
              <th><img :src="`https://api.gocami.com/product_detail/` + product.image" width="100"/></th>
              <td>
                <div class="form-group">
                  <!-- Ensure correct data binding to product.dimensions -->
                  <QuillEditor theme="snow" v-model:content="product.dimensions" contentType="html"/>
                </div>
              </td>
              <td>
                <div class="form-group">
                  <!-- Ensure correct data binding to product.dimensions -->
                  <QuillEditor theme="snow" v-model:content="product.bullet_pts" contentType="html"/>
                </div>
              </td>
              <td>
                <input type="text" v-model="product.patch" class="form-control"/>
              </td>
              <td>
                <div class="form-group">
                  <!-- Ensure correct data binding to product.dimensions -->
                  <QuillEditor theme="snow" v-model:content="product.included_items" contentType="html"/>
                </div>
              </td>
              <td>
                <button @click="updateDescription(product)" class="btn btn-primary">
                  Update
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  data() {
    return {
      allProductDetails: [],
      currentPage: 1,
      brands: [],
      selectedBrand: null,
    };
  },

  created() {
    // Load products when component is created
    this.loadProductsByBrand();
  },

  components: {
    QuillEditor
  },

  methods: {
    userLoad(brandId) {
      var nextPage = `api/data-entry/${brandId}`;
      axiosInstance.get(nextPage).then(({ data }) => {
        this.allProductDetails = data.products;
        this.brands = data.brands;
      });
    },

    loadProductsByBrand() {
      if (this.selectedBrand) {
        this.userLoad(this.selectedBrand);
      } else {
        // Handle case where no brand is selected (show all products)
        this.userLoad();
      }
    },
    updateDescription(product) {
      // Send updated product details to the server
      axiosInstance.post(`api/update-product-details-content/${product.id}`, {
        dimensions: product.dimensions,
        bullet_pts: product.bullet_pts,
        patch: product.patch,
        included_items: product.included_items
      })
          .then(() => {
            // Refresh product list after successful update
            this.loadProductsByBrand();
          })
          .catch(error => {
            console.error('Error updating product details:', error);
          });
    },
  },
}
</script>

<style scoped>
/* Add any necessary styling */
</style>
