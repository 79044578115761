<script>
import axiosInstance from "../../../../../axiosInstance";
// import router from "@/router";

export default {
  data() {
    return {
      mainPrice: [],
      selectedBrand: null,
      brands: [],
      successMessage: '', // Initialize success message
    };
  },

  created() {
    this.priceLoad();
  },

  methods: {
    loadProductsByBrand() {
      if (this.selectedBrand) {
        this.priceLoad(this.selectedBrand);
      }
    },

    priceLoad(brandId) {
      var page = `api/get-accounting-stock/${brandId}`;
      axiosInstance.get(page).then(({data}) => {
        console.log(data);
        this.mainPrice = data.mainPrice;
        this.brands = data.brands;
      });
    },

    remove(agents) {
      var url = `api/color-delete/${agents.id}`;
      axiosInstance.delete(url).then(() => {
        const index = this.agent.indexOf(agents);
        this.agent.splice(index, 1);
      });
    },

    updateMainPrice(mainPrice) {
      axiosInstance.put(`api/accounting-update-min-quantity/${mainPrice.id}`, {min_quantity: mainPrice.min_quantity})
          .then(response => {
            console.log(response);
            this.successMessage = 'Main price updated successfully'; // Set success message

            // Hide the success message after 5 seconds
            setTimeout(() => {
              this.successMessage = '';
            }, 5000);

            // Optionally, you can reload the data after successful update
            this.priceLoad(this.selectedBrand);
          })
          .catch(error => {
            console.error(error);
          });
    },
  },
};
</script>

<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div v-if="successMessage" class="success-message">
          {{ successMessage }}
        </div>
        <div class="card-body">
          <select v-model="selectedBrand" @change="loadProductsByBrand">
            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
              {{ brand.brand_name }}
            </option>
          </select>

          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Sku</th>
              <th>Title</th>
              <th>Quantity</th>
              <th>Minimum Quantity</th>
              <th>Action</th> <!-- New column for the action buttons -->
            </tr>
            </thead>
            <tbody>
            <tr v-for="(mainPrice, index) in mainPrice" :key="mainPrice.id">
              <th scope="row">{{ index + 1 }}</th>
              <td><img :src="'https://api.gocami.com/product_detail/'+ mainPrice.image" width="150"/></td>
              <td>{{ mainPrice.sku }}</td>
              <td>{{ mainPrice.title }}</td>
              <td>{{ mainPrice.quantity }}</td>
              <td>
                <input type="number" v-model="mainPrice.min_quantity">
              </td>
              <td>
                <button @click="updateMainPrice(mainPrice)">Submit</button> <!-- New submit button -->
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.success-message {
  position: fixed;
  background-color: #4CAF50; /* Green */
  color: white;
  padding: 15px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
