<script>
import axiosInstance from '../../../axiosInstance';

export default {
  data() {
    return {
      selectedBrand: null,
      productDetail: [],
      product: {
        main_price: '',
        new_price: '',
        total_commission: '',
        affiliate_commission: '',
        sub_affiliate_commission: '',
      },
      brands: [],
      products: [],
      childcategory: [],
      file: null
    };
  },

  created() {
    this.fetchAllData();
  },

  methods: {
    fetchAllData() {
      return axiosInstance
          .get(`api/get-is-new-product-detail`)
          .then(response => {
            this.productDetail = response.data.productDetail;
          })
          .catch(error => {
            console.error('Error fetching main data:', error);
          });
    },

    updateDescription(product) {
      console.log('Updating description for product:', product);

      axiosInstance
          .post(`api/update-is-new-product-detail/${product.id}`, {
            main_price: product.main_price,
            new_price: product.new_price,
            total_commission: product.total_commission,
            affiliate_commission: product.affiliate_commission,
            sub_affiliate_commission: product.sub_affiliate_commission
          })
          .then(response => {
            console.log('Details updated:', response.data);
            this.fetchAllData();
          })
          .catch(error => {
            console.error('Error updating details:', error);
          });
    },

    updateCheckedItems() {
      const checkedProducts = this.productDetail.filter(product => product.checked);
      if (checkedProducts.length === 0) {
        alert("Please select at least one item to update.");
        return;
      }

      checkedProducts.forEach(product => {
        // Update the checked products
        this.updateDescription(product);
      });
    },

    // copyNewPriceToSalePrice() {
    //     const checkedProducts = this.productDetail.filter(product => product.checked);
    //     if (checkedProducts.length === 0) {
    //         alert("Please select at least one item to update.");
    //         return;
    //     }

    //     checkedProducts.forEach(product => {
    //         // Only update if new_price is different from sale_price
    //         if (product.new_price !== product.sale_price) {
    //             // Copy new_price to sale_price for the current product
    //             product.sale_price = product.new_price;
    //             // Update the backend with the new sale_price
    //             axiosInstance.post('api/products/update-sale-price', {
    //                 product_ids: [product.id],
    //                 new_price: product.new_price
    //             })
    //                 .then(response => {
    //                     console.log('Sale price updated successfully for product:', product.id, response);
    //                     // Optionally, you can fetch updated data after successful update
    //                     this.fetchAllData(this.selectedBrand);
    //                 })
    //                 .catch(error => {
    //                     console.error('Error updating sale price for product:', product.id, error);
    //                     // Optionally handle the error, e.g., display an error message
    //                 });
    //         }
    //     });
    // }

  },
}
</script>


<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Product Detail</h3>

          <button @click="updateCheckedItems" class="btn btn-primary mb-3 me-5">
            Update Checked Items
          </button>

          <table class="table w-100">
            <thead class="fixed-header border-bottom">
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Sku</th>
              <th>Title</th>
              <th>Sale Price</th>
              <th>Image</th>
              <th>Weight</th>
              <th>Main Cost</th>
              <th>Sale Price(NEW)</th>
              <th>Total Commission</th>
              <th>Affiliate Commission</th>
              <th>Sub Affiliate Commission</th>
              <th>Select</th> <!-- Added -->
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in productDetail" :key="product.id">
              <td>{{ product.id }}</td>
              <td>{{product.name}}</td>
              <td>{{ product.sku }}</td>
              <td>{{ product.title }}</td>
              <td v-if="product.sale_price">${{ product.sale_price }}</td>
              <td v-else>-</td>
              <td><img :src="'https://api.gocami.com/product_detail/' + product.image" width="75" />
              </td>
              <td v-if="product.weight">{{ product.weight }}</td>
              <td v-else>-</td>
              <td><input type="text" class="form-control" style="width:100px !important" v-model="product.main_price" /></td>
              <td><input type="text" class="form-control" style="width:100px !important" v-model="product.new_price" /></td>
              <td><input type="text" class="form-control" style="width:100px !important" v-model="product.total_commission" /></td>
              <td><input type="text" class="form-control" style="width:100px !important" v-model="product.affiliate_commission" />
              </td>
              <td><input type="text" class="form-control" style="width:100px !important"
                         v-model="product.sub_affiliate_commission" /></td>
              <td><input type="checkbox" class="form-check-input" v-model="product.checked" /></td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>