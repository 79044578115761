<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card shadow-sm border-0">
        <div class="card-body">

          <button @click="exportToCSV" class="btn btn-primary mb-4">Export to CSV</button>


          <div class="row mb-4">
            <div class="col-md-6">
              <label for="from" class="form-label">From:</label>
              <input type="date" id="from" class="form-control" v-model="filterFrom" @change="applyDateFilter"/>
            </div>
            <div class="col-md-6">
              <label for="to" class="form-label">To:</label>
              <input type="date" id="to" class="form-control" v-model="filterTo" @change="applyDateFilter"/>
            </div>
          </div>

          <div class="summary mb-4">
            <h5>Overall Totals <span class="bg-warning p-2">After Refunds</span></h5>

            <table class="table table-striped table-hover text-center justify-content-center">
              <tr class="bg-secondary">
                <th>#</th>
                <th>Loris</th>
                <th>Cosmetic</th>
                <th>ليست وكالتنا</th>
              </tr>
              <tr>
                <th class="bg-success text-start">Before Discount</th>
                <th class="bg-primary">{{
                    (overallTotals.mainBrand2.before_discount - overallTotalsRefund.mainBrand2.before_discount_refund).toFixed(2)
                  }}
                </th>
                <th class="bg-warning">{{
                    (overallTotals.mainBrand1Target1.before_discount - overallTotalsRefund.mainBrand1Target1.before_discount_refund).toFixed(2)
                  }}
                </th>
                <th class="bg-danger">{{
                    (overallTotals.mainBrand0.before_discount - overallTotalsRefund.mainBrand0.before_discount_refund).toFixed(2)
                  }}
                </th>
              </tr>
              <tr>
                <th class="bg-success text-start">After Discount</th>
                <th class="bg-primary">{{
                    (overallTotals.mainBrand2.after_discount - overallTotalsRefund.mainBrand2.after_discount_refund).toFixed(2)
                  }}
                </th>
                <th class="bg-warning">{{
                    (overallTotals.mainBrand1Target1.after_discount - overallTotalsRefund.mainBrand1Target1.after_discount_refund).toFixed(2)
                  }}
                </th>
                <th class="bg-danger">{{
                    (overallTotals.mainBrand0.after_discount - overallTotalsRefund.mainBrand0.after_discount_refund).toFixed(2)
                  }}
                </th>
              </tr>

              <tr>
                <th class="bg-success text-start">Commission</th>
                <th class="bg-primary">{{ (totalCommissionLoris - totalCommissionLorisRefund).toFixed(2) }}</th>
                <th class="bg-warning">{{ (totalCommissionCosmetic - totalCommissionCosmeticRefund).toFixed(2) }}</th>
                <th class="bg-danger">{{ (totalCommissionNotBrand - totalCommissionNotBrandRefund).toFixed(2) }}</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Target</th>
                <th class="bg-primary">{{
                    ((overallTotals.mainBrand2.after_discount - totalCommissionLoris) - (overallTotalsRefund.mainBrand2.after_discount_refund - totalCommissionLorisRefund)).toFixed(2)
                  }}
                </th>
                <th class="bg-warning">
                  {{
                    ((overallTotals.mainBrand1Target1.after_discount - totalCommissionCosmetic) - (overallTotalsRefund.mainBrand1Target1.after_discount_refund - totalCommissionCosmeticRefund)).toFixed(2)
                  }}
                </th>
                <th class="bg-danger">-</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Transfer</th>
                <th class="bg-primary">{{
                    (totalCommissionTransferLoris - totalCommissionTransferLorisRefund).toFixed(2)
                  }}
                </th>
                <th class="bg-warning">
                  {{ (totalCommissionTransferCosmetic - totalCommissionTransferCosmeticRefund).toFixed(2) }}
                </th>
                <th class="bg-danger">
                  {{ (totalCommissionTransferNotBrand - totalCommissionTransferNotBrandRefund).toFixed(2) }}
                </th>
              </tr>

              <tr>
                <th class="bg-success text-start">Profit</th>
                <th class="bg-primary">{{ lorisProfitAfterRefund }}</th>
                <th class="bg-warning">{{ cosmeticProfitAfterRefund }}</th>
                <th class="bg-danger">-</th>
              </tr>
            </table>
            <p><span class="bg-danger p-3 rounded">Affiliate Transfer</span>:
              {{
                totalAffCommissionTransferLoris + totalAffCommissionTransferCosmetic + totalAffCommissionTransferNotBrand
              }}
            </p>
          </div>


          <div class="summary mb-4 mt-5">
            <h5>Overall Totals</h5>

            <table class="table table-striped table-hover text-center justify-content-center">
              <tr class="bg-secondary">
                <th>#</th>
                <th>Loris</th>
                <th>Cosmetic</th>
                <th>ليست وكالتنا</th>
              </tr>
              <tr>
                <th class="bg-success text-start">Before Discount</th>
                <th class="bg-primary">{{ overallTotals.mainBrand2.before_discount.toFixed(2) }}</th>
                <th class="bg-warning">{{ overallTotals.mainBrand1Target1.before_discount.toFixed(2) }}</th>
                <th class="bg-danger">{{ overallTotals.mainBrand0.before_discount.toFixed(2) }}</th>
              </tr>
              <tr>
                <th class="bg-success text-start">After Discount</th>
                <th class="bg-primary">{{ overallTotals.mainBrand2.after_discount.toFixed(2) }}</th>
                <th class="bg-warning">{{ overallTotals.mainBrand1Target1.after_discount.toFixed(2) }}</th>
                <th class="bg-danger">{{ overallTotals.mainBrand0.after_discount.toFixed(2) }}</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Commission</th>
                <th class="bg-primary">{{ totalCommissionLoris.toFixed(2) }}</th>
                <th class="bg-warning">{{ totalCommissionCosmetic.toFixed(2) }}</th>
                <th class="bg-danger">{{ totalCommissionNotBrand.toFixed(2) }}</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Target</th>
                <th class="bg-primary">{{
                    (overallTotals.mainBrand2.after_discount - totalCommissionLoris).toFixed(2)
                  }}
                </th>
                <th class="bg-warning">
                  {{ (overallTotals.mainBrand1Target1.after_discount - totalCommissionCosmetic).toFixed(2) }}
                </th>
                <th class="bg-danger">-</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Transfer</th>
                <th class="bg-primary">{{ (totalCommissionTransferLoris).toFixed(2) }}</th>
                <th class="bg-warning">{{ (totalCommissionTransferCosmetic).toFixed(2) }}</th>
                <th class="bg-danger">{{ (totalCommissionTransferNotBrand).toFixed(2) }}</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Profit</th>
                <th class="bg-primary">{{ lorisProfit }}</th>
                <th class="bg-warning">{{ cosmeticProfit }}</th>
                <th class="bg-danger">-</th>
              </tr>
            </table>
          </div>


          <div class="summary mb-4">
            <h5>Overall Totals <span class="bg-warning p-2">Refunds</span></h5>

            <table class="table table-striped table-hover text-center justify-content-center">
              <tr class="bg-secondary">
                <th>#</th>
                <th>Loris</th>
                <th>Cosmetic</th>
                <th>ليست وكالتنا</th>
              </tr>
              <tr>
                <th class="bg-success text-start">Before Discount</th>
                <th class="bg-primary">{{ overallTotalsRefund.mainBrand2.before_discount_refund.toFixed(2) }}</th>
                <th class="bg-warning">{{
                    overallTotalsRefund.mainBrand1Target1.before_discount_refund.toFixed(2)
                  }}
                </th>
                <th class="bg-danger">{{ overallTotalsRefund.mainBrand0.before_discount_refund.toFixed(2) }}</th>
              </tr>
              <tr>
                <th class="bg-success text-start">After Discount</th>
                <th class="bg-primary">{{ overallTotalsRefund.mainBrand2.after_discount_refund.toFixed(2) }}</th>
                <th class="bg-warning">{{ overallTotalsRefund.mainBrand1Target1.after_discount_refund.toFixed(2) }}</th>
                <th class="bg-danger">{{ overallTotalsRefund.mainBrand0.after_discount_refund.toFixed(2) }}</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Commission</th>
                <th class="bg-primary">{{ totalCommissionLorisRefund.toFixed(2) }}</th>
                <th class="bg-warning">{{ totalCommissionCosmeticRefund.toFixed(2) }}</th>
                <th class="bg-danger">{{ totalCommissionNotBrandRefund.toFixed(2) }}</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Target</th>
                <th class="bg-primary">{{
                    (overallTotalsRefund.mainBrand2.after_discount_refund - totalCommissionLorisRefund).toFixed(2)
                  }}
                </th>
                <th class="bg-warning">
                  {{
                    (overallTotalsRefund.mainBrand1Target1.after_discount_refund - totalCommissionCosmeticRefund).toFixed(2)
                  }}
                </th>
                <th class="bg-danger">-</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Transfer</th>
                <th class="bg-primary">{{ (totalCommissionTransferLorisRefund).toFixed(2) }}</th>
                <th class="bg-warning">{{ (totalCommissionTransferCosmeticRefund).toFixed(2) }}</th>
                <th class="bg-danger">{{ (totalCommissionTransferNotBrandRefund).toFixed(2) }}</th>
              </tr>

              <tr>
                <th class="bg-success text-start">Profit</th>
                <th class="bg-primary">{{ lorisProfitRefund }}</th>
                <th class="bg-warning">{{ cosmeticProfitRefund }}</th>
                <th class="bg-danger">-</th>
              </tr>
            </table>

          </div>


          <table class="table table-striped table-hover">
            <thead>
            <tr>
              <th>User Name</th>
              <th>Total Before Discount</th>
              <th>Total After Discount</th>
              <th>Loris Before Discount</th>
              <th>Loris After Discount</th>
              <th>Cosmetic Before Discount</th>
              <th>Cosmetic After Discount</th>
              <th>ليست وكالتنا Before Discount</th>
              <th>ليست وكالتنا After Discount</th>
              <th>Commission</th>
              <th>Transfer <span class="bg-warning p-2">(Loris)</span></th>
              <th>Transfer <span class="bg-warning p-2">(Cosmetics)</span></th>
              <th>Transfer <span class="bg-warning p-2">(Not our brand)</span></th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(result, index) in sortedResults" :key="index">
              <td>{{ result.first_name }} {{ result.last_name }}</td>
              <td>{{
                  (result.mainBrand0Totals.before_discount + result.mainBrand2Totals.before_discount + result.mainBrand1Target1Totals.before_discount).toFixed(2)
                }}
              </td>
              <td>{{
                  (result.mainBrand0Totals.after_discount + result.mainBrand1Target1Totals.after_discount + result.mainBrand2Totals.after_discount).toFixed(2)
                }}
              </td>
              <td>{{ result.mainBrand2Totals.before_discount.toFixed(2) }}</td>
              <td>{{ result.mainBrand2Totals.after_discount.toFixed(2) }}</td>
              <td>{{ result.mainBrand1Target1Totals.before_discount.toFixed(2) }}</td>
              <td>{{ result.mainBrand1Target1Totals.after_discount.toFixed(2) }}</td>
              <td>{{ result.mainBrand0Totals.before_discount.toFixed(2) }}</td>
              <td>{{ result.mainBrand0Totals.after_discount.toFixed(2) }}</td>
              <td>
                {{
                  (result.mainBrand0Totals.commission + result.mainBrand2Totals.commission + result.mainBrand1Target1Totals.commission).toFixed(2)
                }}
              </td>
              <td v-if="result.mainBrand2Totals.commission_transfer > 0">
                <span class="transfer-badge bg-success">Transfer: {{
                    result.mainBrand2Totals.commission_transfer.toFixed(2)
                  }}</span>
                <br/>
                <span class="transfer-discount-badge bg-primary">Transfer - after discount: {{
                    (result.mainBrand2Totals.after_discount - result.mainBrand2Totals.commission_transfer).toFixed(2)
                  }}</span>
                <br/>

                <span class="calculated-value bg-danger"
                      v-if="(result.mainBrand2Totals.after_discount - result.mainBrand2Totals.commission_transfer) > 130 && (result.mainBrand2Totals.after_discount - result.mainBrand2Totals.commission_transfer) < 350">
        {{ ((result.mainBrand2Totals.after_discount - result.mainBrand2Totals.commission_transfer) * 0.01).toFixed(2) }}
    </span>

                <span class="calculated-value bg-danger"
                      v-else-if="(result.mainBrand2Totals.after_discount - result.mainBrand2Totals.commission_transfer) > 351 && (result.mainBrand2Totals.after_discount - result.mainBrand2Totals.commission_transfer) < 2000">
        {{ ((result.mainBrand2Totals.after_discount - result.mainBrand2Totals.commission_transfer) * 0.02).toFixed(2) }}
    </span>

                <span class="calculated-value bg-danger"
                      v-else-if="(result.mainBrand2Totals.after_discount - result.mainBrand2Totals.commission_transfer) > 2001">
        {{ ((result.mainBrand2Totals.after_discount - result.mainBrand2Totals.commission_transfer) * 0.04).toFixed(2) }}
    </span>
              </td>

              <td v-else>-</td>


              <td v-if="result.mainBrand1Target1Totals.commission_transfer > 0">
                <span class="transfer-badge bg-success">Transfer: {{ result.mainBrand1Target1Totals.commission_transfer.toFixed(2) }}</span>
                <br/>
                <span class="transfer-discount-badge bg-primary">Transfer - after discount: {{
                    (result.mainBrand1Target1Totals.after_discount - result.mainBrand1Target1Totals.commission_transfer).toFixed(2)
                  }}</span>
                <br/>

                <span class="calculated-value bg-danger"
                      v-if="(result.mainBrand1Target1Totals.after_discount - result.mainBrand1Target1Totals.commission_transfer) > 130 && (result.mainBrand1Target1Totals.after_discount - result.mainBrand1Target1Totals.commission_transfer) < 350">
        {{
                    ((result.mainBrand1Target1Totals.after_discount - result.mainBrand1Target1Totals.commission_transfer) * 0.01).toFixed(2)
                  }}
    </span>

                <span class="calculated-value bg-danger"
                      v-else-if="(result.mainBrand1Target1Totals.after_discount - result.mainBrand1Target1Totals.commission_transfer) > 351 && (result.mainBrand1Target1Totals.after_discount - result.mainBrand1Target1Totals.commission_transfer) < 2000">
        {{
                    ((result.mainBrand1Target1Totals.after_discount - result.mainBrand1Target1Totals.commission_transfer) * 0.02).toFixed(2)
                  }}
    </span>

                <span class="calculated-value bg-danger"
                      v-else-if="(result.mainBrand1Target1Totals.after_discount - result.mainBrand1Target1Totals.commission_transfer) > 2001">
        {{
                    ((result.mainBrand1Target1Totals.after_discount - result.mainBrand1Target1Totals.commission_transfer) * 0.04).toFixed(2)
                  }}
    </span>
              </td>

              <td v-else>-</td>
              <td v-if="result.mainBrand0Totals.commission_transfer > 0">
                {{ result.mainBrand0Totals.commission_transfer.toFixed(2) }}
              </td>
              <td v-else>-</td>
              <td>
                <router-link :to="{name: 'SubAffiliatesTargets' , params:{id:result.user_id}}"
                             class="bg-warning text-white p-3 my-2">
                  View
                </router-link>
              </td>
            </tr>
            <tr v-if="results.length === 0">
              <td colspan="9" class="text-center">No data available</td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';
import {useUserStore} from '../../../store';

export default {
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },

  data() {
    return {
      filterFrom: '',
      filterTo: '',
      results: [],
      resultsRefund: [],
      overallTotals: {
        mainBrand2: {before_discount: 0, after_discount: 0},
        mainBrand1Target1: {before_discount: 0, after_discount: 0},
        mainBrand0: {before_discount: 0, after_discount: 0},
        all: {before_discount: 0, after_discount: 0}
      },
      overallTotalsRefund: {
        mainBrand2: {before_discount_refund: 0, after_discount_refund: 0},
        mainBrand1Target1: {before_discount_refund: 0, after_discount_refund: 0},
        mainBrand0: {before_discount_refund: 0, after_discount_refund: 0},
        all: {before_discount_refund: 0, after_discount_refund: 0}
      },
      totalCommissionLoris: 0,
      totalCommissionCosmetic: 0,
      totalCommissionNotBrand: 0,
      totalCommissionTransferLoris: 0,
      totalCommissionTransferCosmetic: 0,
      totalCommissionTransferNotBrand: 0,
      totalAffCommissionTransferLoris: 0,
      totalAffCommissionTransferCosmetic: 0,
      totalAffCommissionTransferNotBrand: 0,

      totalCommissionLorisRefund: 0,
      totalCommissionCosmeticRefund: 0,
      totalCommissionNotBrandRefund: 0,
      totalCommissionTransferLorisRefund: 0,
      totalCommissionTransferCosmeticRefund: 0,
      totalCommissionTransferNotBrandRefund: 0,
      totalAffCommissionTransferLorisRefund: 0,
      totalAffCommissionTransferCosmeticRefund: 0,
      totalAffCommissionTransferNotBrandRefund: 0,
    };
  },



  created() {
    this.fetchSalesData();
    this.fetchSalesDataRefund();
  },

  computed: {

    sortedResults() {
      return this.results.slice().sort((a, b) => {
        const commissionA = a.mainBrand0Totals.before_discount + a.mainBrand2Totals.before_discount + a.mainBrand1Target1Totals.before_discount;
        const commissionB = b.mainBrand0Totals.before_discount + b.mainBrand2Totals.before_discount + b.mainBrand1Target1Totals.before_discount;
        return commissionB - commissionA; // Sort from highest to lowest
      });
    },

    lorisProfit() {
      const netAmount = this.overallTotals.mainBrand2.after_discount - this.totalCommissionLoris;
      if (netAmount >= 500 && netAmount <= 1000) {
        return (netAmount * 0.03).toFixed(2);
      } else if (netAmount >= 1000 && netAmount <= 2000) {
        return (netAmount * 0.05).toFixed(2);
      } else if (netAmount >= 2001 && netAmount <= 4000) {
        return (netAmount * 0.07).toFixed(2);
      } else if (netAmount >= 4001 && netAmount <= 8000) {
        return (netAmount * 0.08).toFixed(2);
      } else if (netAmount >= 8000 && netAmount <= 12000) {
        return (netAmount * 0.09).toFixed(2);
      } else if (netAmount >= 12001 && netAmount <= 17000) {
        return (netAmount * 0.10).toFixed(2);
      } else if (netAmount > 17001) {
        return (netAmount * 0.11).toFixed(2);
      } else {
        return 0;
      }
    },

    lorisProfitAfterRefund() {
      const netAmount = ((this.overallTotals.mainBrand2.after_discount - this.totalCommissionLoris) - (this.overallTotalsRefund.mainBrand2.after_discount_refund - this.totalCommissionLorisRefund));
      if (netAmount >= 500 && netAmount <= 1000) {
        return (netAmount * 0.03).toFixed(2);
      } else if (netAmount >= 1000 && netAmount <= 2000) {
        return (netAmount * 0.05).toFixed(2);
      } else if (netAmount >= 2001 && netAmount <= 4000) {
        return (netAmount * 0.07).toFixed(2);
      } else if (netAmount >= 4001 && netAmount <= 8000) {
        return (netAmount * 0.08).toFixed(2);
      } else if (netAmount >= 8000 && netAmount <= 12000) {
        return (netAmount * 0.09).toFixed(2);
      } else if (netAmount >= 12001 && netAmount <= 17000) {
        return (netAmount * 0.10).toFixed(2);
      } else if (netAmount > 17001) {
        return (netAmount * 0.11).toFixed(2);
      } else {
        return 0;
      }
    },

    lorisProfitRefund() {
      const netAmount = this.overallTotals.mainBrand2.after_discount_refund - this.totalCommissionLorisRefund;
      if (netAmount >= 500 && netAmount <= 1000) {
        return (netAmount * 0.03).toFixed(2);
      } else if (netAmount >= 1000 && netAmount <= 2000) {
        return (netAmount * 0.05).toFixed(2);
      } else if (netAmount >= 2001 && netAmount <= 4000) {
        return (netAmount * 0.07).toFixed(2);
      } else if (netAmount >= 4001 && netAmount <= 8000) {
        return (netAmount * 0.08).toFixed(2);
      } else if (netAmount >= 8000 && netAmount <= 12000) {
        return (netAmount * 0.09).toFixed(2);
      } else if (netAmount >= 12001 && netAmount <= 17000) {
        return (netAmount * 0.10).toFixed(2);
      } else if (netAmount > 17001) {
        return (netAmount * 0.11).toFixed(2);
      } else {
        return 0;
      }
    },

    cosmeticProfitRefund() {
      const netAmount = this.overallTotalsRefund.mainBrand1Target1.after_discount_refund - this.totalCommissionCosmeticRefund;

      if (netAmount >= 500 && netAmount <= 2500) {
        return (netAmount * 0.03).toFixed(2);
      } else if (netAmount > 2500) {
        return (netAmount * 0.05).toFixed(2);
      } else {
        return 0;
      }
    },

    cosmeticProfit() {
      const netAmount = this.overallTotals.mainBrand1Target1.after_discount - this.totalCommissionCosmetic;

      if (netAmount >= 500 && netAmount <= 2500) {
        return (netAmount * 0.03).toFixed(2);
      } else if (netAmount > 2500) {
        return (netAmount * 0.05).toFixed(2);
      } else {
        return 0;
      }
    },

    cosmeticProfitAfterRefund() {
      const netAmount = ((this.overallTotalsRefund.mainBrand1Target1.after_discount_refund - this.totalCommissionCosmeticRefund) - (this.overallTotals.mainBrand1Target1.after_discount - this.totalCommissionCosmetic));

      if (netAmount >= 500 && netAmount <= 2500) {
        return (netAmount * 0.03).toFixed(2);
      } else if (netAmount > 2500) {
        return (netAmount * 0.05).toFixed(2);
      } else {
        return 0;
      }
    }

  },

  methods: {

    fetchSalesData(startDate = null, endDate = null) {
      const apiEndpoint = `api/sub-invoices`;
      const params = {
        user_id: this.$route.params.id,
        from: startDate,
        to: endDate,
      };
      axiosInstance
          .get(apiEndpoint, {params})
          .then(({data}) => {
            console.log(data); // Add this line to debug
            this.results = data.results;
            this.overallTotals = data.overallTotals;
            this.calculateTotalCommissionLoris();
            this.calculateTotalCommissionCosmetic();
            this.calculateTotalCommissionNotBrand();
            this.calculateTotalCommissionTransferLoris();
            this.calculateTotalCommissionTransferCosmetic();
            this.calculateTotalCommissionTransferNotBrand();
            this.calculateTotalAffCommissionTransferLoris();
            this.calculateTotalAffCommissionTransferCosmetic();
            this.calculateTotalAffCommissionTransferNotBrand();
          })
          .catch(error => {
            console.error('API call failed:', error);
            this.results = [];
            this.overallTotals = {
              mainBrand2: {before_discount: 0, after_discount: 0},
              mainBrand1Target1: {before_discount: 0, after_discount: 0},
              mainBrand0: {before_discount: 0, after_discount: 0},
              all: {before_discount: 0, after_discount: 0}
            };
          });
    },

    fetchSalesDataRefund(startDate = null, endDate = null) {
      const apiEndpoint = `api/sub-invoices-refund`;
      const params = {
        user_id: this.$route.params.id,
        from: startDate,
        to: endDate,
      };
      axiosInstance
          .get(apiEndpoint, {params})
          .then(({data}) => {
            console.log(data); // Add this line to debug
            this.resultsRefund = data.results_refund;
            this.overallTotalsRefund = data.overallTotalsRefund;
            this.calculateTotalCommissionLorisRefund();
            this.calculateTotalCommissionCosmeticRefund();
            this.calculateTotalCommissionNotBrandRefund();
            this.calculateTotalCommissionTransferLorisRefund();
            this.calculateTotalCommissionTransferCosmeticRefund();
            this.calculateTotalCommissionTransferNotBrandRefund();
            this.calculateTotalAffCommissionTransferLorisRefund();
            this.calculateTotalAffCommissionTransferCosmeticRefund();
            this.calculateTotalAffCommissionTransferNotBrandRefund();
          })
          .catch(error => {
            console.error('API call failed:', error);
            this.resultsRefund = [];
            this.overallTotalsRefund = {
              mainBrand2: {before_discount_refund: 0, after_discount_refund: 0},
              mainBrand1Target1: {before_discount_refund: 0, after_discount_refund: 0},
              mainBrand0: {before_discount_refund: 0, after_discount_refund: 0},
              all: {before_discount_refund: 0, after_discount_refund: 0}
            };
          });
    },

    applyDateFilter() {
      if (this.filterFrom && this.filterTo) {
        const formattedStartDate = new Date(this.filterFrom).toISOString().split('T')[0];
        const formattedEndDate = new Date(this.filterTo).toISOString().split('T')[0];
        this.fetchSalesData(formattedStartDate, formattedEndDate);
        this.fetchSalesDataRefund(formattedStartDate, formattedEndDate);
      } else {
        this.fetchSalesData();
        this.fetchSalesDataRefund();
      }
    },

    calculateTotalCommissionTransferLoris() {
      this.totalCommissionTransferLoris = this.results.reduce((acc, result) => {
        return acc + (result.mainBrand2Totals.commission_transfer || 0);
      }, 0);
    },

    calculateTotalCommissionTransferCosmetic() {
      this.totalCommissionTransferCosmetic = this.results.reduce((acc, result) => {
        return acc + (result.mainBrand1Target1Totals.commission_transfer || 0);
      }, 0);
    },

    calculateTotalCommissionTransferNotBrand() {
      this.totalCommissionTransferNotBrand = this.results.reduce((acc, result) => {
        return acc + (result.mainBrand0Totals.commission_transfer || 0);
      }, 0);
    },

    calculateTotalAffCommissionTransferLoris() {
      this.totalAffCommissionTransferLoris = this.results.reduce((acc, result) => {
        return acc + (result.mainBrand2Totals.aff_commission_transfer || 0);
      }, 0);
    },

    calculateTotalAffCommissionTransferCosmetic() {
      this.totalAffCommissionTransferCosmetic = this.results.reduce((acc, result) => {
        return acc + (result.mainBrand1Target1Totals.aff_commission_transfer || 0);
      }, 0);
    },

    calculateTotalAffCommissionTransferNotBrand() {
      this.totalAffCommissionTransferNotBrand = this.results.reduce((acc, result) => {
        return acc + (result.mainBrand0Totals.aff_commission_transfer || 0);
      }, 0);
    },

    calculateTotalCommissionLoris() {
      this.totalCommissionLoris = this.results.reduce((acc, result) => {
        return acc + (result.mainBrand2Totals.commission || 0);
      }, 0);
    },

    calculateTotalCommissionCosmetic() {
      this.totalCommissionCosmetic = this.results.reduce((acc, result) => {
        return acc + (result.mainBrand1Target1Totals.commission || 0);
      }, 0);
    },

    calculateTotalCommissionNotBrand() {
      this.totalCommissionNotBrand = this.results.reduce((acc, result) => {
        return acc + (result.mainBrand0Totals.commission || 0);
      }, 0);
    },


    calculateTotalCommissionTransferLorisRefund() {
      this.totalCommissionTransferLorisRefund = this.resultsRefund.reduce((acc, refund) => {
        return acc + (refund.mainBrand2Totals.commission_transfer_refund || 0);
      }, 0);
    },

    calculateTotalCommissionTransferCosmeticRefund() {
      this.totalCommissionTransferCosmeticRefund = this.resultsRefund.reduce((acc, refund) => {
        return acc + (refund.mainBrand1Target1Totals.commission_transfer_refund || 0);
      }, 0);
    },

    calculateTotalCommissionTransferNotBrandRefund() {
      this.totalCommissionTransferNotBrandRefund = this.resultsRefund.reduce((acc, refund) => {
        return acc + (refund.mainBrand0Totals.commission_transfer_refund || 0);
      }, 0);
    },

    calculateTotalAffCommissionTransferLorisRefund() {
      this.totalAffCommissionTransferLorisRefund = this.resultsRefund.reduce((acc, refund) => {
        return acc + (refund.mainBrand2Totals.aff_commission_transfer_refund || 0);
      }, 0);
    },

    calculateTotalAffCommissionTransferCosmeticRefund() {
      this.totalAffCommissionTransferCosmeticRefund = this.resultsRefund.reduce((acc, refund) => {
        return acc + (refund.mainBrand1Target1Totals.aff_commission_transfer_refund || 0);
      }, 0);
    },

    calculateTotalAffCommissionTransferNotBrandRefund() {
      this.totalAffCommissionTransferNotBrandRefund = this.resultsRefund.reduce((acc, refund) => {
        return acc + (refund.mainBrand0Totals.aff_commission_transfer_refund || 0);
      }, 0);
    },

    calculateTotalCommissionLorisRefund() {
      this.totalCommissionLorisRefund = this.resultsRefund.reduce((acc, refund) => {
        return acc + (refund.mainBrand2Totals.commission_refund || 0);
      }, 0);
    },

    calculateTotalCommissionCosmeticRefund() {
      this.totalCommissionCosmeticRefund = this.resultsRefund.reduce((acc, refund) => {
        return acc + (refund.mainBrand1Target1Totals.commission_refund || 0);
      }, 0);
    },

    calculateTotalCommissionNotBrandRefund() {
      this.totalCommissionNotBrandRefund = this.resultsRefund.reduce((acc, refund) => {
        return acc + (refund.mainBrand0Totals.commission_refund || 0);
      }, 0);
    },

  },
};
</script>

<style scoped>
.transfer-badge, .transfer-discount-badge, .calculated-value {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 12px;
  font-weight: bold;
  font-size: 0.85rem; /* Adjust as needed */
}

.bg-success {
  background-color: #28a745; /* Bootstrap green */
  color: white;
}

.bg-primary {
  background-color: #007bff; /* Bootstrap blue */
  color: white;
}

.bg-danger {
  background-color: #dc3545; /* Bootstrap red */
  color: white;
}
</style>