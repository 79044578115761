<template>
    <div class="main-panel ms-5">
        <div class="content-wrapper container">
            <!-- Add brand selection dropdown -->

            <div class="mt-5">
                <label for="brand">Select Brand:</label>
                <select v-model="selectedBrand" @change="orderLoad" class="form-control mb-4">
                    <option value="">All Brands</option>
                    <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.brand_name }}</option>
                </select>
            </div>


            <!-- Zoomed image container -->
            <div v-if="showZoomedImage" id="zoomedImageContainer" style="width: 100px; height: 100px;">
                <div class="zoomedImageContent" style="width: 100%; height: 100%;">
                    <span class="close" @click="closeZoomedImage">&times;</span>
                    <img :src="zoomedImageSrc" alt="Zoomed Image" style="max-width: 100%; max-height: 100%;">
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <h3 class="mb-5">Orders Panel</h3>

                    <table class="responsive table-responsive table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Image</th>
                                <th>Product Name</th>
                                <th>Product Sku</th>
                                <th>Current quantity</th>
                                <th>Expiry Date</th>
                                <th>Weight</th>
                                <th>Main Price</th>
                                <th>Main Price</th>
                                <th>Barcode</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(order, index) in allOrder" :key="index">
                                <th scope="row">{{ index + 1 }}</th>
                                <th>
                                    <img @click="zoomImage(order.product_image)"
                                        :src="'https://api.gocami.com/product_detail/' + order.product_image" width="50"
                                        height="50" />
                                </th>

                                <td>{{ order.product_name }}</td>
                                <td>{{ order.product_sku }}</td>
                                <td v-if="order.current_quantity == 0" class="text-white bg-danger fw-bold">{{
                                    order.current_quantity }}</td>
                                <td v-else-if="order.current_quantity < 10" class="text-white bg-warning fw-bold">{{
                                    order.current_quantity }}</td>
                                <td v-else class="text-danger fw-bold">{{ order.current_quantity }}</td>
                                <td :class="getExpiryClass(order.expiry_date)" v-if="order.expiry_date">{{ order.expiry_date }}</td>
                                <td v-else>No Expiry Date</td>

                                <td>{{ order.weight }}</td>
                                
                                <td v-if="order.main_price" class="text-center fw-bolder">{{ order.main_price }}</td>
                                <td v-else class="text-center">-</td>
                                <td>
                                    <input v-model="order.updatedMainPrice" type="text" class="form-control" />
                                    <button @click="updateMainPrice(order)">Update</button>
                                </td>
                                <td v-if="order.barcode" class="bg-success text-white fw-bold">{{ order.barcode }}</td>
                                <td v-else class="bg-danger text-white fw-bold">No Barcode</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
    name: "CartInvoice",

    data() {
        return {
            allOrder: [],
            brands: [],
            selectedBrand: '',
            fromDate: '',
            toDate: '',
            sortColumn: null,
            sortDirection: 'asc',
            availableSortColumns: ['current_quantity', 'ordered_times'],
            selectedSortColumn: 'current_quantity',
            selectedSortOrder: 'asc',
            updatedMainPrice: null,
            showZoomedImage: false,
            zoomedImageSrc: ''
        };
    },

    created() {
        this.orderLoad();
    },

    methods: {

        zoomImage(imageSrc) {
            console.log('Zoom image clicked');
            console.log('Image source:', imageSrc);
            this.zoomedImageSrc = 'https://api.gocami.com/product_detail/' + imageSrc;
            console.log('Zoomed image source:', this.zoomedImageSrc);
            this.showZoomedImage = true;
        },

        closeZoomedImage() {
            console.log('Close zoomed image clicked');
            this.showZoomedImage = false;
        },

        updateMainPrice(order) {
            const newMainPrice = parseFloat(order.updatedMainPrice);
            const endpoint = `api/update-main-price/${order.product_id}`;
            const data = { main_price: newMainPrice };

            axiosInstance.put(endpoint, data)
                .then(response => {
                    // Handle success
                    console.log(response);
                    alert('Main price updated successfully.');
                    this.orderLoad();
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                    alert('Failed to update main price.');
                });
        },

        getExpiryClass(expiryDate) {
            const expiryDateObj = new Date(expiryDate);
            const currentDate = new Date();
            const monthsDifference = (expiryDateObj.getFullYear() - currentDate.getFullYear()) * 12 + (expiryDateObj.getMonth() - currentDate.getMonth());

            if (monthsDifference <= 0) {
                return 'bg-danger';
            } else if (monthsDifference === 1) {
                return 'bg-warning';
            } else {
                return 'bg-success';
            }
        },

        orderLoad() {
            var endpoint = `api/super-admin-all-products`;
            var params = {
                brand_id: this.selectedBrand,
            };

            axiosInstance.get(endpoint, { params }).then(({ data }) => {
                console.log(data);
                this.brands = data.brands;
                this.allOrder = data.products.map(product => {
                    return {
                        ...product,
                        updatedMainPrice: null // Initialize updatedMainPrice for each product
                    };
                });
            });
        },
    },
};
</script>

<style scoped>
/* Existing styles */
.link-to:hover {
    color: red;
}

.bg-danger {
    background-color: red;
}

.bg-warning {
    background-color: orange;
}

.bg-success {
    background-color: green;
}

/* New styles for zoomed image container */
#zoomedImageContainer {
    display: block;  /* Add this line to make the container visible */
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.9); */
    overflow: auto;
}

.zoomedImageContent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 999999 !important;
}

.zoomedImageContent img {
    z-index: 99999 !important;
    max-width: 100%;
    max-height: 100%;
    border: 2px solid #fff;
    /* Optional: Add a border around the image */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    /* Optional: Add a box shadow */
}

.close {
    color: red !important;
    position: absolute;
    top: 10px;
    right: 110px;
    font-size: 30px !important;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.close:hover {
    transform: scale(1.2);
    /* Optional: Increase the size of the close icon on hover */
}
</style>
